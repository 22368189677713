var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-3"},[_c('v-row',{attrs:{"dense":"","align":"center"}},[(_vm.editMode ===0)?_c('v-col',{attrs:{"cols":"12","sm":"10"}},[(_vm.merchantDay)?_c('h1',[_vm._v("當前編輯：第"+_vm._s(_vm.merchantDay.number)+"屆商人節大會")]):_vm._e()]):_vm._e(),(_vm.editMode ===0)?_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":_vm.newYear}},[_vm._v("新增年度")]),(_vm.merchantDayYears)?_c('v-select',{attrs:{"dense":"","label":"年度","items":_vm.merchantDayYears,"outlined":"","color":"primary","hide-details":"","value":_vm.merchantDay ? _vm.merchantDay.year : ''},on:{"change":_vm.changeYear}}):_vm._e()],1):_vm._e(),(_vm.editMode ===0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"text-center",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"outlined":_vm.selectedTab !== 0,"dark":_vm.selectedTab === 0,"rounded":"","color":"deep-orange"},on:{"click":function($event){_vm.selectedTab = 0}}},[_vm._v("商人節活動報導")])],1),_c('v-col',{attrs:{"cols":"4"}},[(_vm.editMode === 0)?_c('v-btn',{attrs:{"outlined":_vm.selectedTab !== 1,"dark":_vm.selectedTab === 1,"rounded":"","color":"deep-orange"},on:{"click":function($event){_vm.selectedTab = 1}}},[_vm._v("各縣市活動剪影")]):_vm._e()],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs-items',{staticClass:"pa-3",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',[(_vm.editMode === 0)?_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.merchantDay)?_c('v-card',{attrs:{"flat":"","color":"lime lighten-4"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"年度","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"年度","items":_vm.getYears,"outlined":"","color":"primary","error-messages":errors,"disabled":""},model:{value:(_vm.merchantDay.year),callback:function ($$v) {_vm.$set(_vm.merchantDay, "year", $$v)},expression:"merchantDay.year"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"屆數","rules":"required|max:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":"2","outlined":"","label":"屆數","color":"primary","error-messages":errors,"type":"number"},model:{value:(_vm.merchantDay.number),callback:function ($$v) {_vm.$set(_vm.merchantDay, "number", $$v)},expression:"merchantDay.number"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"表揚名單月刊期數","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.magazines)?_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.magazines,"color":"primary","item-value":"path","item-text":"name","label":"表揚名單月刊期數","error-messages":errors},model:{value:(_vm.merchantDay.edmPath),callback:function ($$v) {_vm.$set(_vm.merchantDay, "edmPath", $$v)},expression:"merchantDay.edmPath"}}):_vm._e()]}}],null,true)})],1)],1),_c('h3',[_vm._v("報導內容")]),_c('editor-tool',{key:_vm.merchantDay.year,attrs:{"data":_vm.merchantDay.content},on:{"update:data":function($event){return _vm.$set(_vm.merchantDay, "content", $event)}}})],1)],1):_vm._e(),_c('div',{staticClass:"text-right pa-3"},[_c('v-btn',{staticClass:"mx-3",on:{"click":function($event){_vm.$refs.doubleCheckTool.setOpen(_vm.removeMerchantDay(_vm.merchantDay), _vm.delMerchantDay, _vm.merchantDay)}}},[_vm._v(" 刪除 ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.updateMerchantDay}},[_vm._v("更新")])],1)]}}],null,false,3410180231)}):_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.merchantDay)?_c('v-card',{attrs:{"flat":"","color":"lime lighten-4"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"年度","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"年度","items":_vm.getYears,"outlined":"","color":"primary","error-messages":errors},model:{value:(_vm.newMerchantDay.year),callback:function ($$v) {_vm.$set(_vm.newMerchantDay, "year", $$v)},expression:"newMerchantDay.year"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"屆數","rules":"required|max:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":"2","outlined":"","label":"屆數","color":"primary","error-messages":errors,"type":"number"},model:{value:(_vm.newMerchantDay.number),callback:function ($$v) {_vm.$set(_vm.newMerchantDay, "number", $$v)},expression:"newMerchantDay.number"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"表揚名單月刊期數","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.magazines,"color":"primary","item-value":"path","item-text":"name","label":"表揚名單月刊期數","error-messages":errors},model:{value:(_vm.newMerchantDay.edmPath),callback:function ($$v) {_vm.$set(_vm.newMerchantDay, "edmPath", $$v)},expression:"newMerchantDay.edmPath"}})]}}],null,true)})],1)],1),_c('h3',[_vm._v("報導內容")]),_c('editor-tool',{attrs:{"data":_vm.newMerchantDay.content},on:{"update:data":function($event){return _vm.$set(_vm.newMerchantDay, "content", $event)}}})],1)],1):_vm._e(),_c('div',{staticClass:"text-right pa-3"},[_c('v-btn',{staticClass:"mr-2",on:{"click":function($event){return _vm.init()}}},[_vm._v("取消")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.createdMerchantDay}},[_vm._v("新增")])],1)]}}])})],1),_c('v-tab-item',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"fill-height",attrs:{"cols":"12","sm":"6"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{attrs:{"outlined":"","color":"pink lighten-5"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('upload-tool',{attrs:{"data":_vm.newMerchantDayImage.image,"loadingItem":_vm.dialog.global,"text":"選擇圖片"},on:{"update:data":function($event){return _vm.$set(_vm.newMerchantDayImage, "image", $event)},"update:loadingItem":function($event){return _vm.$set(_vm.dialog, "global", $event)},"update:loading-item":function($event){return _vm.$set(_vm.dialog, "global", $event)}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"說明","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"outlined":"","label":"說明","color":"primary","error-messages":errors},model:{value:(_vm.newMerchantDayImage.name),callback:function ($$v) {_vm.$set(_vm.newMerchantDayImage, "name", $$v)},expression:"newMerchantDayImage.name"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid || !_vm.newMerchantDayImage.image},on:{"click":_vm.createdMerchantDayImage}},[_vm._v(" 新增 ")])],1)],1)],1)]}}])})],1),(_vm.merchantDay)?_vm._l((_vm.merchantDay.id),function(item,index){return _c('v-col',{key:index,staticClass:"fill-height",attrs:{"cols":"12","sm":"6"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{attrs:{"outlined":"","color":"blue-grey lighten-5"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('upload-tool',{attrs:{"data":_vm.merchantDay.image[index],"loadingItem":_vm.dialog.global,"text":"選擇新圖片"},on:{"update:data":function($event){return _vm.$set(_vm.merchantDay.image, index, $event)},"update:loadingItem":function($event){return _vm.$set(_vm.dialog, "global", $event)},"update:loading-item":function($event){return _vm.$set(_vm.dialog, "global", $event)}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('validation-provider',{attrs:{"name":"排序","rules":"required|numeric|max:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"counter":"2","outlined":"","label":"排序","color":"primary","error-messages":errors},model:{value:(_vm.merchantDay.sort[index]),callback:function ($$v) {_vm.$set(_vm.merchantDay.sort, index, $$v)},expression:"merchantDay.sort[index]"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('validation-provider',{attrs:{"name":"說明","rules":"required|max:45"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"counter":"45","outlined":"","label":"說明","color":"primary","error-messages":errors},model:{value:(_vm.merchantDay.name[index]),callback:function ($$v) {_vm.$set(_vm.merchantDay.name, index, $$v)},expression:"merchantDay.name[index]"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mx-3",on:{"click":function($event){_vm.$refs.doubleCheckTool.setOpen(_vm.removeMerchantDayImage(_vm.merchantDay.sort[index]), _vm.delMerchantDayImage, item)}}},[_vm._v(" 刪除 ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.updateMerchantDayImage(index)}}},[_vm._v(" 更新 ")])],1)],1)],1)]}}],null,true)})],1)}):_vm._e()],2)],1)],1)],1)],1),_c('double-check-tool',{ref:"doubleCheckTool"}),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.dialog.global),callback:function ($$v) {_vm.$set(_vm.dialog, "global", $$v)},expression:"dialog.global"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }