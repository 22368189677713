<template>
    <v-card elevation="0" outlined class="pa-5 my-5">
        <validation-observer v-slot="{ invalid }">
            <v-row justify="center" class="text-center">
                <v-col cols="8">
                    <validation-provider name="帳號" rules="required" v-slot="{ errors }">
                        <v-text-field  outlined :error-messages="errors"
                            v-model="data.account" label="帳號"
                        ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="8">
                    <validation-provider name="帳號" rules="required" v-slot="{ errors }">
                        <v-text-field
                            v-model="data.password"
                            :append-icon="eyeIcon ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="eyeIcon ? 'text' : 'password'"
                            label="密碼" outlined :error-messages="errors"
                            @click:append="eyeIcon = !eyeIcon"
                        ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="8">
                    <v-btn color="primary" :loading="loading" @click="signin()"
                        :disabled="invalid"
                    > 登入 </v-btn>
                </v-col>
            </v-row>
        </validation-observer>
    </v-card>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {
                eyeIcon: false,
                loading: false,
                data: {
                    account: '',
                    password: ''
                }
            }
        },
        computed: {
          ...mapGetters({
            token: 'Auth/getToken'
          })
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                login: 'Auth/login'
            }),
            init() {
                if (this.token) {
                     this.$router.push({  name: 'home' })
                }
            },
            async signin() {
                this.loading = true
                await this.login(this.data)
                this.init()
            }
        }
    }
</script>
