<template>
    <div>
        <v-container fill-height fluid>
            <v-row>
                <v-col cols="9" class="px-0">
                    <v-tabs grow show-arrows color="deep-orange" v-model="selectedTab">
                        <v-tab v-for="(item, index) in tabs" :key="index" v-text="item">  </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="selectedTab" class="pa-3">
                        <v-tab-item>
                            <v-dialog
                                v-model="userDialog"
                                max-width="500px"
                            >
                                <validation-observer v-slot="{ invalid }">
                                    <v-card>
                                        <v-toolbar dark color="primary">
                                            <h3>新增會員</h3>
                                            <v-spacer></v-spacer>
                                            <v-btn icon @click="userDialog = false">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-card-text class="mt-3">
                                            <v-row dense justify="center">
                                                <v-col cols="12" md="8">
                                                    <validation-provider name="電子信箱" rules="required|email" v-slot="{ errors }">
                                                        <v-text-field  outlined dense :error-messages="errors"
                                                          v-model="createUser.email" label="電子信箱"
                                                          hide-details="auto"
                                                        ></v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                                <v-col cols="12" md="8">
                                                    <validation-provider name="所屬團體/中小企業" rules="required" v-slot="{ errors }">
                                                        <v-text-field  outlined dense :error-messages="errors"
                                                          v-model="createUser.group_name" label="所屬團體/中小企業"
                                                          hide-details="auto"
                                                        ></v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                                <v-col cols="12" md="8">
                                                    <validation-provider name="姓名" rules="required|alpha_spaces" v-slot="{ errors }">
                                                        <v-text-field  outlined dense :error-messages="errors"
                                                          v-model="createUser.name" label="姓名"
                                                          hide-details="auto"
                                                        ></v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                                <v-col cols="12" md="8">
                                                    <validation-provider name="聯絡電話" rules="required|numeric" v-slot="{ errors }">
                                                        <v-text-field  outlined dense :error-messages="errors"
                                                          v-model="createUser.phone" label="聯絡電話"
                                                          hide-details="auto"
                                                        ></v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions class="justify-space-between mt-1">
                                            <v-btn @click="userDialog = false" class="mx-2">關閉</v-btn>
                                            <v-btn color="primary" :dark="!invalid" :disabled="invalid" @click="addUser"> 新增 </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </validation-observer>
                            </v-dialog>
                            <v-data-table v-if="users" fixed-header height="41vh"
                              :headers="userHeaders"
                              :items="users"
                              item-key="id"
                              class="elevation-1"
                              :search="userSearch"
                            >
                                <template v-slot:top>
                                    <v-row class="d-flex ma-3" align="center">
                                        <v-text-field
                                            v-model="userSearch"
                                            label="輸入Email來查詢"
                                            class="mx-4"
                                        ></v-text-field>
                                        <v-btn color="primary" dark class="mb-2" @click="userDialog = true">
                                            新增會員
                                        </v-btn>
                                    </v-row>
                                </template>
                                <template v-slot:item.email="{ item }">
                                    <a @click="selectUser(item)"> {{ item.email }} </a>
                                </template>
                                <template v-slot:item.sso_type="{ item }">
                                    <span v-if="item.sso_type === null">
                                        本站
                                    </span>
                                    <span v-if="item.sso_type === 1">
                                        Facebook
                                    </span>
                                    <span v-if="item.sso_type === 2">
                                        Google
                                    </span>
                                </template>
                                <template v-slot:item.status="{ item }">
                                    <span v-if="item.status === 0">
                                        已停用
                                    </span>
                                    <span v-if="item.status === 1">
                                        正常
                                    </span>
                                    <span v-if="item.status === 2">
                                        未變更密碼
                                    </span>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                        <v-tab-item>
                            <v-dialog
                                v-model="adminDialog"
                                max-width="500px"
                            >
                                <validation-observer v-slot="{ invalid }">
                                    <v-card>
                                        <v-toolbar dark color="primary">
                                            <h3>新增管理員</h3>
                                            <v-spacer></v-spacer>
                                            <v-btn icon @click="adminDialog = false">
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-card-text class="mt-3">
                                            <v-row dense justify="center">
                                                <v-col cols="12" md="8">
                                                    <validation-provider name="帳號" rules="required|alpha_dash|min:8" v-slot="{ errors }">
                                                        <v-text-field  outlined dense :error-messages="errors"
                                                          v-model="createAdmin.account" label="帳號"
                                                          hide-details="auto"
                                                        ></v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                                <v-col cols="12" md="8">
                                                    <validation-provider name="姓名" rules="required|alpha_spaces" v-slot="{ errors }">
                                                        <v-text-field  outlined dense :error-messages="errors"
                                                          v-model="createAdmin.name" label="姓名"
                                                          hide-details="auto"
                                                        ></v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                                <v-col cols="12" md="8">
                                                    <validation-provider name="職稱" rules="required|alpha_spaces" v-slot="{ errors }">
                                                        <v-text-field  outlined dense :error-messages="errors"
                                                          v-model="createAdmin.title" label="職稱"
                                                          hide-details="auto"
                                                        ></v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                                <v-col cols="12" md="8">
                                                    <validation-provider name="密碼" rules="required|alpha_dash|min:8|confirmedA:confirmPassword" vid="password" v-slot="{ errors }">
                                                        <v-text-field
                                                            v-model="createAdmin.password" :error-messages="errors"
                                                            :append-icon="eyeIconPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                            :type="eyeIconPassword ? 'text' : 'password'"
                                                            label="密碼" hide-details="auto"
                                                            outlined dense
                                                            @click:append="eyeIconPassword = !eyeIconPassword"
                                                        ></v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                                <v-col cols="12" md="8">
                                                    <validation-provider name="密碼" rules="required|alpha_dash|min:8|confirmedB:password" vid="confirmPassword" v-slot="{ errors }">
                                                        <v-text-field
                                                            v-model="createAdmin.confirmPassword" :error-messages="errors"
                                                            :append-icon="eyeIconConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                            :type="eyeIconConfirmPassword ? 'text' : 'password'"
                                                            label="確認密碼" hide-details="auto"
                                                            outlined dense
                                                            @click:append="eyeIconConfirmPassword = !eyeIconConfirmPassword"
                                                        ></v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions class="justify-space-between mt-1">
                                            <v-btn @click="userDialog = false" class="mx-2">關閉</v-btn>
                                            <v-btn color="primary" :dark="!invalid" :disabled="invalid" @click="addAdmin"> 新增 </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </validation-observer>
                            </v-dialog>
                            <v-data-table v-if="admins" fixed-header height="41vh"
                              :headers="adminHeaders"
                              :items="admins"
                              item-key="id"
                              class="elevation-1"
                              :search="adminSearch"
                            >
                                <template v-slot:top>
                                    <v-row class="d-flex ma-3" align="center">
                                        <v-text-field
                                            v-model="adminSearch"
                                            label="輸入帳號來查詢"
                                            class="mx-4"
                                        ></v-text-field>
                                        <v-btn color="primary" dark class="mb-2" @click="adminDialog = true">
                                            新增管理員
                                        </v-btn>
                                    </v-row>
                                </template>
                                <template v-slot:item.account="{ item }">
                                    <a @click="selectAdmin(item)" v-if="account === item.account"> {{ item.account }} (您的帳號)</a>
                                    <a @click="selectAdmin(item)" v-else> {{ item.account }} </a>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
                <v-col cols="3" class="my-2" align-self="center">
                    <v-card>
                        <v-toolbar color="primary" dark>
                            <v-toolbar-title>
                                <span>待審核活動</span>
                                <h5>共 {{notAuditArticles.length}} 篇</h5>
                                <div class="text-center">
                                </div>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn @click="getNotAuditArticles">刷新</v-btn>
                        </v-toolbar>
                            <v-list two-line style="height: 60vh;" class="overflow-y-auto">
                                <div v-if="notAuditArticles.length > 0">
                                    <template v-for="(item, index) in notAuditArticles">
                                        <v-list-item :key="item.title" :href="`/#/activities/${item.id}`" target="_blank">
                                            <template>
                                                <v-list-item-content>
                                                    <v-img :src="item.banner" :aspect-ratio="29/14"></v-img>
                                                    <v-list-item-subtitle class="text-right">
                                                        {{ item.start_date }} ~ {{ item.end_date }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                                    <v-list-item-subtitle
                                                        class="text--primary"
                                                        v-text="item.teacher"
                                                    >
                                                    </v-list-item-subtitle>
                                                    <v-list-item-subtitle v-text="item.describe"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-list-item>
                                        <v-divider
                                            v-if="index < notAuditArticles.length - 1"
                                            :key="index"
                                        ></v-divider>
                                    </template>
                                </div>
                                <v-container fill-height v-else>
                                    <span >已經都審閱完畢囉！</span>
                                </v-container>
                            </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="loading"
            persistent
            width="300"
        >
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-dialog>
        <v-dialog
            v-model="updateUserDialog"
            max-width="500px"
        >
            <validation-observer v-slot="{ invalid }">
                <v-card>
                    <v-toolbar dark color="primary">
                        <h3>編輯會員</h3>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="updateUserDialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text class="mt-3">
                        <v-row dense justify="center">
                            <v-col cols="12" md="8">
                                <validation-provider name="電子信箱" rules="required|email" v-slot="{ errors }">
                                    <v-text-field  outlined dense :error-messages="errors"
                                      v-model="updateUser.email" label="電子信箱"
                                      hide-details="auto" disabled
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="8">
                                <validation-provider name="所屬團體/中小企業" rules="required" v-slot="{ errors }">
                                    <v-text-field  outlined dense :error-messages="errors"
                                      v-model="updateUser.group_name" label="所屬團體/中小企業"
                                      hide-details="auto"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="8">
                                <validation-provider name="姓名" rules="required|alpha_spaces" v-slot="{ errors }">
                                    <v-text-field  outlined dense :error-messages="errors"
                                      v-model="updateUser.name" label="姓名"
                                      hide-details="auto"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="8">
                                <validation-provider name="聯絡電話" rules="required|numeric" v-slot="{ errors }">
                                    <v-text-field  outlined dense :error-messages="errors"
                                      v-model="updateUser.phone" label="聯絡電話"
                                      hide-details="auto"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="8">
                                <validation-provider name="帳號狀態" rules="required" v-slot="{ errors }">
                                    <v-radio-group v-model="updateUser.status" row v-if="updateUser.status !== 2"
                                        :error-messages="errors" hide-details="auto" label="帳號狀態"
                                    >
                                        <v-radio label="正常" :value="1"></v-radio>
                                        <v-radio label="停用" :value="0"></v-radio>
                                    </v-radio-group>
                                    <span v-else>該會員尚未變更密碼。</span>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-btn :loading="resetPasswordLoading" @click="resetPassword" color="blue" class="mx-2">重設密碼</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-space-between mt-1">
                        <v-btn @click="updateUserDialog = false" class="mx-2">關閉</v-btn>
                        <v-btn color="primary" :dark="!invalid" :disabled="invalid" @click="editUser"> 更新 </v-btn>
                    </v-card-actions>
                </v-card>
            </validation-observer>
        </v-dialog>
        <v-dialog
            v-model="updateAdminDialog"
            max-width="500px"
        >
            <v-card>
                <v-toolbar dark color="primary">
                    <h3>編輯管理員</h3>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="updateAdminDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-3">
                    <validation-observer v-slot="{ invalid }">
                        <v-row dense justify="center">
                            <v-col cols="12" md="8">
                                <validation-provider name="帳號" rules="required|alpha_dash|min:8" v-slot="{ errors }">
                                    <v-text-field  outlined dense :error-messages="errors"
                                      v-model="updateAdmin.account" label="帳號"
                                      hide-details="auto"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="8">
                                <validation-provider name="姓名" rules="required|alpha_spaces" v-slot="{ errors }">
                                    <v-text-field  outlined dense :error-messages="errors"
                                      v-model="updateAdmin.name" label="姓名"
                                      hide-details="auto"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="8">
                                <validation-provider name="職稱" rules="required|alpha_spaces" v-slot="{ errors }">
                                    <v-text-field  outlined dense :error-messages="errors"
                                      v-model="updateAdmin.title" label="職稱"
                                      hide-details="auto"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row justify="end" class="d-flex">
                            <v-btn color="blue" @click="resetAdminPassword(updateAdmin)" v-if="account !== updateAdmin.account"> 重設密碼為統編 </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" :dark="!invalid" :disabled="invalid" @click="editAdmin"> 更新 </v-btn>
                        </v-row>
                    </validation-observer>
                    <div v-if="account === updateAdmin.account">
                        <v-divider class="my-5"></v-divider>
                        <validation-observer v-slot="{ invalid }">
                            <v-row dense justify="center">
                                <v-col cols="12" md="8">
                                    <validation-provider name="新密碼" rules="required|alpha_dash|min:8|confirmedA:confirmPassword" vid="password" v-slot="{ errors }">
                                        <v-text-field
                                            v-model="updateAdminPassword.password" :error-messages="errors"
                                            :append-icon="eyeIconPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="eyeIconPassword ? 'text' : 'password'"
                                            label="新密碼" hide-details="auto"
                                            outlined dense
                                            @click:append="eyeIconPassword = !eyeIconPassword"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <validation-provider name="密碼" rules="required|alpha_dash|min:8|confirmedB:password" vid="confirmPassword" v-slot="{ errors }">
                                        <v-text-field
                                            v-model="updateAdminPassword.confirmPassword" :error-messages="errors"
                                            :append-icon="eyeIconConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="eyeIconConfirmPassword ? 'text' : 'password'"
                                            label="確認密碼" hide-details="auto"
                                            outlined dense
                                            @click:append="eyeIconConfirmPassword = !eyeIconConfirmPassword"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn color="primary" :dark="!invalid" :disabled="invalid" @click="editAdminPassword"> 更新密碼 </v-btn>
                                </v-col>
                            </v-row>
                        </validation-observer>
                    </div>
                </v-card-text>
            </v-card>

        </v-dialog>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {
                eyeIconPassword: false,
                eyeIconConfirmPassword: false,
                selectedTab: 0,
                tabs: ['會員名冊', '系統人員名冊'],
                selected: null,
                loading: false,
                benched: 0,
                userSearch: '',
                userDialog: false,
                createUser: {
                    subscription: true
                },
                updateUser: {},
                updateUserDialog: false,
                resetPasswordLoading: false,
                adminSearch: '',
                adminDialog: false,
                createAdmin: {},
                updateAdmin: {},
                updateAdminPassword: {},
                updateAdminDialog: false,
            }
        },
        computed: {
            ...mapGetters({
                notAuditArticles: 'Articles/getNotAuditArticles',
                users: 'Users/getUsers',
                admins: 'Admins/getAdmins',
                account: 'Auth/getAccount'
            }),
            userHeaders() {
                return [{
                    text: 'Email',
                    align: 'start',
                    sortable: true,
                    value: 'email'
                }, {
                    text: '所屬團體',
                    align: 'start',
                    sortable: true,
                    value: 'group_name',
                }, {
                    text: '姓名',
                    align: 'start',
                    sortable: true,
                    value: 'name',
                }, {
                    text: '電話',
                    align: 'start',
                    sortable: true,
                    value: 'phone',
                }, {
                    text: '註冊管道',
                    align: 'start',
                    sortable: true,
                    value: 'sso_type',
                }, {
                    text: '狀態',
                    align: 'start',
                    sortable: true,
                    value: 'status',
                }]
            },
            adminHeaders() {
                return [{
                    text: '帳號',
                    align: 'start',
                    sortable: true,
                    value: 'account',
                }, {
                    text: '姓名',
                    align: 'start',
                    sortable: true,
                    value: 'name',
                }, {
                    text: '職稱',
                    align: 'start',
                    sortable: true,
                    value: 'title',
                }]
            }
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getNotAuditArticles: 'Articles/getNotAuditArticles',
                getUsers: 'Users/getUsers',
                postUsers: 'Users/postUsers',
                putUsers: 'Users/putUsers',
                delUsers: 'Users/delUsers',
                forget: 'Users/forget',
                getAdmins: 'Admins/getAdmins',
                postAdmins: 'Admins/postAdmins',
                putAdmins: 'Admins/putAdmins',
                delAdmins: 'Admins/delAdmins',
                putPassword: 'Admins/putPassword',
                resetAdminPassword: 'Admins/resetPassword',
            }),
            async init() {
                this.loading = true
                this.updateUser = {}
                if (!this.notAuditArticles) {
                    await this.getNotAuditArticles()
                }
                if (!this.users) {
                    await this.getUsers()
                }
                if (!this.admins) {
                    await this.getAdmins(this.account)
                }
                this.updateUserDialog = false
                this.userDialog = false
                this.createUser = {
                    subscription: 1
                }
                this.loading = false
                this.resetPasswordLoading = false

                this.adminDialog = false
                this.createAdmin = {}
                this.updateAdmin = {}
                this.updateAdminPassword = {}
                this.updateAdminDialog = false
            },
            async addUser() {
                this.loading = true
                await this.postUsers(this.createUser)
                this.init()
            },
            async editUser() {
                this.loading = true
                await this.putUsers(this.updateUser)
                this.init()
            },
            async resetPassword() {
                this.resetPasswordLoading = true
                await this.forget(this.updateUser)
                this.init()
            },
            async addAdmin() {
                this.loading = true
                await this.postAdmins(this.createAdmin)
                this.init()
            },
            async editAdmin() {
                this.loading = true
                await this.putAdmins(this.updateAdmin)
                this.init()
            },
            async editAdminPassword() {
                this.loading = true
                await this.putPassword({
                    ...this.updateAdmin,
                    password: this.updateAdminPassword.password
                })
                this.init()
            },
            selectUser(item) {
                this.updateUser = item
                this.updateUserDialog = true
            },
            selectAdmin(item) {
                this.updateAdmin = item
                this.updateAdminDialog = true
            }
        }
    }
</script>
