<template>
    <div class="ma-3">
        <v-row v-if="memberGroup">
            <v-col cols="12" md="6">
                <validation-observer v-slot="{ invalid }">
                    <v-card class="py-3 px-5" color="amber lighten-3">
                        <v-row dense align="center">
                            <v-col cols="9" class="title">
                                <validation-provider name="公會名稱" rules="required|alpha|max:45" v-slot="{ errors }">
                                    <v-text-field label="公會名稱" counter="45" v-model="memberGroup.name" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="3" class="text-right">
                                <v-btn v-if="memberGroup.address" icon :href="`https://www.google.com.tw/maps/place/'${memberGroup.address}`">
                                    <v-icon color="blue darken-1"> mdi-map-marker</v-icon>
                                </v-btn>
                                <v-btn icon :href="memberGroup.website">
                                    <v-icon color="blue darken-1"> mdi-web</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="5" md="2">
                                <h4>
                                    <v-icon color="green darken-2"> mdi-phone</v-icon>
                                    電話
                                </h4>
                            </v-col>
                            <v-col cols="7" md="4" class="subtitle-1">
                                <validation-provider name="電話" rules="phone|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" v-model="memberGroup.phone" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="5" md="2">
                                <h4>
                                    <v-icon color="green darken-2"> mdi-fax</v-icon>
                                    傳真
                                </h4>
                            </v-col>
                            <v-col cols="7" md="4" class="subtitle-1">
                                <validation-provider name="傳真" rules="phone|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" v-model="memberGroup.fax" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="5" md="2">
                                <h4>
                                    <v-icon color="green darken-2"> mdi-domain</v-icon>
                                    地址
                                </h4>
                            </v-col>
                            <v-col cols="7" md="10" class="subtitle-1">
                                <validation-provider name="地址" rules="required|alpha_dash|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" v-model="memberGroup.address" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="5" md="3">
                                <h4>
                                    <v-icon color="green darken-2"> mdi-email</v-icon>
                                    電子郵件
                                </h4>
                            </v-col>
                            <v-col cols="7" md="9" class="subtitle-1">
                                <validation-provider name="電子信箱" rules="email|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" v-model="memberGroup.email" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="5" md="3">
                                <h4>
                                    <v-icon color="green darken-2"> mdi-web</v-icon>
                                    網址
                                </h4>
                            </v-col>
                            <v-col cols="7" md="9" class="subtitle-1">
                                <validation-provider name="網址" rules="url|max:100" v-slot="{ errors }">
                                    <v-text-field counter="100" v-model="memberGroup.website" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="5" md="3">
                                <h4>
                                    <v-icon color="green darken-2"> mdi-currency-usd</v-icon>
                                    繳費狀態
                                </h4>
                            </v-col>
                            <v-col cols="7" md="4" class="subtitle-1">
                                <v-radio-group row v-model="memberGroup.pay_status" :error-messages="errors">
                                    <v-radio
                                        v-for="status of [1, 0]"
                                        :key="status"
                                        :label="`${status?'有效':'逾期'}`"
                                        :value="status"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="5" md="3">
                                <h4>
                                    <v-icon color="green darken-2"> mdi-eye</v-icon>
                                    前台顯示
                                </h4>
                            </v-col>
                            <v-col cols="7" md="2" class="subtitle-1">
                                <v-radio-group row v-model="memberGroup.show">
                                    <v-radio
                                        v-for="status of [1, 0]"
                                        :key="status"
                                        :label="`${status?'有':'無'}`"
                                        :value="status"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" class="text-right">
                                <v-btn
                                    color="primary" :disabled="invalid"
                                    @click="updateMemberGroup"
                                >
                                    更新
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </validation-observer>
                <v-card class="py-3 px-5 mt-5" color="amber lighten-3">
                    <validation-observer v-slot="{ invalid }">
                        <v-row dense align="center">
                            <v-col cols="6">
                                <h3> 理監事名單 </h3>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <v-btn
                                    color="primary"
                                    @click="addLevel"
                                >
                                    增加抬頭
                                </v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-simple-table class="amber lighten-3" v-if="editSupervisorsLevel">
                                    <template v-slot:default>
                                        <tbody>
                                            <tr v-for="(item, level) in editSupervisorsLevel" :key="level">
                                                <td width="40px">
                                                    <v-btn icon class="ma-0 pa-0"
                                                        color="gray"
                                                        @click="removeSupervisors(level)"
                                                    >
                                                        <v-icon>
                                                            mdi-close
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                                <td width="130px">
                                                    <validation-provider name="抬頭" rules="required|alpha|max:15" v-slot="{ errors }">
                                                        <v-text-field counter="15" label="抬頭" v-model="editSupervisorsLevel[level]" :error-messages="errors"></v-text-field>
                                                    </validation-provider>
                                                </td>
                                                <td class="subtitle-1">
                                                    <validation-provider name="名單" rules="required|alpha_comma" v-slot="{ errors }">
                                                        <v-text-field counter label="名單" v-model="editSupervisors[level]" :error-messages="errors"></v-text-field>
                                                    </validation-provider>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <v-col cols="12" class="text-right">
                                <v-btn
                                    color="primary" :disabled="invalid"
                                    @click="updateSupervisors"
                                >
                                    更新
                                </v-btn>
                            </v-col>
                        </v-row>
                    </validation-observer>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="py-3 px-5" color="amber lighten-3">
                    <v-row dense align="center" v-if="memberGroup">
                        <v-col cols="12" class="title">
                            <h3> {{ getGroupType }}</h3>
                        </v-col>
                        <v-col cols="12">
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center">
                                <v-col cols="10">
                                    <v-autocomplete return-object
                                        :items="basicGroups"
                                        color="green darken-2"
                                        item-value="id"
                                        item-text="name"
                                        label="增加同業公會"
                                        v-model="newbasicGroups"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn :dark="newbasicGroups?true:false" color="green darken-2" :disabled="!newbasicGroups" @click="addbasicGroups()">
                                        增加
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-simple-table class="amber lighten-3">
                                <template v-slot:default>
                                    <tbody color="">
                                        <tr v-for="(item, index) in memberGroup.basic_groups" :key="index">
                                            <td width="40px">
                                                <v-btn icon class="ma-0 pa-0"
                                                    color="gray"
                                                    @click="removeBasicGroup(index)"
                                                >
                                                    <v-icon>
                                                        mdi-close
                                                    </v-icon>
                                                </v-btn>
                                            </td>
                                            <td class="subtitle-1">{{ item.name }}</td>
                                            <td class="subtitle-1">
                                                <v-btn v-if="item.address"
                                                    icon :href="'https://www.google.com.tw/maps/place/'+item.address">
                                                    <v-icon color="blue darken-1"> mdi-map-marker</v-icon>
                                                </v-btn>{{ item.address }}
                                            </td>
                                            <td class="subtitle-1">{{ item.phone }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="12" class="text-right">
                            <v-btn
                                color="primary"
                                @click="updateBasicGroup"
                            >
                                更新
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <double-check-tool ref="doubleCheckTool"></double-check-tool>
        <v-dialog v-model="dialog.global"
            persistent
            width="300"
        >
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-dialog>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import _map from 'lodash/map'
    export default {
        data () {
            return {
                dialog: {
                    global: false
                },
                memberGroup: null,
                editSupervisors: [],
                editSupervisorsLevel: null,
                newbasicGroups: null,
                editBasicGroup: []
            }
        },
        computed: {
          ...mapGetters({
                basicGroups: 'MemberGroups/getBasicGroups',
            }),
            getGroupType() {
                let result = this.memberGroup.name || ''
                if (result.indexOf('商業會') !== -1) {
                    result = result.replace('商業會', '同業公會')
                }
                if (result.indexOf('台灣省') !== -1) {
                    result = result.replace('台灣省', '台灣省各縣市')
                }
                if (result.indexOf('聯合會') !== -1) {
                    result = result.replace('聯合會', '')
                }
                return result
            }
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getBasicGroups: 'MemberGroups/getBasicGroups',
                getMemberGroup: 'MemberGroups/getMemberGroup',
                putMemberGroup: 'MemberGroups/putMemberGroup',
                putMemberGroupSupervisors: 'MemberGroups/putMemberGroupSupervisors',
                putBasicGroup: 'MemberGroups/putBasicGroup',
                delMemberGroup: 'MemberGroups/delMemberGroup',
            }),
            closeLoading() {
                this.dialog.global = false
            },
            async init() {
                this.dialog.global = true
                if(this.basicGroups === ''){
                    await this.getBasicGroups()
                }
                this.memberGroup = await this.getMemberGroup(this.$route.params.id)
                this.editSupervisorsLevel = this.memberGroup.level
                for (let i = 0 ; i < this.editSupervisorsLevel.length ; i++) {
                    this.editSupervisors[i] = _map(this.memberGroup.supervisorsGroup[i],'name').toString()
                }
                this.editBasicGroup = []
                this.closeLoading()
            },
            async updateMemberGroup() {
                this.dialog.global = true
                await this.putMemberGroup(this.memberGroup)
                this.init()
            },
            async updateSupervisors() {
                this.dialog.global = true
                let supervisors = []
                let count = 0
                for (let i = 0 ; i < this.editSupervisorsLevel.length ; i++) {
                    let currentLevel = []
                    if (this.editSupervisors[i]) {
                        currentLevel = this.editSupervisors[i].split(',')
                    }
                    for (let j = 0 ; j < currentLevel.length ; j++) {
                        if (this.editSupervisorsLevel[i]){
                            supervisors.push({
                                sort: ++count,
                                title: this.editSupervisorsLevel[i],
                                name: currentLevel[j]
                            })
                        }
                    }
                }
                await this.putMemberGroupSupervisors({
                    mg_id: this.memberGroup.id,
                    list: supervisors
                })
                this.init()
            },
            async updateBasicGroup() {
                if(this.editBasicGroup.length < 1) {
                    return
                }
                this.dialog.global = true
                for (let i = 0; i < this.editBasicGroup.length ; i++) {
                    await this.putBasicGroup(this.editBasicGroup[i])
                }
                this.init()
            },
            addLevel() {
                this.$set(this.editSupervisorsLevel, this.editSupervisorsLevel.length, '')
            },
            removeSupervisors(level) {
                this.editSupervisorsLevel.splice(level, 1)
                this.editSupervisors.splice(level, 1)
            },
            removeBasicGroup(index) {
                const mgId = this.memberGroup.id
                const currentItem = this.memberGroup.basic_groups[index]
                let pass = false
                for (let i = 0 ; i < this.editBasicGroup.length ; i++) {
                    let currentEdit = this.editBasicGroup[i]
                    if (currentItem.id === currentEdit.id) {
                        this.editBasicGroup.splice(i, 1)
                        pass = true
                    }
                }
                if (!pass) {
                    for(let i = 1 ; i < 5 ; i++) {
                        if(this.memberGroup.basic_groups[index][`belong${i}`] === mgId) {
                            this.memberGroup.basic_groups[index][`belong${i}`] = null
                        }
                    }
                    this.editBasicGroup.push(this.memberGroup.basic_groups[index])
                }
                this.memberGroup.basic_groups.splice(index, 1)
            },
            addbasicGroups() {
                const mgId = this.memberGroup.id
                let keep = true
                for(let i = 1 ; i < 5 ; i++) {
                    let current = this.newbasicGroups[`belong${i}`]
                    if (current === mgId) {
                        keep = false
                    }
                    if(current === null && keep) {
                        keep = false
                        let tmp = {
                            ...this.newbasicGroups
                        }
                        tmp[`belong${i}`] = mgId
                        this.editBasicGroup.push(tmp)
                        this.memberGroup.basic_groups.push(tmp)
                    }
                }
                this.newbasicGroups = null
            }
        }
    }
</script>
