import axios from './axios.js'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
export default {
    namespaced: true,
    state: {
        admins: null
    },
    actions: {
        getAdmins ({commit}, account) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/admins`).then(response => {
                    commit('setAdmins', { data: response, account: account })
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        postAdmins ({}, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/admins`, data
                ).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        putAdmins ({}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/admins/${data.id}`, {
                    name: data.name,
                    title: data.title
                }).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        delAdmins ({}, data) {
            return new Promise((resolve, reject) => {
                axios.delete(`/api/admins/${data.id}`).
                then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        putPassword({}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/admins/${data.id}/password`,{
                    password: data.password
                }).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        resetPassword({}, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/admins/${data.id}/password`).
                then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        }
    },
    mutations: {
        setAdmins(state, data) {
            let list = _filter(data.data, (item) => { return item.account !== data.account })
            let first = _find(data.data, (item) => { return item.account === data.account })
            list.unshift(first)
            state.admins = list
        }
    },
    getters: {
        getAdmins(state) {
            return state.admins
        }
    }
}
