import axios from 'axios'
import Vue from 'vue'
import router from '../router'

axios.defaults.timeout = 10000
axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || ''
axios.interceptors.request.use(
    config => {
        if (localStorage.getItem('authorization')) {
            config.headers.authorization = localStorage.getItem('authorization')
        }
        return config
    },
    error => {
        return Promise.reject(error)
})

axios.interceptors.response.use(
    (response) => {
        const data = response.data.data
        const status = response.data.status
        Vue.$toast.clear()
        switch (status) {
            case 1:
                Vue.$toast.open({
                    message: '操作成功！',
                    type: 'success',
                });
                return data
            case 111:
                localStorage.clear()
                Vue.$toast.open({
                    message: '登入的效期已過期，請重新登入！',
                    type: 'info',
                });
                router.replace({ path: '/login' })
                break
            case 101:
                Vue.$toast.open({
                    message: '資料不存在！',
                    type: 'warning',
                });
                router.replace({ path: '/home' })
                break
            case 102:
                Vue.$toast.open({
                    message: '資料有誤！',
                    type: 'warning',
                });
                return Promise.reject(status)
                break
            case 0:
                Vue.$toast.open({
                    message: '操作未成功，請稍後再試或聯繫管理員',
                    type: 'error',
                });
                return Promise.reject(status)
                break
        }
        return Promise.reject({})
    },
    (error) => {
        Vue.$toast.open({
            message: '系統異常，請稍後再試！',
            type: 'error'
        });
        return Promise.reject(error)
  }
)

// const post = (url, params) => {
//     return new Promise((resolve, reject) => {
//         axios
//         .post(url, params)
//         .then(response => {
//             if(response.data.status === 1) {
//                 resolve(response.data.data)
//             } else {
//                 resolve('參數有誤')
//             }
//         })
//         .catch(error => {
//             reject(error)
//         })
//     })
// }
//
// const put = (url, params) => {
//     return new Promise((resolve, reject) => {
//         axios
//         .put(url, params)
//         .then(response => {
//             if(response.data.status === 1) {
//                 resolve(response.data.data)
//             } else {
//                 resolve('參數有誤')
//             }
//         })
//         .catch(error => {
//             reject(error)
//         })
//     })
// }
//
// const del = (url, params) => {
//     return new Promise((resolve, reject) => {
//         axios
//         .delete(url, params)
//         .then(response => {
//             if(response.data.status === 1) {
//                 resolve(response.data.data)
//             }
//             resolve('參數有誤')
//         })
//         .catch(error => {
//             reject(error)
//         })
//     })
// }
//
// const get = (url, query) => {
//     return new Promise((resolve, reject) => {
//         axios
//         .get(url, query)
//         .then(response => {
//             if (response.data.status === 1) {
//                 resolve(response.data.data)
//             } else {
//                 resolve('參數有誤')
//             }
//         }).catch(error => {
//             reject(error)
//         })
//     })
// }
//
// const $axios = {
//     post: post,
//     get: get,
//     put: put,
//     delete: del
// }

export default axios
