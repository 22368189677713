import axios from './axios.js'
import { encode, decode } from 'html-entities'
export default {
    namespaced: true,
    state: {
        merchantDays: null,
        merchantDay: null,
        merchantDayYears: []
    },
    actions: {
        getMerchantDays ({dispatch, commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/merchantDay`).then(response => {
                    commit('setMerchantDays', response)
                    if (response.length > 0) {
                        dispatch('getMerchantDay', response[0].year)
                    }
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getMerchantDay ({commit}, year) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/merchantDay/${year}`).then(response => {
                    commit('setMerchantDay', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        postMerchantDay ({dispatch}, data) {
            data.content = encode(data.content)
            return new Promise((resolve, reject) => {
                axios.post(`/api/merchantDay`, data).then(response => {
                    dispatch('getMerchantDays')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putMerchantDay ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/merchantDay/${data.year}`, {
                    content: encode(data.content),
                    edmPath: data.edmPath,
                    number: data.number
                }).then(response => {
                    dispatch('getMerchantDay', data.year)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        postMerchantDayImage ({dispatch, state}, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/merchantDay/${state.merchantDay.year}`, {
                    sort: state.merchantDay.maxSort + 1,
                    name: data.name,
                    image: data.image
                }).then(response => {
                    dispatch('getMerchantDays')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putMerchantDayImage ({state}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/merchantDay/${state.merchantDay.year}/${data.id}`, {
                    image: data.image,
                    name: data.name,
                    sort: data.sort
                }).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        delMerchantDay ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.delete(`/api/merchantDay/${data.year}`).then(response => {
                    dispatch('getMerchantDays')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        delMerchantDayImage ({dispatch, state}, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`/api/merchantDay/${state.merchantDay.year}/${id}`).then(response => {
                    dispatch('getMerchantDay', state.merchantDay.year)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    mutations: {
        setMerchantDays(state, data) {
            state.merchantDayYears = []
            data.forEach((item) => {
                item.content = decode(item.content)
                state.merchantDayYears.push(item.year)
            })
            state.merchantDays = data
        },
        setMerchantDay(state, data) {
            data.content = decode(data.content)
            data.id = data.id ? data.id.split(',') : []
            data.name = data.name ? data.name.split(',') : []
            data.sort = data.sort ? data.sort.split(',') : []
            data.maxSort = data.maxSort ? data.maxSort : 0

            let image = data.image ? data.image.split(',') : []
            image.forEach((value, key) => {
                image[key] = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${value}`
            })
            data.image = image
            state.merchantDay = data
        }
    },
    getters: {
        getMerchantDays: state => {
          return state.merchantDays
        },
        getMerchantDay: state => {
          return state.merchantDay
        },
        getMerchantDayYears: state => {
          return state.merchantDayYears
        },
    }
}
