<template>
  <v-app>
    <v-app-bar
        fixed
        color="white"
        elevate-on-scroll
        scroll-target="#scrolling"
    >
    <v-row d-flex justify="space-between" align="center" class="text-center">
        <v-col cols="10" md="3" sm="4">
            <!-- Header Logo -->
            <router-link :to="{ name:'home' }">
                <v-img width="100%" src="./header.svg"></v-img>
            </router-link>
        </v-col>
        <v-col cols="2" md="9" sm="2">
            <!-- Mobile Tool Bar Switch -->
            <template v-if="$vuetify.breakpoint.smAndDown" class="text-right">
                <v-app-bar-nav-icon @click="miniDrawer = true"></v-app-bar-nav-icon>
            </template>
            <!-- Tool Bar -->
            <template v-else>
                <v-menu offset-y open-on-hover v-for="(item, index) in menuItems" :key="index">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :to="item.route" color="grey darken-3"
                            text v-if="item.sons" x-large
                            v-bind="attrs" v-on="on"
                        >
                            {{ item.name }}
                        </v-btn>
                        <v-btn :href="item.route" target="_blank"
                            text color="grey darken-3" x-large v-else
                            v-bind="attrs" v-on="on"
                        >
                            {{ item.name }}
                        </v-btn>
                    </template>
                    <v-list v-if="item.sons">
                      <v-list-item v-for="(sonItem, sonIndex) in menuItems[index].sons" link :key="sonIndex">
                          <v-list-item-title @click.stop="routerTo(sonItem.route)">
                              {{ sonItem.name }}
                          </v-list-item-title>
                      </v-list-item>
                    </v-list>
                </v-menu>
                <v-tooltip bottom v-if="token">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="grey" v-bind="attrs" v-on="on"
                            @click="signout"
                        >
                            <v-icon>
                                mdi-logout
                            </v-icon>
                        </v-btn>
                    </template>
                    <span> 登出 </span>
                </v-tooltip>
            </template>
        </v-col>
    </v-row>
    </v-app-bar>
    <!-- Mobile Tool Bar  -->
    <v-navigation-drawer v-model="miniDrawer" app temporary right>
        <v-list nav>
            <template v-for="(item, index) in menuItems">
                <v-list-group v-if="item.sons" :key="index">
                    <template v-slot:activator>
                        <v-list-item-title v-text="item.name" @click.stop="routerTo(item.route)"></v-list-item-title>
                    </template>
                    <v-list-item v-for="(sonItem, sonIndex) in menuItems[index].sons" link :key="sonIndex">
                        <v-list-item-title v-text="sonItem.name" @click.stop="routerTo(sonItem.route)"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-item v-else :key="index">
                    <v-list-item-title v-text="item.name" @click.stop="friendURL(item.route)"></v-list-item-title>
                </v-list-item>
            </template>
        </v-list>
        <div class="text-center">
            <v-tooltip bottom v-if="token">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="grey" v-bind="attrs" v-on="on"
                        @click="signout"
                    >
                        <v-icon>
                            mdi-logout
                        </v-icon>
                    </v-btn>
                </template>
                <span> 登出 </span>
            </v-tooltip>
        </div>
    </v-navigation-drawer>
    <v-sheet class="overflow-y-auto" max-height="1000" style="padding-top: 60px;">
        <v-container fluid id="scrolling">
            <bread-crumb></bread-crumb>
            <router-view></router-view>
        </v-container>
        <v-row style="background-color: #EAEAEA" class="px-2">
            <v-col cols="12" md="4">
                <h2 class="primary--text"><router-link :to="{ name:'home' }">台灣省商業總會</router-link></h2>
                <div>
                    <span>
                        <v-icon color="green"> mdi-phone </v-icon> (02)2536-5455
                    </span>
                </div>
                <div>
                    <span>
                        <v-icon color="green"> mdi-fax </v-icon> (02)2521-1980
                    </span>
                </div>
                <div>
                    <v-icon color="green"> mdi-email </v-icon> <a class="black--text" href="mailto:tcoc@tcoc.org.tw">tcoc@tcoc.org.tw</a>
                </div>
                <div>
                    <span>
                        <v-icon color="green"> mdi-domain </v-icon> 台北市中山區松江路168號13樓
                        <v-btn icon target="_blank" href="https://www.google.com.tw/maps/place/台北市中山區松江路168號13樓">
                            <v-icon color="red"> mdi-map-marker</v-icon>
                        </v-btn>
                    </span>
                </div>
            </v-col>
            <v-col cols="12" md="4">
                <h2 class="primary--text"><a href="http://www.taiwanorigin.org.tw/" class="primary--text">原產地證明書服務處</a></h2>
                <div>
                    <span>
                        <v-icon color="green"> mdi-phone </v-icon> (02)2581-2832
                    </span>
                </div>
                <div>
                    <span>
                        <v-icon color="green"> mdi-fax </v-icon> (02)2567-9375
                    </span>
                </div>
                <div>
                    <v-icon color="green"> mdi-email </v-icon> <a class="black--text" href="mailto:cert@tcoc.org.tw">cert@tcoc.org.tw</a>
                </div>
                <div>
                    <span>
                        <v-icon color="green"> mdi-domain </v-icon> 台北市中山區松江路164巷17號1樓
                        <v-btn icon target="_blank" href="https://www.google.com.tw/maps/place/台北市中山區松江路164巷17號1樓">
                            <v-icon color="red"> mdi-map-marker</v-icon>
                        </v-btn>
                    </span>
                </div>
            </v-col>
            <v-col cols="12" md="4">
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <h2 class="indigo--text darken-3">快速連結</h2>
                    </v-col>
                    <v-col cols="6" class="pa-0">
                        <div v-for="(item, index) in footerLink" :key="index">
                            <v-btn small text :href="item.href" target="_blank">
                                <h3> {{ item.text }} </h3>
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        <v-btn text href="/#/tcocRent" target="_blank" class="mx-5" color="blue">
                            <v-icon> mdi-arrow-right-bold </v-icon>
                            <h3> 場地出租 </h3>
                        </v-btn>
                        <v-btn text href="http://lb.benchmarkemail.com//listbuilder/signupnew?bhNj8SV0aNJUPdA%252FuEdSu%252FG6VTMKFmIoriukPX34DrxcCKnj5b1ICvJh9pUeouFs" target="_blank" class="mx-5" color="blue">
                            <v-icon> mdi-arrow-right-bold </v-icon>
                            <h3> 訂閱電子報 </h3>
                        </v-btn>
                        <div>
                            <v-btn text href="https://www.facebook.com/tcoc.org" target="_blank" class="mx-5" color="blue">
                                <v-icon> mdi-facebook </v-icon>
                                <h3> 省商會臉書 </h3>
                            </v-btn>
                        </div>
                        <div class="text-right caption grey--text lighten-5">
                            <span>&copy; 2022 Zatz. All rights reserved</span>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <tool-form-login-dialog ref="formLogin" v-show="false"></tool-form-login-dialog>
        <tool-form-sign-up-dialog ref="formSignUp" v-show="false"></tool-form-sign-up-dialog>
    </v-sheet>
  </v-app>
</template>

<script>
import ToolFormLoginDialog from '@/components/ToolFormLoginDialog'
import ToolFormSignUpDialog from '@/components/ToolFormSignUpDialog'
import BreadCrumb from "@/components/BreadCrumb.vue";
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
      'tool-form-login-dialog': ToolFormLoginDialog,
      'tool-form-sign-up-dialog': ToolFormSignUpDialog,
      'bread-crumb': BreadCrumb
  },
  data() {
      return {
          miniDrawer: false,
          selectedItem: null,
          menuItems: [{
              name: '商會簡介',
              sons: [{
                  name: `理事長`, // 轉換
                  route: { name: 'chairman' }
              }, {
                  name: `理監事`, // 轉換
                  route: { name: 'supervisors' }
              }, {
                  name: '會員團體',
                  route: { name: 'memberGroup' }
              }, {
                  name: '台灣縣市商會聯網',
                  route: { name: 'chambers' }
              }],
              route: { name: 'tcoc' }
          }, {
              name: '商會動態',
              sons: [{
                  name: '商會月刊',
                  route: {  name: 'magazines' }
              }, {
                  name: '電子報',
                  route: {  name: 'tcocEdm' }
              }],
              route: {  name: 'tcocDynamic' }
          }, {
              name: '活動課程',
              sons: [{
                  name: '中小企業講習',
                  route: {  name: 'activities', query: {type: '1'} }
              }, {
                  name: '稅務講習',
                  route: {  name: 'activities', query: {type: '2'} }
              }, {
                  name: '其他活動',
                  route: {  name: 'activities', query: {type: '3'} }
              }],
              route: {  name: 'activities' }
          }, {
              name: '百大伴手禮',
              route: 'http://www.taiwanbest100.com.tw/'
          }, {
              name: '產證網',
              route: 'http://www.taiwanorigin.org.tw/'
          }, {
              name: '珠算網',
              route: 'http://www.abacus.org.tw/'
          }, {
              name: '商人節',
              sons: [{
                  name: '商人節大會',
                  route: {  name: 'merchantDay' }
              }, {
                  name: '各縣市剪影',
                  route: {  name: 'merchantDayOfCountry' }
              }, {
                  name: '商人節介紹',
                  route: {  name: 'merchantDayIntroduce' }
              }],
              route: {  name: 'merchantDay' }
          }],
          footerLink: [{
              href: 'http://www.chamber.org.tw/',
              text: '台灣縣市商會聯網'
          }, {
              href: 'http://www.abacus.org.tw/',
              text: '珠算全球資訊網'
          }, {
              href: 'http://www.taiwanorigin.org.tw/',
              text: '台灣原產地證明書服務網'
          }, {
              href: 'http://www.taiwanbest100.com.tw/',
              text: '台灣伴手禮'
          }]
      }
  },
  computed: {
    ...mapGetters({
      organize: 'Organize/getOrganize',
      token: 'Auth/getToken'
    })
  },
  created() {
      this.init()
  },
  methods: {
      ...mapActions({
          getOrganize: 'Organize/getOrganize',
          logout: 'Auth/logout',
      }),
      async init() {
          if(this.organize === ''){
              await this.getOrganize()
          }
          this.menuItems[0].sons[0].name = `理事長${this.organize.chairman_name}`
          this.menuItems[0].sons[1].name = `第${this.organize.supervisors_number}屆理監事`
      },
      routerTo(value) {
          this.$router.push(value)
      },
      friendURL(value) {
          window.open(value, '_blank')
      },
      signout() {
          this.logout()
          this.$router.push({ path: '/login' })
      }
  }
};
</script>

<style>
    .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
        margin-left: 0px !important;
    }

    img {
        image-rendering:-moz-crisp-edges;          /* Firefox        */
        image-rendering:-o-crisp-edges;            /* Opera          */
        image-rendering:-webkit-optimize-contrast; /* Safari         */
        image-rendering:optimize-contrast;         /* CSS3 Proposed  */
        -ms-interpolation-mode:nearest-neighbor;   /* IE8+           */
    }
</style>
