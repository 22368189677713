import axios from './axios.js'
import { encode, decode } from 'html-entities'
import dayjs from 'dayjs'
export default {
    namespaced: true,
    state: {
        activities: '',
        activity: '',
    },
    actions: {
        getActivities ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/activities`).then(response => {
                    commit('setActivities', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getActivity ({commit}, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/activities/${id}`).then(response => {
                    commit('setActivity', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        postActivity ({dispatch}, data) {
            data.content = encode(data.content)
            return new Promise((resolve, reject) => {
                axios.post(`/api/activities`, data).then(response => {
                    dispatch('getActivities')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putActivity ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/activities/${data.id}`, {
                    name: data.name,
                    date: data.date,
                    top: data.top,
                    published: data.published,
                    describe: data.describe,
                    content: encode(data.content)
                }).then(response => {
                    dispatch('getActivities')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putActivityImage ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/activities/${data.id}/banner`, {
                    banner: data.banner
                }).then(response => {
                    dispatch('getActivities')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putActivityPublished ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/activities/${data.id}/published`, {
                    published: data.published
                }).then(response => {
                    dispatch('getActivities')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        delActivity ({dispatch}, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`/api/activities/${id}`).then(response => {
                    dispatch('getActivities')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    mutations: {
        setActivities(state, data) {
            data.forEach((item) => {
                item.content = decode(item.content)
                item.date = dayjs(item.date).format('YYYY/MM/DD')
                item.banner = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.banner}`
            })

            state.activities = data
        },
        setActivity(state, data) {
            data.content = decode(data.content)
            data.banner = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.banner}`
            state.activity = data
        }
    },
    getters: {
        getActivities: state => {
          return state.activities
        },
        getActivity: state => {
          return state.activity
        },
    }
}
