<template>
    <div class="ma-3">
        <validation-observer v-slot="{ invalid }">
            <v-row dense align="center">
                <v-col cols="9">
                    <validation-provider name="標題" rules="required|max:45" v-slot="{ errors }">
                        <v-text-field counter="45" label="標題" v-model="data.name" class="title" :error-messages="errors"></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="2">
                    <validation-provider name="日期" rules="required" v-slot="{ errors }">
                        <v-text-field type="date" label="日期" v-model="data.date" class="title" :error-messages="errors"></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12">
                    <validation-provider name="簡述" rules="required|max:255" v-slot="{ errors }">
                        <v-text-field counter="255" label="簡述" v-model="data.describe" class="title" :error-messages="errors"></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="2">
                    <v-checkbox
                      v-model="data.top"
                      label="本篇置頂輪播"
                    ></v-checkbox>
                </v-col>
                <v-col cols="2">
                    <v-switch v-if="pageEditStatus"
                        v-model="data.published"
                        label="發布狀態"
                    ></v-switch>
                </v-col>
                <v-col cols="12">
                    <upload-tool :data.sync="data.banner" :loadingItem.sync="dialog.global" text="選擇預覽圖片"></upload-tool>
                    <span>最佳長寬3:1</span>
                    <p class="text-right" v-if="pageEditStatus">
                        <v-btn color="primary" @click="updateImage()"> 上傳 </v-btn>
                    </p>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" v-if="!dialog.global">
                    <editor-tool :data.sync="data.content" ref="createdEditor"></editor-tool>
                </v-col>
                <v-col cols="12" class="text-right">
                    <v-btn v-if="pageEditStatus"
                         @click="$refs.doubleCheckTool.setOpen(removeMessage, deleteActivity, data.id)"
                    >刪除</v-btn>
                    <v-btn class="ml-3" color="primary" v-if="pageEditStatus" @click="updateActivity" :disabled="invalid">更新</v-btn>
                    <v-btn color="primary" v-else @click="createActivity" :disabled="invalid || !data.banner">新增</v-btn>
                </v-col>
            </v-row>
        </validation-observer>
        <v-dialog v-model="dialog.global"
            persistent
            width="300"
        >
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-dialog>
        <double-check-tool ref="doubleCheckTool"></double-check-tool>
    </div>
</template>
<script>
    import { mapActions } from 'vuex'
    export default {
        data () {
            return {
                pageEditStatus: false,
                currentId: null,
                dialog: {
                    global: false
                },
                data: {
                    name: '',
                    date: '',
                    top: false,
                    banner: null,
                    describe: '',
                    content: ''
                }
            }
        },
        computed: {
            removeMessage() {
                return {
                  title: '刪除',
                  message: `您確定要從系統刪除該商會動態 <u>${this.data.name}</u> 嗎?`
                }
            },
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getActivity: 'Activities/getActivity',
                postActivity: 'Activities/postActivity',
                putActivity: 'Activities/putActivity',
                putActivityImage: 'Activities/putActivityImage',
                delActivity: 'Activities/delActivity',
            }),
            async init() {
                this.dialog.global = true
                this.currentId = this.$route.params.id
                if (this.currentId === 'add') {
                    this.pageEditStatus = false
                    this.data = {
                        name: '',
                        date: '',
                        top: false,
                        banner: '',
                        describe: '',
                        content: ''
                    }
                } else {
                    this.pageEditStatus = true
                    this.data = await this.getActivity(this.currentId)
                }
                this.dialog.global = false
            },
            async createActivity() {
                this.dialog.global = true
                const result = await this.postActivity(this.data)
                this.$router.push({ name: 'tcocDynamicDetial' , params: { id: result.id }})
                this.init()
            },
            async updateActivity() {
                this.dialog.global = true
                await this.putActivity(this.data)
                this.init()
            },
            async updateImage() {
                this.dialog.global = true
                await this.putActivityImage(this.data)
                this.init()
            },
            async deleteActivity(id) {
                await this.delActivity(id)
                this.$router.push({ name: 'tcocDynamic' })
            }
        }
    }
</script>
