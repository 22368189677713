<template>
    <div class="ma-3">
        <v-row>
            <v-col cols="12" md="6">
                <h1>台灣省商業總會理事長 - {{ organize.chairman_name }}</h1>
            </v-col>
            <v-col cols="6" sm="3">
                <v-btn outlined rounded color="deep-orange" to="/tcoc/memberGroup">會員團體名錄</v-btn>
            </v-col>
            <v-col cols="6" sm="3">
                <v-btn outlined rounded color="deep-orange" to="/tcoc/supervisors">第{{organize.supervisors_number}}屆理監事</v-btn>
            </v-col>
        </v-row>
        <v-row justify="center" v-if="chairman">
            <v-col cols="8">
            </v-col>
            <v-col cols="12" class="pa-0"></v-col>
            <v-col cols="12" md="3">
                <upload-tool :data.sync="chairman.image" :loadingItem.sync="disabled.uploadChairmanImage"></upload-tool>
                <!-- <v-img v-if="chairman.image" width="100%" :src="chairman.image"></v-img> -->
                <div class="text-right">
                    <v-btn color="primary" :disabled="disabled.uploadChairmanImage" :loading="loading.uploadChairmanImage"
                        @click="updateChairmanImage()"
                    >
                        上傳
                    </v-btn>
                </div>
            </v-col>
            <v-col cols="12" md="5">
                <editor-tool :data.sync="chairman.introduce" ref="introduceEditor" :height="300"></editor-tool>
            </v-col>
            <v-col cols="10" class="text-center">
                <editor-tool :data.sync="chairman.content" ref="contentEditor" :height="800"></editor-tool>
            </v-col>
            <v-col cols="12" class="text-right">
                <v-btn color="primary" :loading="loading.uploadChairman"
                    @click="updateChairman()"
                >
                    更新
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {
                disabled: {
                    uploadChairmanImage: true,
                },
                loading: {
                    uploadChairmanImage: false,
                    uploadChairman: false
                }
            }
        },
        computed: {
          ...mapGetters({
            organize: 'Organize/getOrganize',
            chairman: 'Chairman/getChairman'
          })
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
              getOrganize: 'Organize/getOrganize',
              getChairman: 'Chairman/getChairman',
              putChairmanImage: 'Chairman/putChairmanImage',
              putChairman: 'Chairman/putChairman'
            }),
            init() {
                if(this.organize === ''){
                    this.getOrganize()
                }
                if(this.chairman === ''){
                    this.getChairman()
                }
            },
            async updateChairmanImage() {
                this.loading.uploadChairmanImage = true
                await this.putChairmanImage(this.chairman)
                this.loading.uploadChairmanImage = false
            },
            async updateChairman() {
                this.loading.uploadChairman = true
                this.chairman.name = this.organize.chairman_name
                await this.putChairman(this.chairman)
                this.loading.uploadChairman = false
            }
        }
    }
</script>
