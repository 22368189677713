var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-3"},[_c('v-row',{attrs:{"dense":"","justify":"end","align":"center"}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog.created = true}}},[_vm._v("新增電子報")])],1),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-select',{attrs:{"items":_vm.edmsYears,"label":"請選擇年份","outlined":"","color":"deep-orange","hide-details":"","clearable":""},on:{"change":function($event){return _vm.findEdms()}},model:{value:(_vm.findValue.year),callback:function ($$v) {_vm.$set(_vm.findValue, "year", $$v)},expression:"findValue.year"}})],1),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-select',{attrs:{"items":_vm.months,"label":"請選擇月份","outlined":"","color":"deep-orange","hide-details":"","clearable":""},on:{"change":function($event){return _vm.findEdms()}},model:{value:(_vm.findValue.month),callback:function ($$v) {_vm.$set(_vm.findValue, "month", $$v)},expression:"findValue.month"}})],1),_vm._l((_vm.getCurrentPageItem),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-3",attrs:{"elevation":hover ? 5 : 2,"color":hover ? 'orange lighten-4': 'orange lighten-5'}},[_c('v-row',{staticClass:"mx-2",attrs:{"align":"center"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('iframe',{attrs:{"width":"700px","height":"300px","src":item.url,"frameborder":"0"}},[_c('p',[_vm._v("很抱歉，您的瀏覽器不支援網頁預覽，請點擊右側預覽。")])])]):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{staticClass:"title",attrs:{"cols":"8"}},[_c('h6',[_vm._v("台灣商業訊息報 - 電子報")]),_vm._v(" "+_vm._s(item.year)+"年"+_vm._s(item.month)+"月 ")]),_c('v-col',{staticClass:"body-1",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(item.describe)+" ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{on:{"click":function($event){_vm.$refs.doubleCheckTool.setOpen(_vm.removeEdm(item), _vm.delEdm, item.id)}}},[_vm._v(" 刪除 ")]),_c('v-btn',{staticClass:"mx-3",attrs:{"dark":"","color":"green","href":item.url,"target":"_blank"}},[_vm._v(" 預覽 ")])],1)],1)],1)],1)],1)]}}],null,true)})],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"color":"primary","length":_vm.getPages,"total-visible":7},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],2),_c('double-check-tool',{ref:"doubleCheckTool"}),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.dialog.global),callback:function ($$v) {_vm.$set(_vm.dialog, "global", $$v)},expression:"dialog.global"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1),(_vm.dialog.created)?_c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.dialog.created),callback:function ($$v) {_vm.$set(_vm.dialog, "created", $$v)},expression:"dialog.created"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.init()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("新增電子報")])],1),_c('v-card-text',{staticClass:"pt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.getYears,"label":"年份"},model:{value:(_vm.newEdm.year),callback:function ($$v) {_vm.$set(_vm.newEdm, "year", $$v)},expression:"newEdm.year"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.months,"label":"月份"},model:{value:(_vm.newEdm.month),callback:function ($$v) {_vm.$set(_vm.newEdm, "month", $$v)},expression:"newEdm.month"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"內容描述","rules":"required|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"counter":"300","outlined":"","label":"內容描述","error-messages":errors},model:{value:(_vm.newEdm.describe),callback:function ($$v) {_vm.$set(_vm.newEdm, "describe", $$v)},expression:"newEdm.describe"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"電子報程式","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":"請貼上電子報程式","error-messages":errors},model:{value:(_vm.newEdm.content),callback:function ($$v) {_vm.$set(_vm.newEdm, "content", $$v)},expression:"newEdm.content"}})]}}],null,true)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.createdEdm}},[_vm._v(" 儲存 ")])],1)],1)]}}],null,false,242269700)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }