<template>
    <v-row justify="space-around">
        <tool-form-forget-password-dialog ref="formForgetPassword"></tool-form-forget-password-dialog>
        <v-col cols="auto">
            <v-dialog max-width="600" v-model="diologStatus">
                <v-card>
                    <v-toolbar color="deep-orange" dark>
                        <h3>{{ Title }}</h3>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="diologStatus = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                <v-card-text class="mt-3 pa-3">
                    <v-row justify="center">
                        <v-col cols="12" md="8">
                            <v-text-field  outlined dense
                              v-model="data.email" label="電子信箱"
                              hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-text-field
                                v-model="data.password"
                                :append-icon="eyeIcon ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="eyeIcon ? 'text' : 'password'"
                                label="密碼" hide-details="auto"
                                outlined dense
                                @click:append="eyeIcon = !eyeIcon"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-row justify="space-between" dense>
                                <v-col cols="4">
                                    <v-btn text class="pa-0" @click="openThirdTarget()">還沒有帳號嗎?立即註冊!</v-btn>
                                </v-col>
                                <v-col cols="3" class="text-end">
                                    <v-btn text class="pa-0" color="grey" @click="forgetPasswordFunction()">忘記密碼</v-btn>
                                </v-col>
                            </v-row>
                            <p>或透過社群帳號登入
                                <v-btn icon>
                                    <v-icon large color="blue darken-3"> mdi-facebook </v-icon>
                                </v-btn>
                                <v-btn icon>
                                    <v-icon large color="red darken-1"> mdi-google </v-icon>
                                </v-btn>
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end mt-2">
                  <v-btn @click="diologStatus = false">關閉</v-btn>
                  <v-btn color="deep-orange" dark :loading="loading" @click="save()"> 註冊 </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import ToolFormForgetPasswordDialog from '@/components/ToolFormForgetPasswordDialog'

export default {
    components: {
        'tool-form-forget-password-dialog': ToolFormForgetPasswordDialog,
    },
  data () {
    return {
      diologStatus: false,
      eyeIcon: false,
      Title: '註冊/登入',
      thirdFunction: null,
      loading: false,
      data: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
      setOpen (target) {
      this.diologStatus = true
      this.data = {
          email: '',
          password: ''
      }
      this.thirdFunction = target
    },
    async save () {
      this.diologStatus = false
    },
    openThirdTarget() {
        this.diologStatus = false
        this.thirdFunction(this.setOpen)
    },
    forgetPasswordFunction() {
        this.diologStatus = false
        this.$refs.formForgetPassword.setOpen()
    }
  }
}
</script>
