<template>
    <div class="ma-3">
        <v-tooltip bottom v-if="token">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="grey" v-bind="attrs" v-on="on"
                    @click="logout"
                >
                    <v-icon>
                        mdi-logout
                    </v-icon>
                </v-btn>
            </template>
            <span> 登出 </span>
        </v-tooltip>
        <div v-if="token">
            <v-tabs grow show-arrows color="deep-orange" v-model="selectedTab">
                <v-tab v-for="(item, index) in tabs" :key="index" v-text="item" class="title"></v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab" class="py-3">
                <!-- 發表新活動 -->
                <v-tab-item>
                    <v-card outlined>
                        <v-card-text v-if="confirmRule">
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field label="活動名稱" outlined color="deep-orange" hide-details placeholder="請填寫您的活動名稱"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field label="主辦單位" outlined color="deep-orange" hide-details placeholder="請填寫該活動的主辦單位"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-file-input outlined color="deep-orange" hide-details accept="image/*" label="活動圖片"></v-file-input>
                                </v-col>
                                <v-col cols="12" md="7">
                                    <v-text-field label="活動描述" outlined color="deep-orange" hide-details placeholder="請簡單敘述活動內容"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-text-field label="活動網址" outlined color="deep-orange" hide-details placeholder="如果有活動網址，請填寫"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field label="聯絡人" outlined color="deep-orange" hide-details placeholder="請填寫該活動的聯繫人姓名"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field label="電話" outlined color="deep-orange" hide-details placeholder="請填寫該活動的聯繫電話"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field label="Email" outlined color="deep-orange" hide-details placeholder="請填寫該活動的Email"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field label="活動起日" type="date" outlined color="deep-orange" hide-details ></v-text-field>
                                    <v-text-field type="time" outlined color="deep-orange" hide-details ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field label="活動迄日" type="date" outlined color="deep-orange" hide-details ></v-text-field>
                                    <v-text-field type="time" outlined color="deep-orange" hide-details ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <span class="subtitle-2">活動地點</span>
                                        <v-checkbox hide-details label="線上活動"></v-checkbox>
                                        <v-checkbox hide-details label="實體活動"></v-checkbox>
                                        <v-text-field outlined dense color="deep-orange" hide-details placeholder="若有實體活動，請填寫地址"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <span class="subtitle-2">費用資訊</span>
                                    <span class="subtitle-2">請填寫價位名稱及金額</span>
                                        <v-card flat>
                                            <v-card-text>
                                                <v-row align="center">
                                                    <v-checkbox hide-details label="免費活動"></v-checkbox>
                                                </v-row>
                                                <v-row align="center">
                                                    <v-text-field dense outlined color="deep-orange" hide-details placeholder="例如：原價"></v-text-field>
                                                    <v-text-field dense outlined color="deep-orange" hide-details prepend-inner-icon="mdi-currency-usd" placeholder="1500"></v-text-field>
                                                </v-row>
                                                <v-row align="center" class="py-1">
                                                    <v-text-field dense outlined color="deep-orange" hide-details placeholder="例如：早鳥價"></v-text-field>
                                                    <v-text-field dense outlined color="deep-orange" hide-details prepend-inner-icon="mdi-currency-usd" placeholder="1300"></v-text-field>
                                                </v-row>
                                                <v-row align="center">
                                                    <v-text-field dense outlined color="deep-orange" hide-details placeholder="例如：會員價"></v-text-field>
                                                    <v-text-field dense outlined color="deep-orange" hide-details prepend-inner-icon="mdi-currency-usd" placeholder="1200"></v-text-field>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                </v-col>

                                <v-col cols="12">
                                    <v-row class="pa-3" align="end">
                                        <span class="subtitle-2">活動介紹</span>
                                        <v-spacer></v-spacer>
                                        <v-btn v-if="preview" color="grey" dark @click="preview = false">關閉預覽</v-btn>
                                        <v-btn v-else dark color="grey" @click="preview = true">預覽</v-btn>
                                    </v-row>
                                    <div class="pb-2">
                                    </div>
                                    <vue-editor v-model="editorContent" :editorOptions="editorSettings"/>
                                </v-col>
                                <v-col cols="12">
                                    <v-card flat v-if="preview">
                                        <v-card-text>
                                            <div class="ql-editor" v-html="editorContent"></div>
                                        </v-card-text>
                                    </v-card>
                                    <div class="text-right">
                                        <v-btn @click="confirmRule = confirmRuleCheckBox = false">取消</v-btn>
                                        <v-btn dark color="deep-orange" class="ml-2" @click="sendDialog = true">送出審核</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text v-else>
                            <h2>台灣商會聯合資訊網受理網路會員免費刊登活動訊息申請須知：</h2><br>
                            <div class="subtitle-1">
                                1. 活動訊息至遲應於開辦前三天提出申請。<br>
                                2. 活動內容應與商業服務有關之活動、講座或課程。<br>
                                3. 活動訊息刊登與否需經本網審查，如有違背公序良俗、虛偽不實、違反政府法令規定者，本網有權拒絕刊登或撤除。<br>
                                4. 活動訊息刊登後，參與者若發生任何爭議，概由刊登者與主辦單位自行與參與者依循正當法律途徑解決，本會歉難負責。
                            </div>
                            <div class="text-right">
                                <v-checkbox
                                    v-model="confirmRuleCheckBox"
                                    label="我同意上述規定"
                                ></v-checkbox>
                                <v-btn  @click="confirmRule = true" :disabled="!confirmRuleCheckBox">開始刊登</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <!-- 會員資訊 -->
                <v-tab-item>
                    <v-card outlined>
                        <v-card-text>
                            <v-chip label color="#17a2b8" dark class="mb-3">
                                <h3>個人資料</h3>
                            </v-chip>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field label="電子信箱" outlined color="deep-orange" hide-details disabled value="test@gmail.com"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field label="公協會/公司行號/個人" outlined color="deep-orange" hide-details value="台灣省商會"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field label="姓名" outlined color="deep-orange" hide-details value="王小明"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field label="電話" outlined color="deep-orange" hide-details value="(02)12345678#321"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn dark color="deep-orange">送出</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card outlined class="mt-5">
                        <v-card-text>
                            <v-chip label color="#17a2b8" dark class="mb-3">
                                <h3>變更密碼</h3>
                            </v-chip>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field label="原密碼" counter outlined color="deep-orange" hide-details
                                        placeholder="請輸入舊密碼" :type="showIcon.oldPassword ? 'text' : 'password'"
                                        :append-icon="showIcon.oldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="showIcon.oldPassword = !showIcon.oldPassword"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field label="新密碼" counter outlined color="deep-orange" hide-details
                                        placeholder="請輸入6~18位數新密碼" :type="showIcon.newPassword ? 'text' : 'password'"
                                        :append-icon="showIcon.newPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="showIcon.newPassword = !showIcon.newPassword"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field label="確認新密碼" counter outlined color="deep-orange" hide-details
                                        placeholder="請再輸入一次新密碼" :type="showIcon.confirmPassword ? 'text' : 'password'"
                                        :append-icon="showIcon.confirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="showIcon.confirmPassword = !showIcon.confirmPassword"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn dark color="deep-orange">送出</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <!-- 已申請的活動 -->
                <v-tab-item>
                    <v-row dense justify="end" align="center">
                        <v-col cols="12" v-for="(item, index) in data" :key="index">
                            <v-hover v-slot="{ hover }">
                                <v-card :elevation="hover ? 5 : 2" :color="hover ? 'orange lighten-4': 'orange lighten-5'" class="ma-3">
                                    <v-row align="center" class="mx-2">
                                        <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp">
                                            <v-img width="100%" :src="item.image"></v-img>
                                        </v-col>
                                        <v-col cols="12" md="10">
                                            <v-row dense align="center">
                                                <v-col cols="7">
                                                    <span v-for="(category, index) in categorys" :key="index">
                                                        <v-chip dark :color="category.color" v-if="category.status === item.status" class="ma-2">
                                                            {{ category.name }}
                                                        </v-chip>
                                                        <v-chip dark :color="category.color" v-if="category.status === 2 && today > dayjs(item.dateRelease).format('YYYY/MM/DD') && today < dayjs(item.dateline).format('YYYY/MM/DD')" class="ma-2">
                                                            {{ category.name }}
                                                        </v-chip>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-chip dark :color="category.color" v-if="category.status === 3 && today < dayjs(item.dateRelease).format('YYYY/MM/DD') " class="ma-2" v-bind="attrs" v-on="on">
                                                                    {{ category.name }}
                                                                </v-chip>
                                                            </template>
                                                            <span>
                                                                1. 活動訊息已送交審核不得自行更改<br>
                                                                2. 本網擁有最終審核活動刊登之權利
                                                            </span>
                                                        </v-tooltip>
                                                        <v-chip dark :color="category.color" v-if="category.status === 4 && today > dayjs(item.dateline).format('YYYY/MM/DD')" class="ma-2">
                                                            {{ category.name }}
                                                        </v-chip>
                                                    </span>
                                                </v-col>
                                                <v-col cols="5" class="text-right">
                                                    活動日期：{{ item.dateStart }} ~ {{ item.dateline }}
                                                </v-col>
                                                <v-col cols="8" class="title"> {{ item.title }} </v-col>
                                                <v-col cols="12" class="body-1"> {{ item.content }} </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <v-dialog v-model="sendDialog" width="500">
            <v-card>
                <v-card-title class="text-title lighten-2">
                    即將送出審核
                </v-card-title>
                <v-card-text>
                    1. 活動訊息已送交審核不得自行更改<br>
                    2. 本網擁有最終審核活動刊登之權利
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="deep-orange" text
                        @click="sendDialog = false"
                    >
                        同意，並送出審核
                    </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    </div>
</template>
<script>
    import { VueEditor, Quill } from "vue2-editor";
    import { ImageDrop } from "quill-image-drop-module"
    import ImageResize from "quill-image-resize-module--fix-imports-error"

    Quill.register("modules/imageDrop", ImageDrop)
    Quill.register("modules/imageResize", ImageResize)

    import { mapActions, mapGetters } from 'vuex'
    export default {
        components: { VueEditor },
        data () {
            return {
                today: this.dayjs().format('YYYY/MM/DD'),
                tabs: ['發表新活動', '會員資訊', '活動列表'],
                selectedTab: 0,
                editorContent: '',
                editorSettings: {
                    modules: {
                        imageDrop: true,
                        imageResize: {}
                    }
                },
                confirmRule: false,
                confirmRuleCheckBox: false,
                preview: false,
                sendDialog: false,
                showIcon: {
                    oldPassword: false,
                    newPassword: false,
                    confirmPassword: false
                },
                categorys: [{
                    status: 0,
                    name: '審核中',
                    color: 'deep-orange'
                }, {
                    status: 1,
                    name: '已通過審核',
                    color: 'deep-orange'
                }, {
                    status: 2,
                    name: '正在刊登',
                    color: 'blue'
                }, {
                    status: 3,
                    name: '尚未刊登',
                    color: 'blue'
                }, {
                    status: 4,
                    name: '已下架',
                    color: 'blue'
                }],
                data: [{
                    id: 1,
                    title: '活動名稱活動名稱活動名稱活動名稱',
                    content: '簡單描述簡單描述簡單描述簡單描述簡單描述簡單描述',
                    image: require('./bg_image_1.png'),
                    type:'1',
                    dateRelease: '2022/04/20',
                    dateStart: '2022/05/01',
                    dateline: '2022/05/30',
                    status: 0,
                }, {
                    id: 2,
                    title: '活動名稱活動名稱活動名稱活動名稱',
                    content: '簡單描述簡單描述簡單描述簡單描述簡單描述簡單描述',
                    image: require('./bg_image_1.png'),
                    type:'2',
                    dateRelease: '2022/02/01',
                    dateStart: '2022/03/01',
                    dateline: '2022/04/30',
                    status: 1,
                }, {
                    id: 3,
                    title: '活動名稱活動名稱活動名稱活動名稱',
                    content: '簡單描述簡單描述簡單描述簡單描述簡單描述簡單描述',
                    image: require('./bg_image_1.png'),
                    type:'3',
                    dateRelease: '2022/02/01',
                    dateStart: '2022/03/01',
                    dateline: '2022/04/30',
                    status: 1,
                }, {
                    id: 4,
                    title: '活動名稱活動名稱活動名稱活動名稱',
                    content: '簡單描述簡單描述簡單描述簡單描述簡單描述簡單描述',
                    image: require('./bg_image_1.png'),
                    type:'2',
                    dateRelease: '2022/02/01',
                    dateStart: '2022/03/01',
                    dateline: '2022/04/30',
                    status: 1,
                }, {
                    id: 5,
                    title: '活動名稱活動名稱活動名稱活動名稱',
                    content: '簡單描述簡單描述簡單描述簡單描述簡單描述簡單描述',
                    image: require('./bg_image_1.png'),
                    type:'1',
                    dateRelease: '2022/02/01',
                    dateStart: '2022/03/01',
                    dateline: '2022/03/30',
                    status: 1,
                }]
            }
        },
        computed: {
          ...mapGetters({
            token: 'Auth/getToken'
          })
        },
        methods : {
            ...mapActions({
                checkTokenExpiry: 'Auth/checkTokenExpiry',
                logout: 'Auth/logout'
            }),
            async init() {
                this.checkTokenExpiry()
            }
        }
    }
</script>
