import axios from './axios.js'
export default {
    namespaced: true,
    state: {
        users: null
    },
    actions: {
        getUsers ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/users`).then(response => {
                    commit('setUsers', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        postUsers ({}, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/users`, data
                ).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        putUsers ({}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/users/${data.id}`, {
                    group_name: data.group_name,
                    name: data.name,
                    phone: data.phone,
                    status: data.status,
                    subscription:data.subscription
                }).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        delUsers ({}, data) {
            return new Promise((resolve, reject) => {
                axios.delete(`/api/users/${data.id}`)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        forget ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/auth/forget`, {
                    email: data.email
                }).then(response => {
                    dispatch('getUsers')
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
    },
    mutations: {
        setUsers(state, data) {
            state.users = data
        }
    },
    getters: {
        getUsers(state) {
            return state.users
        }
    }
}
