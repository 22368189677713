import axios from './axios.js'
export default {
    namespaced: true,
    state: {
        token: null,
        expiry: null,
        account: null
    },
    actions: {
        login ({commit}, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/auth/adminLogin`, {
                    account: data.account,
                    password: data.password
                }).then(response => {
                    commit('setTokenAndExpiry', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        logout({commit}) {
            const data = {
                token: null,
                expiry: null,
                account: null
            }
            commit('setClearTokenAndExpiry', data)
        },
    },
    mutations: {
        setTokenAndExpiry(state, data) {
            if (data.token) {
                localStorage.setItem('authorization', data.token)
                state.token = localStorage.getItem('authorization')
            }
            if (data.expiry) {
                localStorage.setItem('expiry', data.expiry)
                state.expiry = localStorage.getItem('expiry')
            }
            if (data.account) {
                localStorage.setItem('account', data.account)
                state.account = localStorage.getItem('account')
            }
        },
        setClearTokenAndExpiry(state, data) {
            localStorage.clear()
            state.token = data.token
            state.expiry = data.expiry
            state.account = data.account
        }
    },
    getters: {
        getToken(state) {
            return state.token || localStorage.getItem('authorization')
        },
        getExpiry(state) {
            return state.expiry || localStorage.getItem('expiry')
        },
        getAccount(state) {
            return state.account || localStorage.getItem('account')
        }
    }
}
