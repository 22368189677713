<template>
    <div class="ma-3">
        <v-row dense  align="center">
            <v-col cols="12" sm="10" v-if="editMode ===0">
                <h1 v-if="merchantDay">當前編輯：第{{merchantDay.number}}屆商人節大會</h1>
            </v-col>
            <v-col cols="12" sm="2" class="text-right" v-if="editMode ===0">
                <v-btn color="primary" @click="newYear" class="mb-2">新增年度</v-btn>
                <v-select dense label="年度" :items="merchantDayYears" outlined v-if="merchantDayYears"
                    color="primary" hide-details :value="merchantDay ? merchantDay.year : ''" @change="changeYear"
                ></v-select>
            </v-col>
            <v-col cols="12" v-if="editMode ===0">
                <v-row justify="center" class="text-center">
                    <v-col cols="4">
                        <v-btn :outlined="selectedTab !== 0" :dark="selectedTab === 0" rounded  color="deep-orange" @click="selectedTab = 0">商人節活動報導</v-btn>
                    </v-col>
                    <v-col cols="4">
                        <v-btn :outlined="selectedTab !== 1" :dark="selectedTab === 1" rounded color="deep-orange" @click="selectedTab = 1" v-if="editMode === 0">各縣市活動剪影</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-tabs-items v-model="selectedTab" class="pa-3">
                    <v-tab-item>
                        <validation-observer v-slot="{ invalid }" v-if="editMode === 0">
                            <v-card flat color="lime lighten-4" v-if="merchantDay">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="4">
                                            <validation-provider name="年度" rules="required" v-slot="{ errors }">
                                                <v-select label="年度" :items="getYears" outlined color="primary"
                                                    v-model="merchantDay.year" :error-messages="errors" disabled
                                                ></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="4">
                                            <validation-provider name="屆數" rules="required|max:2" v-slot="{ errors }">
                                                <v-text-field counter="2" outlined label="屆數" v-model="merchantDay.number" color="primary" :error-messages="errors" type="number"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="4">
                                            <validation-provider name="表揚名單月刊期數" rules="required" v-slot="{ errors }">
                                                <v-autocomplete outlined v-if="magazines"
                                                    :items="magazines"
                                                    color="primary"
                                                    item-value="path"
                                                    item-text="name"
                                                    label="表揚名單月刊期數" :error-messages="errors"
                                                    v-model="merchantDay.edmPath"
                                                ></v-autocomplete>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <h3>報導內容</h3>
                                    <editor-tool :data.sync="merchantDay.content" :key="merchantDay.year"></editor-tool>
                                </v-card-text>
                            </v-card>
                            <div class="text-right pa-3">
                                <v-btn class="mx-3" @click="$refs.doubleCheckTool.setOpen(removeMerchantDay(merchantDay), delMerchantDay, merchantDay)">
                                    刪除
                                </v-btn>
                                <v-btn color="primary" @click="updateMerchantDay" :disabled="invalid">更新</v-btn>
                            </div>
                        </validation-observer>
                        <validation-observer v-slot="{ invalid }" v-else>
                            <v-card flat color="lime lighten-4" v-if="merchantDay">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="4">
                                            <validation-provider name="年度" rules="required" v-slot="{ errors }">
                                                <v-select label="年度" :items="getYears" outlined color="primary"
                                                    v-model="newMerchantDay.year" :error-messages="errors"
                                                ></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="4">
                                            <validation-provider name="屆數" rules="required|max:2" v-slot="{ errors }">
                                                <v-text-field counter="2" outlined label="屆數" v-model="newMerchantDay.number" color="primary" :error-messages="errors" type="number"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="4">
                                            <validation-provider name="表揚名單月刊期數" rules="required" v-slot="{ errors }">
                                                <v-autocomplete outlined
                                                    :items="magazines"
                                                    color="primary"
                                                    item-value="path"
                                                    item-text="name"
                                                    label="表揚名單月刊期數" :error-messages="errors"
                                                    v-model="newMerchantDay.edmPath"
                                                ></v-autocomplete>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <h3>報導內容</h3>
                                    <editor-tool :data.sync="newMerchantDay.content"></editor-tool>
                                </v-card-text>
                            </v-card>
                            <div class="text-right pa-3">
                                <v-btn @click="init()" class="mr-2">取消</v-btn>
                                <v-btn color="primary" @click="createdMerchantDay" :disabled="invalid">新增</v-btn>
                            </div>
                        </validation-observer>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row justify="center">
                            <v-col cols="12" sm="6" class="fill-height">
                                <validation-observer v-slot="{ invalid }">
                                    <v-card outlined color="pink lighten-5">
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12">
                                                    <upload-tool :data.sync="newMerchantDayImage.image" :loadingItem.sync="dialog.global" text="選擇圖片"></upload-tool>
                                                </v-col>
                                                <v-col cols="12">
                                                    <validation-provider name="說明" rules="required" v-slot="{ errors }">
                                                        <v-text-field outlined label="說明" v-model="newMerchantDayImage.name" color="primary" :error-messages="errors" class="mt-2"></v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                            </v-row>
                                            <div class="text-right">
                                                <v-btn color="primary" @click="createdMerchantDayImage" :disabled="invalid || !newMerchantDayImage.image"> 新增 </v-btn>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </validation-observer>
                            </v-col>
                            <template v-if="merchantDay">
                                <v-col cols="12" sm="6" v-for="(item, index) in merchantDay.id" :key="index" class="fill-height">
                                    <validation-observer v-slot="{ invalid }">
                                        <v-card outlined color="blue-grey lighten-5">
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <upload-tool :data.sync="merchantDay.image[index]" :loadingItem.sync="dialog.global" text="選擇新圖片"></upload-tool>
                                                    </v-col>
                                                    <v-col cols="12" sm="2">
                                                        <validation-provider name="排序" rules="required|numeric|max:2" v-slot="{ errors }">
                                                            <v-text-field counter="2" outlined label="排序" v-model="merchantDay.sort[index]" color="primary" :error-messages="errors" class="mt-2"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" sm="10">
                                                        <validation-provider name="說明" rules="required|max:45" v-slot="{ errors }">
                                                            <v-text-field counter="45" outlined label="說明" v-model="merchantDay.name[index]" color="primary" :error-messages="errors" class="mt-2"></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                                <div class="text-right">
                                                    <v-btn class="mx-3" @click="$refs.doubleCheckTool.setOpen(removeMerchantDayImage(merchantDay.sort[index]), delMerchantDayImage, item)">
                                                        刪除
                                                    </v-btn>
                                                    <v-btn color="primary" @click="updateMerchantDayImage(index)" :disabled="invalid"> 更新 </v-btn>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </validation-observer>
                                </v-col>
                            </template>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <double-check-tool ref="doubleCheckTool"></double-check-tool>
        <v-dialog v-model="dialog.global"
            persistent
            width="300"
        >
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-dialog>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {
                newMerchantDay: {},
                newMerchantDayImage: {},
                dialog: {
                    global: false
                },
                editMode: 0,
                selectedTab: 0
            }
        },
        computed: {
            ...mapGetters({
                merchantDays: 'MerchantDay/getMerchantDays',
                magazines: 'Magazines/getMagazines',
                merchantDay: 'MerchantDay/getMerchantDay',
                merchantDayYears: 'MerchantDay/getMerchantDayYears'
            }),
            getYears() {
                const currentYear = new Date().getFullYear() - 1911
                let result = []
                for (let year = currentYear + 3 ; year > currentYear-25 ; year--) {
                    result.push(year)
                }
                return result
            }
        },
        watch: {
            '$route.name'() {
                this.init()
            }
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getMagazines: 'Magazines/getMagazines',
                getMerchantDays: 'MerchantDay/getMerchantDays',
                getMerchantDay: 'MerchantDay/getMerchantDay',
                postMerchantDay: 'MerchantDay/postMerchantDay',
                putMerchantDay: 'MerchantDay/putMerchantDay',
                delMerchantDay: 'MerchantDay/delMerchantDay',
                postMerchantDayImage: 'MerchantDay/postMerchantDayImage',
                putMerchantDayImage: 'MerchantDay/putMerchantDayImage',
                delMerchantDayImage: 'MerchantDay/delMerchantDayImage',
            }),
            newYear() {
                this.editMode = 1
                this.selectedTab = 0
            },
            removeMerchantDay(item) {
                let result = {
                  title: '刪除',
                  message: `您確定要從系統刪除<u>${item.year}</u>年度商人節嗎?`
                }
                return result
            },
            removeMerchantDayImage(sort) {
                let result = {
                  title: '刪除',
                  message: `您確定要從系統刪除<u>${this.merchantDay.year}</u>年度排序<u>${sort}</u>的圖片嗎?`
                }
                return result
            },
            async init() {
                this.dialog.global = true
                this.editMode = 0
                this.selectedTab = 0
                if (!this.merchantDays) {
                    await this.getMerchantDays()
                }
                if (!this.magazines) {
                    await this.getMagazines()
                }
                if(this.$route.name === 'merchantDayOfCountry') {
                    this.selectedTab = 1
                }
                this.newMerchantDay = {
                    year: null,
                    number: null,
                    content: '',
                    edmPath: null
                }
                this.newMerchantDayImage = {
                    name: '',
                    image: null
                }
                this.dialog.global = false
            },
            async updateMerchantDay() {
                this.dialog.global = true
                await this.putMerchantDay(this.merchantDay)
                this.init()
            },
            async createdMerchantDay() {
                this.dialog.global = true
                await this.postMerchantDay(this.newMerchantDay)
                this.init()
            },
            async createdMerchantDayImage() {
                this.dialog.global = true
                await this.postMerchantDayImage(this.newMerchantDayImage)
                this.init()
            },
            async updateMerchantDayImage(id) {
                this.dialog.global = true
                await this.putMerchantDayImage({
                    id: this.merchantDay.id[id],
                    name: this.merchantDay.name[id],
                    sort: this.merchantDay.sort[id],
                    image: this.merchantDay.image[id]
                })
                this.init()
            },
            async changeYear(year) {
                this.dialog.global = true
                await this.getMerchantDay(year)
                this.init()
            }
        }
    }
</script>
