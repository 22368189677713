<template>
    <div>
        <input ref="editorImage" type="file" :accept="accept" v-show="false">
        <editor
            v-model="text" @change="dataChange()"
            api-key="hzfavngfeqhvixw4riq3hlw5b6oliak1djzodq4m52c25jk6"
            :init="{
                height: height,
                plugins: plugins,
                toolbar: toolbar,
                file_picker_types: fileType,
                paste_data_images: true,
                powerpaste_allow_local_images: true,
                image_advtab: true,
                image_class_list: [{title: 'Responsive', value: 'img-responsive'}],
                file_picker_callback: (callback, value, meta) => { editImageUpload(callback, value ,meta)  },
            }"
        />
    </div>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue'
    import { compressAccurately } from 'image-conversion'
    import { mapActions } from 'vuex'
    export default {
        components: {
            editor: Editor
         },
        data() {
            return {
                text: '',
                plugins: [
                    'advlist','autolink','lists','link','image','charmap','preview','anchor','searchreplace','visualblocks', 'code','fullscreen','insertdatetime','media','table', 'paste','help','wordcount'
                ],
                toolbar: 'undo redo | casechange blocks | bold italic backcolor underline forecolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlst checklist outdent indent | removeformat | link media image | code table help preview',
            }
        },
        props: {
            data: {
                default: '',
                type: String,
                required: true
            },
            height: {
                default: 800,
                type: Number,
                required: false
            },
            accept: {
                default: 'image/*',
                type: String,
                required: false
            },
            fileType: {
                default: 'image',
                type: String,
                required: false
            }
        },
        created() {
            this.text = this.data
        },
        watch: {
            text() {
                this.$emit('update:data', this.text)
            }
        },
        methods: {
            ...mapActions({
                uploadImage: 'Organize/updateImages'
            }),
            editImageUpload(callback, value ,meta) {
                // if (meta.filetype == 'file') {}
                if (meta.filetype == 'image') {
                    let input = this.$refs.editorImage
                    input.click(value)
                    input.onchange = async function () {
                        var file = await compressAccurately(input.files[0], 2000)
                        var reader = new FileReader()
                        reader.onload = function (e) {
                            callback(e.target.result, {
                                alt: file.name
                            });
                        };
                        reader.readAsDataURL(file);
                    };
                }
            },
            async getEditorImages() {
                let find = this.text.indexOf('data:image/')
                if (find !== -1) {
                    let target = `data:image${this.text.match(/src="data:image(\S*)"/)[1]}`
                    let url = await this.uploadImage(target)
                    this.text = this.text.replace(target, url)
                    await this.getEditorImages()
                } else {
                    return true
                }
            }
        }
    }
</script>
