import axios from './axios.js'
import { encode, decode } from 'html-entities'
import dayjs from 'dayjs'
export default {
    namespaced: true,
    state: {
        articles: '',
        notAuditArticles: ''
    },
    actions: {
        getArticles ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/articles`).then(response => {
                    commit('setArticles', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getNotAuditArticles ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/articles?alive=true&audit[0]=0`).then(response => {
                    commit('setNotAuditArticles', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getArticle ({dispatch}, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/articles/${id}`).then(response => {
                    response.teacher_image = response.teacher_image ? `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${response.teacher_image}` : ''
                    response.banner = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${response.banner}`
                    response.content = decode(response.content)
                    dispatch('getArticles')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        postArticle ({dispatch}, data) {
            data.content = encode(data.content)
            return new Promise((resolve, reject) => {
                axios.post(`/api/articles/byAdmin`, data).then(response => {
                    dispatch('getArticles')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putArticle ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/articles/${data.id}`, {
                    name: data.name,
                    type: data.type,
                    host: data.host,
                    teacher: data.teacher,
                    describe: data.describe,
                    start_date: data.start_date,
                    start_time: data.start_time,
                    end_date: data.end_date,
                    end_time: data.end_time,
                    website: data.website,
                    contact: data.contact,
                    phone: data.phone,
                    email: data.email,
                    address_online: data.address_online,
                    address_entity: data.address_entity,
                    address: data.address,
                    price: data.price,
                    content: encode(data.content),
                    audit: data.audit,
                    published: data.published
                }).then(response => {
                    dispatch('getArticles')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putArticlesPublished ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/articles/${data.id}/published`, {
                    published: data.published
                }).then(response => {
                    dispatch('getArticles')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putArticlesTeacherImage ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/articles/${data.id}/teacher_image`, {
                    teacher_image: data.teacher_image
                }).then(response => {
                    dispatch('getArticles')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putArticlesBannerImage ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/articles/${data.id}/banner`, {
                    banner: data.banner
                }).then(response => {
                    dispatch('getArticles')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putArticleAudit ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/articles/${data.id}/audit`, {
                    audit: data.audit
                }).then(response => {
                    dispatch('getNotAuditArticles')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        delArticle ({dispatch}, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`/api/articles/${id}`).then(response => {
                    dispatch('getArticles')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    mutations: {
        setArticles(state, data) {
            data.forEach((item) => {
                item.content = decode(item.content)
                item.start_date = dayjs(item.start_date).format('YYYY/MM/DD')
                item.end_date = dayjs(item.end_date).format('YYYY/MM/DD')
                item.teacher_image = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.teacher_image}`
                item.banner = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.banner}`
            })

            state.articles = data
        },
        setNotAuditArticles(state, data) {
            data.forEach((item) => {
                item.content = decode(item.content)
                item.start_date = dayjs(item.start_date).format('YYYY/MM/DD')
                item.end_date = dayjs(item.end_date).format('YYYY/MM/DD')
                item.teacher_image = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.teacher_image}`
                item.banner = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.banner}`
            })

            state.notAuditArticles = data
        },
    },
    getters: {
        getArticles: state => {
          return state.articles
        },
        getNotAuditArticles: state => {
          return state.notAuditArticles
        }
    }
}
