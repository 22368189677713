import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import stores from './stores'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, numeric, alpha, alpha_spaces, alpha_dash, email, required_if, confirmed } from 'vee-validate/dist/rules'

import './components/Tools'

import 'vue2-drawboard/lib/drawboard.css'

Vue.config.productionTip = false
Vue.use(VueToast)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)


extend('required', {
    ...required,
    message: '{_field_} 為必填項',
})

extend('numeric', {
    ...numeric,
    message: '{_field_} 只接受數字',
})

extend('alpha', {
    ...alpha,
    message: '{_field_} 只接受文字',
})

extend('alpha_spaces', {
    ...alpha_spaces,
    message: '{_field_} 只接受文字，可使用空白格',
})

extend('alpha_dash', {
    ...alpha_dash,
    message: '{_field_} 只接受文字及符號 - 與 ＿ ',
})

extend('email', {
    ...email,
    message: '{_field_} 只接受信箱格式，例如：tcoc@tcoc.org.tw',
})

extend('required_if', {
    ...required_if,
    message: '{_field_} 為必填項',
})

extend('max', {
    validate(value, { max }) {
         return value.toString().length <= max
    },
    params: ['max'],
    message: '{_field_} 最大字數為{max}',
})

extend('phone', {
    validate(value) {
         return /^[0-9()-]*$/.test(value)
    },
    message: '{_field_} 只接受電話格式，數字0~9，及符號 - 與()',
})

extend('alpha_comma', {
    validate(value) {
         return /^[\u4e00-\u9fa5\sA-Za-z0-9,]*$/.test(value)
    },
    message: '{_field_} 只接受文字及符號 ,',
})

extend('url', {
    validate(value) {
         return /^(?:(http|https|ftp):\/\/)?((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i.test(value)
    },
    message: '{_field_} 只接受網址格式，例如 https://www.tcoc.org.tw',
})

extend('basisGroupId', {
    validate(value) {
         return /^(HT)\d{3}-\d{3}$/.test(value)
    },
    message: '{_field_} 格式如下：HT[3碼數字]-[3碼數字]。例如：HT999-999',
})

extend('min', {
    validate(value, { min }) {
         return value.toString().length >= min
    },
    params: ['min'],
    message: '{_field_} 至少為{min}碼',
})

extend('confirmedA', {
    ...confirmed,
    message: '與確認{_field_} 內容需一致',
})
extend('confirmedB', {
    ...confirmed,
    message: '與前一欄位內容需一致',
})

new Vue({
  vuetify,
  router,
  store: stores,
  render: h => h(App)
}).$mount('#app')
