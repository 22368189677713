<template>
    <v-row justify="space-around">
        <v-col cols="auto">
            <v-dialog max-width="600" v-model="diologStatus">
                <v-card>
                    <v-toolbar color="deep-orange" dark>
                        <h3>{{ Title }}</h3>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="diologStatus = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                <v-card-text class="mt-3 pa-3">
                    <v-row justify="center">
                        <v-col cols="12" md="8">
                            <v-text-field  outlined dense
                              v-model="data.email" label="電子信箱"
                              hide-details="auto"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end mt-2">
                  <v-btn @click="diologStatus = false">關閉</v-btn>
                  <v-btn color="deep-orange" dark :loading="loading" @click="save()"> 取得新密碼 </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
export default {
  data () {
    return {
      diologStatus: false,
      eyeIconPassword: false,
      eyeIconConfirmPassword: false,
      Title: '忘記密碼',
      loading: false,
      data: {
        email: '',
        groupName: '',
        name: '',
        phone: '',
        password: '',
        confirmPassword: ''
      }
    }
  },
  methods: {
      setOpen () {
          this.diologStatus = true
          this.data = {
              email: '',
              groupName: '',
              name: '',
              phone: '',
              password: '',
              confirmPassword: ''
          }
      },
      async save () {
          this.diologStatus = false
      }
  }
}
</script>
