<template>
    <div class="ma-3">
        <v-row>
            <v-col cols="12" md="3">
                <h3>縣市聯網圖片：</h3>
                <upload-tool ref="image" :data.sync="organize.chamber" :loadingItem.sync="disabled"></upload-tool>
                <div class="text-right">
                    <v-btn color="primary" :disabled="disabled" :loading="loading.uploadImage"
                        @click="updateChamberImage()"
                    >
                        上傳
                    </v-btn>
                </div>
            </v-col>
            <v-col cols="8">
                <v-flex d-flex>
                    <h3>縣市聯網圖片超連結設定</h3>
                    <v-btn v-if="$refs.canvasDrawing && !canvas.show" @click="resetCanvas" class="mx-1" color="blue">開始</v-btn>
                </v-flex>
                <vue-drawing-canvas v-if="$refs.image && canvas.coordinates.length > 0"
                    ref="canvasDrawing"
                    :background-image="getCanvsaImage"
                    strokeType="square"
                    :lock="canvas.lock"
                    :initialImage="getCanvas"
                    :width="$refs.image.cropSize.width || 800"
                    :height="$refs.image.cropSize.height || 800"
                    @mouseup="canvas.lock = true"
                />

            </v-col>
            <v-col cols="12">
                <v-btn v-if="$refs.canvasDrawing && canvas.show" @click="resetCanvas" class="mx-1" color="blue">重設</v-btn>
                <v-btn v-if="$refs.canvasDrawing && canvas.show" @click="addItem" class="mx-1" color="green">增加一項</v-btn>
                <v-simple-table v-if="canvas.item.length > 0">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    no.
                                </th>
                                <th class="text-left">
                                    名稱
                                </th>
                                <th class="text-left">
                                    網址
                                </th>
                                <th class="text-left"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, key) in canvas.item"
                                :key="key"
                            >
                                <td> {{ key+1 }} </td>
                                <td>
                                    <v-text-field label="名稱" outlined dense
                                        v-model="item.name" class="pt-3"
                                    ></v-text-field>
                                </td>
                                <td width="800px">
                                    <v-text-field label="網址" outlined dense
                                        v-model="item.url" class="pt-3"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-btn @click="removeItem(key)" color="red" dark>刪除</v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <div class="text-right">
                    <v-btn v-if="$refs.canvasDrawing && canvas.show && canvas.item.length > 0" @click="save" color="primary" :loading="loading.uploadChamber">儲存</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import VueDrawingCanvas from "vue-drawing-canvas"
    import { uniq, map, sortBy, property, zip, join}  from 'lodash'
    export default {
        components: {
            VueDrawingCanvas,
        },
        data () {
            return {
                disabled: true,
                loading: {
                    uploadImage: false,
                    uploadChamber: false
                },
                canvas: {
                    show: false,
                    lock: true,
                    image: null,
                    item: [],
                    coordinates: []
                },
            }
        },
        computed: {
          ...mapGetters({
            organize: 'Organize/getOrganize',
            chamber: 'Chamber/getChambers'
          }),
          getCanvsaImage() {
              return this.canvas.image
          },
          getCanvas() {
              return this.canvas.coordinates
          },
        },
        created() {
            this.init()
        },
        watch : {
            'organize'() {
                if (this.organize.chamber) {
                    this.getBase64Image(this.organize.chamber)
                }
            },
            'chamber'() {
                this.setCanvas()
            }
        },
        methods:{
            ...mapActions({
              getOrganize: 'Organize/getOrganize',
              getChambers: 'Chamber/getChambers',
              putChamberImage: 'Chamber/putChamberImage',
              putChambers: 'Chamber/putChambers'
          }),
            resetCanvas() {
                this.canvas.lock = false
                this.$refs.canvasDrawing.reset()
                this.$refs.canvasDrawing.redraw()
                this.canvas.lock = true
                this.canvas.show = true
            },
            addItem() {
                this.canvas.lock = false
                this.$refs.canvasDrawing.redraw()
                this.canvas.item.push({
                    name: '',
                    url: ''
                })
            },
            removeItem(key) {
                this.canvas.item.splice(key,1)
                this.$refs.canvasDrawing.getAllStrokes().splice(key,1)
                this.$refs.canvasDrawing.redraw()
            },
            save() {
                const coordinates = { ...this.$refs.canvasDrawing.getAllStrokes() }
                const items = this.canvas.item
                this.canvas.item.forEach((item, key) => {
                    const x = uniq(map(sortBy(coordinates[key].coordinates, property(['x'])), 'x'))
                    const y = uniq(map(sortBy(coordinates[key].coordinates, property(['y'])), 'y'))
                    item.id = key
                    item.coords = join(zip(x, y), ',')
                })
                this.updateChamber()
            },
            async init() {
                await this.getOrganize()
                if(this.chamber === '') {
                    await this.getChambers()
                }
                this.setCanvas()
            },
            setCanvas(){
                this.canvas.item = [ ...this.chamber ]
                for (const values of this.chamber) {
                    const coords = values.coords.split(',')
                    this.canvas.coordinates.push({
                        color: '#ff00ff',
                        coordinates: [{x: parseInt(coords[2]), y: parseInt(coords[1])}, {x: parseInt(coords[2]), y: parseInt(coords[3])}, {x: parseInt(coords[0]), y: parseInt(coords[3])}, {x: parseInt(coords[0]), y: parseInt(coords[1])}],
                        fill: false,
                        from: {x: parseInt(coords[0]), y: parseInt(coords[1])},
                        lineCap: 'round',
                        lineJoin: 'miter',
                        type: 'square',
                        width: 5
                    })
                }
            },
            getBase64Image(imgUrl) {
                const image = new Image();
                image.crossOrigin='anonymous';
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.height = image.naturalHeight;
                    canvas.width = image.naturalWidth;
                    ctx.drawImage(image, 0, 0);
                    const dataUrl = canvas.toDataURL();
                    this.canvas.image = dataUrl
                }
                image.src = imgUrl;
            },
            async updateChamber() {
                this.loading.uploadChamber = true
                await this.putChambers(this.canvas.item)
                this.loading.uploadChamber = false
            },
            async updateChamberImage() {
                this.loading.uploadImage = true
                await this.putChamberImage({ image: this.organize.chamber})
                this.loading.uploadImage = false
            }
        }
    }
</script>
