var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"editorImage",attrs:{"type":"file","accept":_vm.accept}}),_c('editor',{attrs:{"api-key":"hzfavngfeqhvixw4riq3hlw5b6oliak1djzodq4m52c25jk6","init":{
            height: _vm.height,
            plugins: _vm.plugins,
            toolbar: _vm.toolbar,
            file_picker_types: _vm.fileType,
            paste_data_images: true,
            powerpaste_allow_local_images: true,
            image_advtab: true,
            image_class_list: [{title: 'Responsive', value: 'img-responsive'}],
            file_picker_callback: function (callback, value, meta) { _vm.editImageUpload(callback, value ,meta)  },
        }},on:{"change":function($event){return _vm.dataChange()}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }