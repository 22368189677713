<template>
  <v-breadcrumbs :items="breadCrumbs" v-if="breadCrumbs" class="pa-0">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item exact
        :to="item.to" class="text-subtitle-2 crumb-item"
        divider="/"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  computed: {
    breadCrumbs() {
        let response = []
        if (typeof this.$route.meta.breadCrumbs === "function") {
            response = this.$route.meta.breadCrumbs.call(this, this.$route)
        } else {
            response = this.$route.meta.breadCrumbs
        }
        if (Object.values(this.$route.params).length){
            response.push({
                to: null,
                text: Object.values(this.$route.params)[0]
            })
        }
        return response
    },
  },
};
</script>

<style>
