<template>
    <div class="ma-3">
        <v-row dense v-if="activities">
            <v-col cols="12" class="text-right">
                <v-btn color="primary" :to="`/tcocDynamic/add`">新增動態</v-btn>
            </v-col>
            <v-col cols="12" v-for="(item, index) in getCurrentPageItem" :key="index">
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 5 : 2" :color="hover ? 'orange lighten-4': 'orange lighten-5'" class="ma-3">
                        <v-row align="center" class="mx-2">
                            <v-col cols="2" md="2" v-if="$vuetify.breakpoint.mdAndUp">
                                <v-switch
                                    v-model="item.published" class="text-right" @change="updateActivityPublished(item)"
                                    label="發布狀態"
                                ></v-switch>
                                <v-img :aspect-ratio="16/9" :src="item.banner"></v-img>
                            </v-col>
                            <v-col cols="12" md="10">
                                <div class="text-right">
                                    <v-chip dark color="red" v-if="item.top" class="ma-2">已置頂輪播</v-chip>
                                    <v-chip dark color="green" v-if="item.published" class="ma-2">已發布</v-chip>
                                </div>
                                <v-row dense align="center">
                                    <v-col cols="12" md="8" class="title"> {{ item.name }} </v-col>
                                    <v-col cols="12" md="4" class="text-right">
                                        <span> {{ item.date }} </span>
                                    </v-col>
                                    <v-col cols="12" class="body-1"> {{ item.describe }} </v-col>
                                    <v-col cols="12" class="text-right">
                                        <v-btn class="mx-3" @click="$refs.doubleCheckTool.setOpen(removeActivity(item), delActivity, item.id)">
                                            刪除
                                        </v-btn>
                                        <v-btn color="primary" :to="`/tcocDynamic/${item.id}`">
                                            編輯
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12">
                <v-pagination color="primary"
                    v-model="currentPage"
                    :length="getPages"
                    :total-visible="7"
                ></v-pagination>
            </v-col>
        </v-row>
        <double-check-tool ref="doubleCheckTool"></double-check-tool>
        <v-dialog v-model="dialog.global"
            persistent
            width="300"
        >
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-dialog>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import _chunk  from 'lodash/chunk'
    export default {
        data () {
            return {
                dialog: {
                    global: false
                },
                currentPage: 1,
                pageChunk: 20
            }
        },
        computed: {
          ...mapGetters({
              activities: 'Activities/getActivities'
          }),
          getPages() {
              if (this.activities && this.activities.length > this.pageChunk){
                  return Math.ceil(this.activities.length / this.pageChunk)
              }
              return 1
          },
          getCurrentPageItem() {
              return _chunk(this.activities,this.pageChunk)[this.currentPage - 1]

          }
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getActivities: 'Activities/getActivities',
                putActivityPublished: 'Activities/putActivityPublished',
                delActivity: 'Activities/delActivity',
            }),
            async init() {
                this.dialog.global = true
                if (!this.activities) {
                    await this.getActivities()
                }
                this.dialog.global = false
            },
            async updateActivityPublished(data) {
                await this.putActivityPublished(data)
            },
            removeActivity(item) {
                let result = {
                  title: '刪除',
                  message: `您確定要從系統刪除該商會動態 <u>${item.name}</u> 嗎?`
                }
                return result
            },
        }
    }
</script>
