import axios from './axios.js'
export default {
    namespaced: true,
    state: {
        magazines: '',
        magazinesYears: []
    },
    actions: {
        getMagazines ({commit}, params) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/magazines`, { params }).then(response => {
                    commit('setMagazines', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        postMagazine ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/magazines`, data).then(response => {
                    dispatch('getMagazines')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putMagazine ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/magazines/${data.number}`, {
                    year: data.year,
                    month: data.month,
                    describe: data.describe
                }).then(response => {
                    dispatch('getMagazines')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putMagazineFile ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/magazines/${data.number}/file`, {
                    file: data.file
                }).then(response => {
                    dispatch('getMagazines')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        delMagazine ({dispatch}, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`/api/magazines/${id}`).then(response => {
                    dispatch('getMagazines')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    mutations: {
        setMagazines(state, data) {
            data.forEach((item) => {
                item.path = item.file
                item.file = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.file}`
                state.magazinesYears.push(item.year)
                item.name = `${item.year}年${item.month}月`
            })
            state.magazines = data
        }
    },
    getters: {
        getMagazines: state => {
            return state.magazines
        },
        getMagazinesYears: state => {
            return state.magazinesYears
        },
    }
}
