var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-3"},[_vm._l((_vm.supervisors),function(item,index){return _c('v-card',{key:index},[_c('v-row',{staticClass:"ma-5",attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"py-3"},[_vm._v("階層："+_vm._s(index))]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _vm._l((item),function(member,index){return _c('v-row',{key:index,attrs:{"align":"center"}},[(index === 0 || index > 0 && member.genre_name !== item[index-1].genre_name)?[_c('v-col',{attrs:{"cols":"9"}},[_c('validation-provider',{attrs:{"name":"抬頭","rules":"required|alpha|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:("title-" + (member.level_id)),refInFor:true,attrs:{"label":"抬頭","value":member.genre_name,"counter":"15","error-messages":errors}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.updateTitle(("title-" + (member.level_id)), item)}}},[_vm._v(" 更新名稱 ")]),_c('v-btn',{staticClass:"mx-3",attrs:{"color":"#f58a44","dark":""},on:{"click":function($event){return _vm.addSupervisor(member, item)}}},[_vm._v(" 新增一位 ")])],1)]:_vm._e()],2)})}}],null,true)})],1),_vm._l((item),function(member,index){return _c('v-col',{key:index,attrs:{"cols":"6","md":"2"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{attrs:{"flat":"","color":"#f4f4f4"}},[_c('v-row',{staticClass:"black--text ma-3",attrs:{"dense":"","justify":"center"}},[_c('v-col',{staticClass:"pb-0 pt-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(member.genre_name))])]),_c('v-col',{attrs:{"cols":"6","md":"10"}},[_c('upload-tool',{attrs:{"data":member.image,"loadingItem":_vm.uploadLoading},on:{"update:data":function($event){return _vm.$set(member, "image", $event)},"update:loadingItem":function($event){_vm.uploadLoading=$event},"update:loading-item":function($event){_vm.uploadLoading=$event}}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(member.name))]),_c('validation-provider',{attrs:{"name":"姓名","rules":"required|alpha|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"姓名","counter":"15","error-messages":errors},model:{value:(member.name),callback:function ($$v) {_vm.$set(member, "name", $$v)},expression:"member.name"}})]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"red","dark":_vm.findLevelItem(member.level_id, item),"disabled":!_vm.findLevelItem(member.level_id, item)},on:{"click":function($event){return _vm.removeSupervisor(member)}}},[_vm._v("刪除此牌卡")]),(member.id)?_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.updateSupervisor(member)}}},[_vm._v("更新此牌卡")]):_c('v-btn',{attrs:{"color":"primary","disabled":invalid || !member.image},on:{"click":function($event){return _vm.postSupervisor(member)}}},[_vm._v("新建此牌卡")])],1)]}}],null,true)})],1)})],2)],1)}),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.uploadLoading),callback:function ($$v) {_vm.uploadLoading=$$v},expression:"uploadLoading"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }