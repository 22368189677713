<template>
    <div class="ma-3">
        <v-row dense justify="end" align="center">
            <v-col cols="12" class="text-right">
                <v-btn color="primary" :to="`/activities/add`">新增活動課程</v-btn>
            </v-col>
            <v-col cols="12" class="text-right">
                <v-btn  v-for="(item, index) in categorys" :key="index"
                    dark large :color="item.color" class="mx-2" @click="selectType = item.type"
                >
                    {{ item.name }} {{ item.content }}
                    <v-badge color="white" light inline tile :content="getAlltypeCount" v-if="!item.type"></v-badge>
                    <v-badge color="white" light inline tile :content="getTypeCount[item.type] ? getTypeCount[item.type].length : '0'" v-else></v-badge>
                </v-btn>
            </v-col>
            <v-col cols="12" v-for="(item, index) in getCurrentPageItem" :key="index">
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 5 : 2" :color="hover ? 'orange lighten-4': 'orange lighten-5'" class="ma-3"
                        v-if="selectType === null || selectType === item.type"
                    >
                        <v-row align="center" class="mx-2">
                            <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp">
                                <v-row class="d-flex">
                                    <v-switch
                                        v-model="item.published" class="text-right" @change="updateArticlesPublished(item)"
                                        label="發布狀態" v-if="item.audit === 1"
                                    ></v-switch>
                                    <v-switch v-if="item.type === 3 && !item.audit"
                                        v-model="item.audit" class="text-right" @change="updateArticleAudit(item)"
                                        label="審核狀態"
                                    ></v-switch>
                                </v-row>
                                <v-img :aspect-ratio="2/1" :src="item.banner"></v-img>
                            </v-col>
                            <v-col cols="12" md="10">
                                <div class="text-right">
                                    <v-chip dark color="green" v-if="item.published" class="ma-2">已發布</v-chip>
                                    <v-chip dark color="grep" v-else class="ma-2">未發布</v-chip>

                                    <v-chip dark color="blue" v-if="item.type === 3 && item.audit" class="ma-2">已通過審核</v-chip>
                                    <v-chip dark color="grep" v-if="item.type === 3 && !item.audit" class="ma-2">未通過審核</v-chip>

                                    <v-chip dark color="grep" v-if="new Date() > new Date(`${item.end_date} ${item.end_time}`)" class="ma-2">已下架(過期)</v-chip>
                                </div>
                                <v-row dense align="center">
                                    <v-col cols="7">
                                        <span v-for="(category, index) in categorys" :key="index">
                                            <v-chip dark :color="category.color" v-if="category.type === item.type" class="ma-2">
                                                {{ category.name }}
                                            </v-chip>
                                        </span>
                                    </v-col>
                                    <v-col cols="5" class="text-right">
                                        活動日期：{{ item.start_date }} ~ {{ item.end_date }}
                                    </v-col>
                                    <v-col cols="8" class="title"> {{ item.name }} </v-col>
                                    <v-col cols="12" class="body-1"> {{ item.describe }} </v-col>
                                    <v-col cols="12" class="text-right">
                                        <v-btn class="mx-3" @click="$refs.doubleCheckTool.setOpen(removeArticles(item), delArticle, item.id)">
                                            刪除
                                        </v-btn>
                                        <v-btn color="primary" :to="`/activities/${item.id}`">
                                            編輯
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12">
                <v-pagination color="primary"
                    v-model="currentPage"
                    :length="getPages"
                    :total-visible="7"
                ></v-pagination>
            </v-col>
            <double-check-tool ref="doubleCheckTool"></double-check-tool>
        </v-row>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import _chunk  from 'lodash/chunk'
    import _groupBy  from 'lodash/groupBy'
    export default {
        data () {
            return {
                selectType: null,
                dialog: {
                    global: false
                },
                currentPage: 1,
                pageChunk: 20,
                categorys: [{
                    name: '全部',
                    type: null,
                    color: 'black'
                }, {
                    name: '中小企業講習',
                    type: 1,
                    color: 'blue lighten-2'
                }, {
                    name: '稅務講習',
                    type: 2,
                    color: 'green lighten-1'
                }, {
                    name: '其他活動',
                    type: 3,
                    color: 'orange lighten-1'
                }],
            }
        },
        computed: {
          ...mapGetters({
              articles: 'Articles/getArticles'
          }),
          getPages() {
              if (this.articles && this.articles.length > this.pageChunk){
                  return Math.ceil(this.articles.length / this.pageChunk)
              }
              return 1
          },
          getCurrentPageItem() {
              return _chunk(this.articles, this.pageChunk)[this.currentPage - 1]

          },
          getAlltypeCount() {
              return this.articles.length
          },
          getTypeCount() {
              return _groupBy(this.articles, 'type')
          },
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getArticles: 'Articles/getArticles',
                putArticlesPublished: 'Articles/putArticlesPublished',
                putArticleAudit: 'Articles/putArticleAudit',
                delArticle: 'Articles/delArticle',
            }),
            removeArticles(item) {
                let result = {
                  title: '刪除',
                  message: `您確定要從系統刪除該活動課程 <u>${item.name}</u> 嗎?`
                }
                return result
            },
            async init() {
                this.dialog.global = true
                if(this.$route.query.type) {
                    this.selectType = parseInt(this.$route.query.type)
                }
                if (!this.articles) {
                    await this.getArticles()
                }
                this.dialog.global = false
            },
            async updateArticlesPublished(data) {
                await this.putArticlesPublished(data)
            },
            async updateArticleAudit(data) {
                await this.putArticleAudit(data)
            },
        }
    }
</script>
<style>
    .v-badge__badge{
        color: black !important;
    }
</style>
