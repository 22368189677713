<template>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog max-width="600" v-model="diologStatus">
            <v-card>
              <v-toolbar
                color="primary"
                dark
              >
                  <h3>{{ title }}</h3>
                  <v-spacer></v-spacer>
                  <v-btn icon
                      @click="diologStatus = false"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-toolbar>
              <v-card-text class="mt-3 pa-3">
                  <v-row>
                      <v-col cols="12">
                          <h3 v-html="message"></h3>
                      </v-col>
                  </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-end mt-2">
                <v-btn @click="diologStatus = false">關閉</v-btn>
                <v-btn :loading="loading" color ="primary" @click="save()"> 執行 </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
      </v-col>
    </v-row>
</template>

<script>
export default {
    data () {
        return {
            data: null,
            diologStatus: false,
            title: '',
            message: '',
            loading: false,
            saveAction: null
        }
    },
    methods: {
        setOpen (removeText, action, data) {
            this.loading = false
            this.title = removeText.title
            this.data = data
            this.message = removeText.message
            this.diologStatus = true
            this.saveAction = action
        },
        async save () {
            this.loading = true
            let saveResult = await this.saveAction(this.data)
            if (saveResult) {
                this.loading = false
                this.diologStatus = false
            }
        }
    }
}
</script>
