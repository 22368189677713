import Vue from 'vue'
import Vuex from 'vuex'

import Auth from './auth'
import Organize from './organize'
import Administrative from './administrative'
import History from './history'
import Chairman from './chairman'
import Chamber from './chamber'
import Supervisors from './supervisors'
import MemberGroups from './memberGroups'
import Activities from './activities'
import Articles from './articles'
import Magazines from './magazines'
import Edms from './edms'
import MerchantDay from './merchantDay'
import Users from './users'
import Admins from './admins'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
    },
    modules: {
        Auth,
        Organize,
        Administrative,
        History,
        Chairman,
        Chamber,
        Supervisors,
        MemberGroups,
        Activities,
        Articles,
        Magazines,
        Edms,
        MerchantDay,
        Users,
        Admins
    }
})
