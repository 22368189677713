<template>
    <div class="ma-3">
        <validation-observer v-slot="{ invalid }">
            <v-row dense align="center">
                <v-col cols="12">
                    <validation-provider name="活動課程標題" rules="required" v-slot="{ errors }">
                        <v-text-field counter="45" outlined label="活動課程標題" v-model="data.name" class="title" :error-messages="errors"></v-text-field>
                    </validation-provider>
                    <validation-provider name="活動課程描述" rules="required|max:500" v-slot="{ errors }">
                        <v-text-field counter="500" outlined label="活動課程描述" v-model="data.describe" class="title" :error-messages="errors"></v-text-field>
                    </validation-provider>
                </v-col>
            </v-row>
            <v-card outlined flat v-if="!dialog.global">
                <v-card-text>
                    <v-row justify="space-between" class="px-5">
                        <v-col cols="6">
                            <span class="title">
                                活動資訊
                                <v-chip dark color="green" v-if="data.published" class="ma-2">已發布</v-chip>
                                <v-chip dark color="grep" v-else class="ma-2">未發布</v-chip>

                                <v-chip dark color="blue" v-if="data.type === 3 && data.audit" class="ma-2">已通過審核</v-chip>
                                <v-chip dark color="grep" v-if="data.type === 3 && !data.audit" class="ma-2">未通過審核</v-chip>

                                <v-chip dark color="grep" v-if="new Date() > new Date(`${data.end_date} ${data.end_time}`)" class="ma-2">已下架(過期)</v-chip>
                            </span>
                        </v-col>
                        <v-col cols="6">
                            <v-row justify="end">
                                <v-switch v-if="pageEditStatus && data.audit === 1"
                                    v-model="data.published" @change="putArticlesPublished(data)"
                                    label="發布狀態"
                                ></v-switch>
                                <v-switch v-if="pageEditStatus && data.type === 3 && !data.audit"
                                    v-model="data.audit" @change="putAudit"
                                    label="審核狀態"
                                ></v-switch>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="5">
                            <upload-tool :data.sync="data.banner" :loadingItem.sync="dialog.global" text="選擇預覽圖片"></upload-tool>
                            <p class="text-right" v-if="pageEditStatus">
                                <v-btn color="primary" @click="updateBannerImage()"> 上傳 </v-btn>
                            </p>
                        </v-col>
                        <v-col cols="12" md="7">
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                        <tr>
                                            <td class="title" width="30%">
                                                <v-icon color="green darken-2">mdi-shape</v-icon>
                                                類型
                                            </td>
                                            <td class="body-1" colspan="2">
                                                <span v-for="(category, index) in categorys" :key="index"
                                                    @click="data.type = category.type"
                                                >
                                                    <v-chip dark :disabled="pageEditStatus"
                                                        :color="category.type === data.type ? category.color : ''" class="ma-2"
                                                    >
                                                        <h3> {{ category.name }} </h3>
                                                    </v-chip>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="title">
                                                <v-icon color="green darken-2">mdi-domain</v-icon>
                                                主辦單位
                                            </td>
                                            <td class="body-1 py-2" colspan="2">
                                                <validation-provider name="主辦單位" rules="required|alpha|max:45" v-slot="{ errors }">
                                                    <v-text-field counter="45" outlined v-model="data.host" :error-messages="errors"></v-text-field>
                                                </validation-provider>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="title">
                                                <v-icon color="green darken-2">mdi-account</v-icon>
                                                講師
                                            </td>
                                            <td class="body-1 py-2" colspan="2">
                                                <tr>
                                                    <td>
                                                        <validation-provider name="講師" rules="required|alpha|max:30" v-slot="{ errors }">
                                                            <v-text-field counter="30" outlined v-model="data.teacher" :error-messages="errors"></v-text-field>
                                                        </validation-provider>
                                                        <upload-tool :data.sync="data.teacher_image" :loadingItem.sync="dialog.global" text="選擇講師圖片" class="my-2" :cropperBlock="false"></upload-tool>
                                                    </td>
                                                    <td v-if="data.teacher_image" class="pl-5">
                                                        <v-avatar size="90" class="mr-3">
                                                            <v-img :src="data.teacher_image"></v-img>
                                                        </v-avatar>
                                                        <v-btn v-if="pageEditStatus && data.teacher_image" color="primary" @click="updateTeacherImage()"> 上傳 </v-btn>
                                                    </td>
                                                </tr>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="title">
                                                <v-icon color="green darken-2">mdi-clock-time-nine-outline</v-icon>
                                                活動時間
                                            </td>
                                            <td width="40%" class="body-1 pt-2">
                                                <validation-provider name="起始日" rules="required" v-slot="{ errors }">
                                                    <v-text-field outlined type="date" label="起始日" v-model="data.start_date" :error-messages="errors"></v-text-field>
                                                </validation-provider>
                                                <validation-provider name="結束日" rules="required" v-slot="{ errors }">
                                                    <v-text-field outlined type="date" label="結束日" v-model="data.end_date" :error-messages="errors"></v-text-field>
                                                </validation-provider>
                                            </td>
                                            <td width="30%" class="body-1 pt-2">
                                                <validation-provider name="起始時間" rules="required" v-slot="{ errors }">
                                                    <v-text-field outlined type="time" label="起始時間" v-model="data.start_time" :error-messages="errors"></v-text-field>
                                                </validation-provider>
                                                <validation-provider name="結束時間" rules="required" v-slot="{ errors }">
                                                    <v-text-field outlined type="time" label="結束時間" v-model="data.end_time" :error-messages="errors"></v-text-field>
                                                </validation-provider>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="title">
                                                <v-icon color="green darken-2">mdi-currency-usd</v-icon>
                                                費用資訊
                                            </td>
                                            <td colspan="2">
                                                <v-card flat color="transparent">
                                                    <v-card-text>
                                                        <v-row class="mb-5">
                                                            <v-checkbox class="mt-0" hide-details v-model="freePriceStatus" @change="freePrice" label="免費活動"></v-checkbox>
                                                        </v-row>
                                                        <div v-if="!freePriceStatus">
                                                            <v-row align="center" v-if="data.price[0]">
                                                                <v-col class="d-flex">
                                                                    <validation-provider name="票種" rules="alpha" v-slot="{ errors }">
                                                                        <v-text-field dense outlined v-model="data.price[0].name" color="primary" placeholder="例如：原價" :error-messages="errors"></v-text-field>
                                                                    </validation-provider>
                                                                    <validation-provider name="售價" rules="numeric" v-slot="{ errors }">
                                                                        <v-text-field dense outlined v-model="data.price[0].value" color="primary" prepend-inner-icon="mdi-currency-usd" placeholder="1500" :error-messages="errors"></v-text-field>
                                                                    </validation-provider>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row align="center" class="py-1" v-if="data.price[1]">
                                                                <v-col class="d-flex">
                                                                    <validation-provider name="票種" rules="alpha" v-slot="{ errors }">
                                                                        <v-text-field dense outlined v-model="data.price[1].name" color="primary" placeholder="例如：原價" :error-messages="errors"></v-text-field>
                                                                    </validation-provider>
                                                                    <validation-provider name="售價" rules="numeric" v-slot="{ errors }">
                                                                        <v-text-field dense outlined v-model="data.price[1].value" color="primary" prepend-inner-icon="mdi-currency-usd" placeholder="1500" :error-messages="errors"></v-text-field>
                                                                    </validation-provider>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row align="center" v-if="data.price[2]">
                                                                <v-col class="d-flex">
                                                                    <validation-provider name="票種" rules="alpha" v-slot="{ errors }">
                                                                        <v-text-field dense outlined v-model="data.price[2].name" color="primary" placeholder="例如：原價" :error-messages="errors"></v-text-field>
                                                                    </validation-provider>
                                                                    <validation-provider name="售價" rules="numeric" v-slot="{ errors }">
                                                                        <v-text-field dense outlined v-model="data.price[2].value" color="primary" prepend-inner-icon="mdi-currency-usd" placeholder="1500" :error-messages="errors"></v-text-field>
                                                                    </validation-provider>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="title">
                                                <v-icon color="green darken-2">mdi-map-marker</v-icon>
                                                活動地點
                                            </td>
                                            <td class="py-2" colspan="2">
                                                <v-chip :color="data.address_online ? 'teal accent-3' : ''" class="mr-2" @click="data.address_online = !data.address_online">
                                                    <span class="body-1">線上活動</span>
                                                </v-chip>
                                                <v-chip :color="data.address_entity ? 'teal accent-3' : ''" class="mr-2" @click="data.address_entity = !data.address_entity">
                                                    <span class="body-1">實體活動</span>
                                                </v-chip>
                                                <validation-provider name="實體活動地點" rules="alpha_dash|max:45" v-slot="{ errors }">
                                                    <v-text-field counter="45" outlined label="實體活動地點" v-model="data.address" color="primary" v-if="data.address_entity" class="mt-2" :error-messages="errors"></v-text-field>
                                                </validation-provider>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="title">
                                                <v-icon color="green darken-2">mdi-domain</v-icon>
                                                聯絡人
                                            </td>
                                            <td class="py-2" colspan="2">
                                                <validation-provider name="聯絡人" rules="required|alpha|max:45" v-slot="{ errors }">
                                                    <v-text-field counter="45" outlined v-model="data.contact" :error-messages="errors"></v-text-field>
                                                </validation-provider>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="title">
                                                <v-icon color="green darken-2">mdi-phone</v-icon>
                                                聯絡電話
                                            </td>
                                            <td class="py-2" colspan="2">
                                                <validation-provider name="聯絡電話" rules="required|phone|max:45" v-slot="{ errors }">
                                                    <v-text-field counter="45" outlined v-model="data.phone" :error-messages="errors"></v-text-field>
                                                </validation-provider>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="title">
                                                <v-icon color="green darken-2">mdi-email</v-icon>
                                                Email
                                            </td>
                                            <td class="py-2" colspan="2">
                                                <validation-provider name="Email" rules="email|max:45" v-slot="{ errors }">
                                                    <v-text-field counter="45" outlined v-model="data.email" :error-messages="errors"></v-text-field>
                                                </validation-provider>
                                            </td>
                                        </tr>
                                        <tr v-if="data.website !== ''">
                                            <td class="title">
                                                <v-icon color="green darken-2">mdi-web</v-icon>
                                                活動網址
                                            </td>
                                            <td class="py-2" colspan="2">
                                                <validation-provider name="活動網址" rules="url|max:45" v-slot="{ errors }">
                                                    <v-text-field counter="45" outlined v-model="data.website" :error-messages="errors"></v-text-field>
                                                </validation-provider>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-row class="my-2">
                <v-col cols="12" v-if="!dialog.global">
                    <editor-tool :data.sync="data.content" ref="createdEditor"></editor-tool>
                </v-col>
                <v-col cols="12" class="text-right">
                    <v-btn v-if="pageEditStatus"
                         @click="$refs.doubleCheckTool.setOpen(removeMessage, deleteArticle, data.id)"
                    >刪除</v-btn>
                    <v-btn class="ml-3" color="primary" v-if="pageEditStatus" @click="updateArticle" :disabled="invalid">更新</v-btn>
                    <v-btn color="primary" v-else @click="createArticle" :disabled="invalid || !data.banner">新增</v-btn>
                </v-col>
            </v-row>
        </validation-observer>
        <v-card flat outlined color="grey lighten-2">
            <v-card-text class="Caption">
                台灣商會聯合資訊網聲明：<br>
                本活動訊息為網路會員自由刊登，本網並非主辦單位僅作文字允當之審核，報名參加活動者若發生任何爭議，請自行與主辦單位聯繫解決，本網歉難負責。
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialog.global"
            persistent
            width="300"
        >
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-dialog>
        <double-check-tool ref="doubleCheckTool"></double-check-tool>
    </div>
</template>
<script>
    import { mapActions } from 'vuex'
    export default {
        data () {
            return {
                pageEditStatus: false,
                currentId: null,
                dialog: {
                    global: false
                },
                freePriceStatus: true,
                data: {},
                categorys: [{
                    name: '中小企業講習',
                    type: 1,
                    color: 'blue lighten-2'
                }, {
                    name: '稅務講習',
                    type: 2,
                    color: 'green lighten-1'
                }, {
                    name: '其他活動',
                    type: 3,
                    color: 'orange lighten-1'
                }]
            }
        },
        computed: {
            removeMessage() {
                return {
                  title: '刪除',
                  message: `您確定要從系統刪除該活動課程 <u>${this.data.name}</u> 嗎?`
                }
            },
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getArticle: 'Articles/getArticle',
                postArticle: 'Articles/postArticle',
                putArticle: 'Articles/putArticle',
                putArticlesTeacherImage: 'Articles/putArticlesTeacherImage',
                putArticlesBannerImage: 'Articles/putArticlesBannerImage',
                delArticle: 'Articles/delArticle',
                putArticlesPublished: 'Articles/putArticlesPublished',
                putArticleAudit: 'Articles/putArticleAudit',
            }),
            async init() {
                this.dialog.global = true
                this.currentId = this.$route.params.id
                if (this.currentId === 'add') {
                    this.pageEditStatus = false
                    this.data = {
                        name: '',
                        type: 1,
                        host: '',
                        teacher: '',
                        teacher_image: null,
                        banner: null,
                        describe: '',
                        start_date: '',
                        start_time: '',
                        end_date: '',
                        end_time: '',
                        website: '',
                        contact: '',
                        phone: '',
                        email: '',
                        address_online: true,
                        address_entity: true,
                        address: '',
                        price: [],
                        content: '',
                    }
                } else {
                    this.pageEditStatus = true
                    this.data = await this.getArticle(this.currentId)
                    if (this.data.price.length > 0) {
                        this.freePriceStatus = false
                    }
                }
                this.dialog.global = false
            },
            async createArticle() {
                this.dialog.global = true
                const result = await this.postArticle(this.data)
                this.$router.push({ name: 'activitiesDetial' , params: { id: result.id }})
                this.init()
            },
            async updateArticle() {
                this.dialog.global = true
                try {
                    await this.putArticle(this.data)
                    this.init()
                } catch (e) {
                    this.dialog.global = false
                }
            },
            async updateBannerImage() {
                this.dialog.global = true
                await this.putArticlesBannerImage(this.data)
                this.init()
            },
            async updateTeacherImage() {
                this.dialog.global = true
                await this.putArticlesTeacherImage(this.data)
                this.init()
            },
            async deleteArticle(id) {
                await this.delArticle(id)
                this.$router.push({ name: 'activities' })
            },
            async putAudit() {
                await this.putArticleAudit(this.data)
                this.init()
            },
            freePrice(value) {
                if (value) {
                    this.data.price = []
                } else {
                    this.data.price = [{
                        name: '',
                        value: null
                    }, {
                        name: '',
                        value: null
                    }, {
                        name: '',
                        value: null
                    }]
                }
            }
        }
    }
</script>
