import axios from './axios.js'
import { encode, decode } from 'html-entities'

export default {
    namespaced: true,
    state: {
        administrative: ''
    },
    actions: {
        getAdministrative ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/administrative`).then(response => {
                    commit('setAdministrative', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putAdministrativeImage ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/administrative/image`, {
                     image: data.image
                 }).then(response => {
                     dispatch('getAdministrative')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        putAdministrative ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/administrative`, {
                     content: encode(data.content)
                 }).then(response => {
                     dispatch('getAdministrative')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        }
    },
    mutations: {
        setAdministrative(state, data){
            data.content = decode(data.content)
            data.image = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.image}`
            state.administrative = data
        }
    },
    getters: {
        getAdministrative: state => {
          return state.administrative
        },
    }
}
