<template>
    <div class="ma-3">
        <v-row dense justify="end" align="center">
            <v-col cols="12" class="text-right">
                <v-btn color="primary" @click="dialog.created = true">新增月刊</v-btn>
            </v-col>
            <v-col cols="6" md="2" >
                <v-select :items="magazinesYears" label="請選擇年份" outlined color="deep-orange" hide-details clearable v-model="findValue.year" @change="findMagazine()"></v-select>
            </v-col>
            <v-col cols="6" md="2">
                <v-select :items="months" label="請選擇月份" outlined color="deep-orange" hide-details clearable v-model="findValue.month" @change="findMagazine()"></v-select>
            </v-col>
            <v-col cols="12" md="1" class="text-center" align="center"> 或 </v-col>
            <v-col cols="12" md="2">
                <v-text-field label="請輸入期數" type="number" outlined color="deep-orange" hide-details clearable v-model="findValue.number" @change="findMagazine()"></v-text-field>
            </v-col>
            <v-col cols="12" v-for="(magazine, index) in getCurrentPageItem" :key="index">
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 5 : 2" :color="hover ? 'orange lighten-4': 'orange lighten-5'" class="ma-3">
                        <v-row align="center" class="mx-2">
                            <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp">
                                <pdf v-if="magazine.file" :src="magazine.file"></pdf>
                            </v-col>
                            <v-col cols="12" md="10">
                                <v-row dense align="center">
                                    <v-col cols="8" class="title"> {{ magazine.year }} 年 {{ magazine.month }} 月號 {{ magazine.number }} 期 </v-col>
                                    <v-col cols="12" class="body-1"> {{ magazine.describe }} </v-col>
                                    <v-col cols="12" class="text-right">
                                        <v-btn @click="$refs.doubleCheckTool.setOpen(removeMagazine(magazine), delMagazine, magazine.number)">
                                            刪除
                                        </v-btn>
                                        <v-btn class="mx-3" dark color="green" @click="preview(magazine)">
                                            預覽
                                        </v-btn>
                                        <v-btn color="primary" @click="edit(magazine)">
                                            編輯
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12">
                <v-pagination color="primary"
                    v-model="currentPage"
                    :length="getPages"
                    :total-visible="7"
                ></v-pagination>
            </v-col>
        </v-row>
        <double-check-tool ref="doubleCheckTool"></double-check-tool>
        <v-dialog v-model="dialog.global"
            persistent
            width="300"
        >
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-dialog>
        <!-- 觀看月刊 -->
        <v-dialog v-model="dialog.preview">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog.preview = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ preMagazine.year }} 年 {{ preMagazine.month }} 月號 {{ preMagazine.number }} 期</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pt-3">
                    <embed v-if="preMagazine.file" type="video/webm" :src="`${preMagazine.file}#&navpanes=0`" width="100%" style="max-height: 50rem; min-height: 50rem">
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- 新增月刊 -->
        <v-dialog v-model="dialog.created" v-if="dialog.created" width="50%">
            <validation-observer v-slot="{ invalid }">
              <v-card>
                  <v-toolbar dark color="primary">
                      <v-btn icon dark @click="init()">
                          <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>新增月刊</v-toolbar-title>
                  </v-toolbar>
                <v-card-text class="pt-3">
                    <v-row>
                        <v-col cols="6">
                            <validation-provider name="期數" rules="required|numeric|max:4" v-slot="{ errors }">
                                <v-text-field counter="4" outlined label="期數" :error-messages="errors"
                                    v-model="newMagazine.number"
                                ></v-text-field>
                            </validation-provider>
                            <v-select outlined
                                v-model="newMagazine.year"
                                :items="getYears"
                                label="年份"
                            ></v-select>
                            <v-select outlined
                                v-model="newMagazine.month"
                                :items="months"
                                label="月份"
                            ></v-select>
                            <validation-provider name="內容描述" rules="required|max:350" v-slot="{ errors }">
                                <v-textarea counter="350" outlined label="內容描述" :error-messages="errors"
                                    v-model="newMagazine.describe"
                                ></v-textarea>
                            </validation-provider>
                        </v-col>
                        <v-col cols="6">
                            <upload-tool :data.sync="newMagazine.file" :loadingItem.sync="dialog.global"
                                :showFileName="true" fileType="application/pdf"
                            ></upload-tool>
                            <embed v-if="newMagazine.file.preview" type="video/webm" :src="`${newMagazine.file.preview}#toolbar=0`" width="100%" style="max-height: 60rem; min-height: 20rem">
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="createdMagazine" :disabled="invalid || !newMagazine.file"> 儲存 </v-btn>
                </v-card-actions>
              </v-card>
          </validation-observer>
        </v-dialog>
        <!-- 編輯月刊 -->
        <v-dialog v-model="dialog.edit" v-if="dialog.edit" width="50%">
            <validation-observer v-slot="{ invalid }">
              <v-card>
                  <v-toolbar dark color="primary">
                      <v-btn icon dark @click="dialog.edit = false">
                          <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>編輯 - {{ editMagazine.year }} 年 {{ editMagazine.month }} 月號 {{ editMagazine.number }} 期</v-toolbar-title>
                  </v-toolbar>
                <v-card-text class="pt-3">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field outlined label="期數" disabled
                                v-model="editMagazine.number"
                            ></v-text-field>
                            <v-select outlined
                                v-model="editMagazine.year"
                                :items="getYears"
                                label="年份"
                            ></v-select>
                            <v-select outlined
                                v-model="editMagazine.month"
                                :items="months"
                                label="月份"
                            ></v-select>
                            <validation-provider name="內容描述" rules="required|max:350" v-slot="{ errors }">
                                <v-textarea counter="350" outlined label="內容描述" :error-messages="errors"
                                    v-model="editMagazine.describe"
                                ></v-textarea>
                            </validation-provider>
                        </v-col>
                        <v-col cols="6">
                            <upload-tool :data.sync="editMagazine.file" :loadingItem.sync="dialog.global"
                                :showFileName="true" fileType="application/pdf"
                            ></upload-tool>
                            <embed v-if="editMagazine.file.preview" type="video/webm" :src="`${editMagazine.file.preview}#toolbar=0`" width="100%" style="max-height: 60rem; min-height: 20rem">
                            <v-btn v-if="editMagazine.file.data" dark color="primary" @click="updateMagazineFile(editMagazine)"> 上傳 </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="updateMagazine" :disabled="invalid"> 更新 </v-btn>
                </v-card-actions>
              </v-card>
            </validation-observer>
        </v-dialog>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import _chunk  from 'lodash/chunk'
    import pdf from 'vue-pdf'
    export default {
        components: {
            pdf
        },
        data () {
            return {
                months: [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
                dialog: {
                    created: false,
                    global: false,
                    preview: false,
                    edit: false
                },
                currentPage: 1,
                pageChunk: 20,
                newMagazine: {},
                preMagazine: {},
                editMagazine: {},
                findValue: {
                    year: null,
                    month: null,
                    number: null
                }
            }
        },
        computed: {
          ...mapGetters({
              magazines: 'Magazines/getMagazines',
              magazinesYears: 'Magazines/getMagazinesYears'
          }),
          getPages() {
              if (this.magazines && this.magazines.length > this.pageChunk){
                  return Math.ceil(this.magazines.length / this.pageChunk)
              }
              return 1
          },
          getCurrentPageItem() {
              return _chunk(this.magazines, this.pageChunk)[this.currentPage - 1]

          },
          getYears() {
              const currentYear = new Date().getFullYear() - 1911
              let result = []
              for (let year = currentYear + 3 ; year > currentYear - 25 ; year--) {
                  result.push(year)
              }
              return result
          }
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getMagazines: 'Magazines/getMagazines',
                postMagazine: 'Magazines/postMagazine',
                putMagazine: 'Magazines/putMagazine',
                putMagazineFile: 'Magazines/putMagazineFile',
                delMagazine: 'Magazines/delMagazine',
            }),
            preview(data) {
                this.preMagazine = { ...data }
                this.dialog.preview = true
            },
            edit(data) {
                this.editMagazine = {
                    ...data,
                    file: {
                        data: '',
                        preview: data.file
                    }
                }
                this.dialog.edit = true
            },
            removeMagazine(item) {
                let result = {
                  title: '刪除',
                  message: `您確定要從系統刪除該月刊 <u>${item.year} 年 ${item.month} 月號 ${item.number} 期</u> 嗎?`
                }
                return result
            },
            async findMagazine() {
                let request = {}
                if (this.findValue.number) {
                    request.number = this.findValue.number
                    this.findValue.year = this.findValue.month = null
                }
                if (this.findValue.year) {
                    request.year = this.findValue.year
                }
                if (this.findValue.month) {
                    request.month = this.findValue.month
                }

                if (request) {
                    await this.getMagazines(request)
                } else {
                    await this.getMagazines()
                }
            },
            async init() {
                this.dialog.global = true
                this.newMagazine = {
                    number: '',
                    year: new Date().getFullYear() - 1911,
                    month: new Date().getMonth() + 1,
                    describe: '',
                    file: ''
                }
                if (!this.magazines) {
                    await this.getMagazines()
                }
                this.dialog = {
                    created: false,
                    global: false,
                    preview: false,
                    edit: false
                }
            },
            async createdMagazine() {
                this.dialog.global = true
                await this.postMagazine({
                    ...this.newMagazine,
                    file: this.newMagazine.file.data
                })
                this.init()
            },
            async updateMagazineFile() {
                this.dialog.global = true
                this.editMagazine.file = this.editMagazine.file.data
                await this.putMagazineFile(this.editMagazine)
                this.init()
            },
            async updateMagazine() {
                this.dialog.global = true
                await this.putMagazine(this.editMagazine)
                this.init()
            }
        }
    }
</script>
