<template>
    <div class="pa-3" ref="updateTool">
        <v-row d-flex class="pb-1">
            <v-btn
                :color="color"
                class="text-none mr-2"
                depressed :dark="dark"
                @click="$refs.uploader.click()"
            >
                <v-icon left>
                    mdi-upload
                </v-icon>
                {{ text }}
            </v-btn>
            <v-btn icon @click="clearFile" v-if="file">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-row>
        <v-row>
            <p v-if="showFileName" class="pt-3"> {{ fileName }} </p>
            <input
                ref="uploader"
                class="d-none"
                type="file"
                :accept="fileType"
                @change="onFileChanged"
            >
        </v-row>
        <v-row v-show="fileType === 'image/*' && file" class="grey pa-3" v-if="cropperBlock">
            <cropper
                class="cropper"
                ref="cropper"
                :src="file"
                @change="updateCropSize"
            />
            <v-btn class="my-3" color="green" @click="cropImage"> 完成裁減 </v-btn>
            <div class="size-info" v-if="cropSize.width && cropSize.height">
                <div>Width: {{ cropSize.width }}px</div>
                <div>Height: {{ cropSize.height }}px</div>
            </div>
        </v-row>
    </div>

</template>

<script>
    import { compressAccurately } from 'image-conversion'
    import { Cropper } from 'vue-advanced-cropper';
    import 'vue-advanced-cropper/dist/style.css';
    export default {
        components: {
    		Cropper
    	},
        data() {
            return {
                file: '',
                fileName: null,
                width: 0,
    			height: 0,
    			left: 0,
    			top: 0,
                cropSize: {
                    width: null,
                    height: null,
                }
            }
        },
        props: {
            text: {
                default: '選取檔案',
                type: String,
                required: false
            },
            color: {
                default: 'primary',
                type: String,
                required: false
            },
            dark: {
                default: false,
                type: Boolean,
                required: false
            },
            showFileName: {
                default: false,
                type: Boolean,
                required: false
            },
            csvHeaders: {
                default: null,
                type: Array,
                required: false
            },
            fileType: {
                default: 'image/*',
                type: String,
                required: false
            },
            data: {
                default: '',
                required: true
            },
            loadingItem: {
                default: true,
                type: Boolean,
                required: true
            },
            cropperBlock: {
                default: true,
                type: Boolean,
                required: false
            }
        },
        watch: {
            data: function() {
                if (this.data) {
                    this.file = this.data
                    this.setCropperSize()
                }
            }
        },
        created() {
            if (this.data) {
                this.file = this.data
            }
        },
        mounted() {
            this.setCropperSize()
    	},
    	destroyed() {
    		window.removeEventListener('resize', this.refresh);
    		window.removeEventListener('orientationchange', this.refresh);
    	},
        computed: {
    		boxStyle() {
    			return {
    				width: `${this.width}px`,
    				height: `${this.height}px`,
    				left: `${this.left}px`,
    				top: `${this.top}px`
    			};
    		}
    	},
        methods: {
            clearFile() {
                this.$emit('update:data', null)
                this.file =  null
                this.fileName = null
            },
            updateCropSize({ coordinates }){
                this.cropSize.width = Math.round(coordinates.width);
                this.cropSize.height = Math.round(coordinates.height);
            },
            setCropperSize(){
                const { container, cropper } = this.$refs;
        		if (container) {
        			this.updateCoordinates(container.clientWidth, container.clientHeight, 0, 0);
        		}
        		window.addEventListener('resize', this.refresh);
        		window.addEventListener('orientationchange', this.refresh);
                if(cropper) {
                    const center = {
                        left: cropper.coordinates.left + cropper.coordinates.width / 2,
                        top: cropper.coordinates.top + cropper.coordinates.height / 2,
                    };
                    cropper.setCoordinates([
                        ({ coordinates, imageSize }) => ({
                            width: imageSize.width,
                            height: imageSize.height,
                        }),
                        ({ coordinates, imageSize }) => ({
                            left: center.left - coordinates.width / 2,
                            top: center.top - coordinates.height / 2,
                        }),
                    ])
                }
            },
            cropImage() {
                const { canvas } = this.$refs.cropper.getResult()
                this.file = canvas.toDataURL()
                this.$emit('update:data', this.file)
                this.$emit('update:loadingItem', false)
                this.setCropperSize()
            },
            onFileChanged(file) {
                return new Promise(async (resolve, reject) => {
                    const reader = new FileReader();
                    switch (this.fileType) {
                        case '.csv':
                            reader.readAsText(file.target.files[0], 'big5')
                            break
                        case 'application/pdf':
                            reader.readAsDataURL(file.target.files[0])
                            break
                        case 'image/*': // default
                            const compressFile = await compressAccurately(file.target.files[0], 2000)
                            reader.readAsDataURL(compressFile)
                    }
                    this.fileName = file.target.files[0].name
                    reader.onload = () => {
                        switch (this.fileType) {
                            case '.csv':
                                this.file = this.transferCsvToJson(reader.result)
                                break
                            case 'application/pdf':
                                this.file = this.transferPdfObjectURL(reader.result, file.target.files[0])
                                break
                            case 'image/*': // default
                                this.file = reader.result
                        }
                        this.setCropperSize()
                        this.$emit('update:data', this.file)
                        this.$emit('update:loadingItem', false)
                        resolve(true)
                    }
                    reader.onerror = (error) => {
                        this.$emit('update:loadingItem', true)
                        reject(false)
                    }
                })
            },
            updateCoordinates(width, height) {
    			const { container } = this.$refs;
    			if (container) {
    				this.width = Math.min(Math.max(0, width), container.clientWidth);
    				this.height = Math.min(Math.max(0, height), container.clientHeight);
    				this.left = container.clientWidth / 2 - this.width / 2;
    				this.top = container.clientHeight / 2 - this.height / 2;
    				this.$refs.cropper.refresh();
    			}
    		},
            refresh() {
    			this.updateCoordinates(this.width, this.height);
    		},
            onResize(event) {
	            const { container } = this.$refs;
    			if (container) {
    				const directions = { ...event.directions };
    				if (directions.left) {
    					directions.right = directions.left;
    				}
    				if (directions.right) {
    					directions.left = directions.right;
    				}
    				if (directions.top) {
    					directions.bottom = directions.top;
    				}
    				if (directions.bottom) {
    					directions.top = directions.bottom;
    				}
    				const width = this.width + directions.left + directions.right;
    				const height = this.height + directions.top + directions.bottom;
    				this.updateCoordinates(width, height);
    			}
    		},
            transferPdfObjectURL(data, preview) {
                return {
                    data: data,
                    preview: URL.createObjectURL(preview)
                }
            },
            transferCsvToJson(data) {
                let csvToJsonResult = []
                data = data.replace(', ', ',')
                let lines = data.toString().split('\n')
                let headers = this.csvHeaders ? this.csvHeaders : lines[0].split(',')
                for(let i = 1 ; i < lines.length ; i++) {
                    if (lines[i]) {
                        let jsonObject = {};
                        let currentArrayString = lines[i]
                        let string = ''

                        let quoteFlag = 0
                        for (let character of currentArrayString) {
                            if (character === '"' && quoteFlag === 0) {
                                quoteFlag = 1
                            }
                            else if (character === '"' && quoteFlag == 1) quoteFlag = 0

                            if (character === ',' && quoteFlag === 0) character = '|'
                            if (character !== '"') string += character
                        }
                        string = string.replace('\r', '')
                        string = string.replace(' ', '')
                        let jsonProperties = string.split("|")

                        for(let j = 0 ; j < headers.length; j++) {
                            jsonObject[headers[j]] = jsonProperties[j]
                        }
                        csvToJsonResult.push(jsonObject)
                    }
                }
                return csvToJsonResult
            }
        }

    }
</script>
<style lang="scss">
.cropper-refresh {
	.container {
		width: 100%;
		height: 400px;
		position: relative;
	}
	.cropper {
		width: 100%;
		height: 100%;
	}
	.box {
		position: absolute;
	}
	.line {
		border-color: rgba(#3fb37f, 0.5);
	}
	.handler {
		background: #3fb37f;
	}
}
</style>
