<template>
    <div class="ma-3">
        <v-card v-for="(item, index) in supervisors" :key="index">
            <v-row justify="space-around" class="ma-5">
                <v-col cols="12">
                    <h3 class="py-3">階層：{{ index }}</h3>
                    <validation-observer v-slot="{ invalid }">
                        <v-row align="center" v-for="(member, index) in item" :key="index">
                            <template v-if="index === 0 || index > 0 && member.genre_name !== item[index-1].genre_name">
                                <v-col cols="9">
                                    <validation-provider name="抬頭" rules="required|alpha|max:15" v-slot="{ errors }">
                                        <v-text-field label="抬頭" :value="member.genre_name" counter="15"
                                            :ref="`title-${member.level_id}`" :error-messages="errors"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="3" class="text-right">
                                    <v-btn color="primary" :disabled="invalid" @click="updateTitle(`title-${member.level_id}`, item)">
                                        更新名稱
                                    </v-btn>
                                    <v-btn class="mx-3" color="#f58a44" dark @click="addSupervisor(member, item)"> 新增一位 </v-btn>
                                </v-col>
                            </template>
                        </v-row>
                    </validation-observer>
                </v-col>
                <v-col cols="6" md="2" v-for="(member, index) in item" :key="index">
                    <validation-observer v-slot="{ invalid }">
                        <v-card flat color="#f4f4f4">
                            <v-row dense justify="center" class="black--text ma-3">
                                <v-col cols="12" class="pb-0 pt-2">
                                    <span class="title"> {{ member.genre_name }}</span>
                                </v-col>
                                <v-col cols="6" md="10">
                                    <upload-tool :data.sync="member.image" :loadingItem.sync="uploadLoading"></upload-tool>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <h2>{{ member.name }}</h2>
                                    <validation-provider name="姓名" rules="required|alpha|max:15" v-slot="{ errors }">
                                        <v-text-field label="姓名" counter="15" v-model="member.name" :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </v-card>
                        <div class="text-right">
                            <v-btn color="red" :dark="findLevelItem(member.level_id, item)" :disabled="!findLevelItem(member.level_id, item)" @click="removeSupervisor(member)">刪除此牌卡</v-btn>
                            <v-btn color="primary" v-if="member.id" :disabled="invalid" @click="updateSupervisor(member)">更新此牌卡</v-btn>
                            <v-btn color="primary" v-else :disabled="invalid || !member.image" @click="postSupervisor(member)">新建此牌卡</v-btn>
                        </div>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog v-model="uploadLoading"
            persistent
            width="300"
        >
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-dialog>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {
                uploadLoading: false,
                titles: {}
            }
        },
        computed: {
          ...mapGetters({
                supervisors: 'Supervisors/getSupervisors',
            }),
            findLevelItem() {
                return (levelId, items) => {
                    let count = 0
                    items.forEach((item) => {
                        if(item.level_id === levelId) {
                            count++
                        }
                    })
                    return count > 1 ? true : false
                }
            }
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
              getSupervisors: 'Supervisors/getSupervisors',
              postSupervisor: 'Supervisors/postSupervisor',
              putSupervisor: 'Supervisors/putSupervisor',
              delSupervisor: 'Supervisors/delSupervisor',
            }),
            init() {
                if(this.supervisors === ''){
                    this.getSupervisors()
                }
                if(this.chairman === ''){
                    this.getChairman()
                }
            },
            closeLoading() {
                this.uploadLoading = false
            },
            addSupervisor(member, items) {
                items.push({
                    genre_name: member.genre_name,
                    level: member.level,
                    level_id: member.level_id,
                    name: '',
                    image:''
                })
            },
            async removeSupervisor(member) {
                if (member.id) {
                    this.uploadLoading = true
                    await this.delSupervisor(member.id)
                    this.closeLoading()
                } else {
                    await this.getSupervisors()
                }
            },
            async updateSupervisor(data) {
                this.uploadLoading = true
                await this.putSupervisor(data)
                this.closeLoading()
            },
            async updateTitle(refName, items) {
                this.uploadLoading = true
                const newTitle = this.$refs[refName][0].$el.getElementsByTagName('input')[0].value
                const levelId = parseInt(refName.split('-')[1])
                let data = []
                items.forEach((item) => {
                    if (item.level_id === levelId){
                        item.genre_name = newTitle
                        data.push(item)
                    }
                })
                let request = []
                for (let item of data) {
                    request.push(this.putSupervisor(item))
                }
                Promise.all(request).then()
                await this.getSupervisors()
                this.closeLoading()
            },
        }
    }
</script>
