import axios from './axios.js'
import groupBy from 'group-array'

export default {
    namespaced: true,
    state: {
        supervisors: ''
    },
    actions: {
        getSupervisors ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/supervisors`).then(response => {
                    commit('setSupervisors', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putSupervisorsImage ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/supervisors/${data.id}/image`, {
                     image: data.image
                 }).then(response => {
                     dispatch('getSupervisors')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        putSupervisors ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/supervisors/${data.id}`, {
                    genre_name: data.genre_name,
                    name: data.name,
                    sort: data.sort
                }).then(response => {
                    dispatch('getSupervisors')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putSupervisor ({dispatch}, data) {
            if (data.image.indexOf('data:image/') !== -1) {
                data.image = dispatch('putSupervisorsImage', data)
            }
             return new Promise((resolve, reject) => {
                 axios.put(`/api/supervisors/${data.id}`, {
                     genre_name: data.genre_name,
                     name: data.name,
                 }).then(response => {
                     // dispatch('getSupervisors')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        postSupervisor ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.post(`/api/supervisors`, data).then(response => {
                     dispatch('getSupervisors')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        delSupervisor ({dispatch}, id) {
             return new Promise((resolve, reject) => {
                 axios.delete(`/api/supervisors/${id}`).then(response => {
                     dispatch('getSupervisors')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        }
    },
    mutations: {
        setSupervisors(state, data){
            data.forEach((item) => {
                item.image = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.image}`
            })
            state.supervisors = groupBy(data, 'level')
        }
    },
    getters: {
        getSupervisors: state => {
          return state.supervisors
        }
    }
}
