import axios from './axios.js'
import { encode, decode } from 'html-entities'
export default {
    namespaced: true,
    state: {
        organize: '',
        result: null,
    },
    actions: {
        getOrganize ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/organize`).then(response => {
                    commit('setOrganize', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putOrganizeImage ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/organize/image`, {
                     image: data.image
                 }).then(response => {
                     dispatch('getOrganize')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        putOrganizeMastersImage ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/organize/masters`, {
                     masters: data.masters
                 }).then(response => {
                     dispatch('getOrganize')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        putOrganizeGroupActivities ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/organize/groupActivities`, {
                     data: data.group_activities.data
                 }).then(response => {
                     dispatch('getOrganize')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        putOrganizeTaxWorkshop ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/organize/taxWorkshop`, {
                     data: data.tax_workshop.data
                 }).then(response => {
                     dispatch('getOrganize')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        putOrganizeRent ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/organize/rent`, {
                     data: data.rent.data
                 }).then(response => {
                     dispatch('getOrganize')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        putOrganize ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/organize`, {
                     chairman_number: data.chairman_number,
                     chairman_name: data.chairman_name,
                     describe: encode(data.describe),
                     supervisors_number: data.supervisors_number,
                     content: encode(data.content)
                 }).then(response => {
                     dispatch('getOrganize')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        // 要移去文章的Store
        updateImages({commit}, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/articles/editorImage`, {
                    image: data
                }).then(response => {
                    commit('setResult', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    mutations: {
        setOrganize(state, data) {
            data.describe = decode(data.describe)
            data.content = decode(data.content)
            data.image = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.image}`
            data.masters = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.masters}`
            data.group_activities = data.group_activities?`${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.group_activities}`: null
            data.tax_workshop = data.tax_workshop?`${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.tax_workshop}`: null
            data.rent = data.rent?`${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.rent}`: null
            data.chamber = data.chamber?`${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.chamber}`: null
            state.organize = data
        },
        setResult(state, data) {
            state.result = data
        }
    },
    getters: {
        getOrganize: state => {
          return state.organize
        },
    }
}
