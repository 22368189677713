<template>
    <div class="ma-3">
        <v-row dense justify="end" align="center">
            <v-col cols="12" class="text-right">
                <v-btn color="primary" @click="dialog.created = true">新增電子報</v-btn>
            </v-col>
            <v-col cols="6" md="2" >
                <v-select :items="edmsYears" label="請選擇年份" outlined color="deep-orange" hide-details clearable v-model="findValue.year" @change="findEdms()"></v-select>
            </v-col>
            <v-col cols="6" md="2">
                <v-select :items="months" label="請選擇月份" outlined color="deep-orange" hide-details clearable v-model="findValue.month" @change="findEdms()"></v-select>
            </v-col>
            <v-col cols="12" v-for="(item, index) in getCurrentPageItem" :key="index">
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 5 : 2" :color="hover ? 'orange lighten-4': 'orange lighten-5'" class="ma-3">
                        <v-row align="center" class="mx-2">
                            <v-col cols="12" md="8" v-if="$vuetify.breakpoint.mdAndUp">
                                <iframe width="700px" height="300px"
                                    :src="item.url"
                                    frameborder="0"
                                >
                                    <p>很抱歉，您的瀏覽器不支援網頁預覽，請點擊右側預覽。</p>
                                </iframe>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-row dense align="center">
                                    <v-col cols="8" class="title">
                                        <h6>台灣商業訊息報 - 電子報</h6>
                                        {{ item.year }}年{{ item.month }}月
                                    </v-col>
                                    <v-col cols="12" class="body-1"> {{ item.describe }} </v-col>
                                    <v-col cols="12" class="text-right">
                                        <v-btn @click="$refs.doubleCheckTool.setOpen(removeEdm(item), delEdm, item.id)">
                                            刪除
                                        </v-btn>
                                        <v-btn class="mx-3" dark color="green" :href="item.url" target="_blank">
                                            預覽
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12">
                <v-pagination color="primary"
                    v-model="currentPage"
                    :length="getPages"
                    :total-visible="7"
                ></v-pagination>
            </v-col>
        </v-row>
        <double-check-tool ref="doubleCheckTool"></double-check-tool>
        <v-dialog v-model="dialog.global"
            persistent
            width="300"
        >
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-dialog>
        <!-- 新增電子報 -->
        <v-dialog v-model="dialog.created" v-if="dialog.created" width="50%">
            <validation-observer v-slot="{ invalid }">
              <v-card>
                  <v-toolbar dark color="primary">
                      <v-btn icon dark @click="init()">
                          <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>新增電子報</v-toolbar-title>
                  </v-toolbar>
                <v-card-text class="pt-3">
                    <v-row>
                        <v-col cols="6">
                            <v-select outlined
                                v-model="newEdm.year"
                                :items="getYears"
                                label="年份"
                            ></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select outlined
                                v-model="newEdm.month"
                                :items="months"
                                label="月份"
                            ></v-select>
                        </v-col>
                        <v-col cols="12">
                            <validation-provider name="內容描述" rules="required|max:300" v-slot="{ errors }">
                                <v-textarea counter="300" outlined label="內容描述"
                                    v-model="newEdm.describe" :error-messages="errors"
                                ></v-textarea>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12">
                            <validation-provider name="電子報程式" rules="required" v-slot="{ errors }">
                                <v-textarea outlined label="請貼上電子報程式" :error-messages="errors"
                                    v-model="newEdm.content"
                                ></v-textarea>
                            </validation-provider>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="createdEdm" :disabled="invalid"> 儲存 </v-btn>
                </v-card-actions>
              </v-card>
            </validation-observer>
        </v-dialog>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import _chunk  from 'lodash/chunk'
    export default {
        data () {
            return {
                dialog: {
                    created: false,
                    global: false,
                    preview: false,
                    edit: false
                },
                currentPage: 1,
                pageChunk: 20,
                months: [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
                newEdm: {},
                findValue: {
                    year: null,
                    month: null,
                },
                page: 1
            }
        },
        computed: {
          ...mapGetters({
              edms: 'Edms/getEdms',
              edmsYears: 'Edms/getEdmsYears'
          }),
          getPages() {
              if (this.edms && this.edms.length > this.pageChunk){
                  return Math.ceil(this.edms.length / this.pageChunk)
              }
              return 1
          },
          getCurrentPageItem() {
              return _chunk(this.edms, this.pageChunk)[this.currentPage - 1]

          },
          getYears() {
              const currentYear = new Date().getFullYear()
              let result = []
              for (let year = currentYear + 3 ; year > currentYear - 25 ; year--) {
                  result.push(year)
              }
              return result
          }
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getEdms: 'Edms/getEdms',
                postEdm: 'Edms/postEdm',
                delEdm: 'Edms/delEdm',
            }),
            removeEdm(item) {
                let result = {
                  title: '刪除',
                  message: `您確定要從系統刪除 <u>${item.year} 年 ${item.month} 月</u> 電子報嗎?`
                }
                return result
            },
            async findEdms() {
                let request = {}
                if (this.findValue.year) {
                    request.year = this.findValue.year
                }
                if (this.findValue.month) {
                    request.month = this.findValue.month
                }

                if (request) {
                    await this.getEdms(request)
                } else {
                    await this.getEdms()
                }
            },
            async init() {
                this.dialog.global = true
                this.newEdm = {
                    year: new Date().getFullYear(),
                    month: new Date().getMonth() + 1,
                    describe: '',
                    content: ''
                }
                if (!this.edms) {
                    await this.getEdms()
                }
                this.dialog = {
                    created: false,
                    global: false,
                    preview: false,
                    edit: false
                }
            },
            async createdEdm() {
                this.dialog.global = true
                await this.postEdm(this.newEdm)
                this.init()
            },
        }
    }
</script>
