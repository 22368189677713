import axios from './axios.js'

export default {
    namespaced: true,
    state: {
        history: [],
        nowNumber: null
    },
    actions: {
        getHistory ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/history`).then(response => {
                    commit('setHistory', response)
                    if (response.length > 0) {
                        commit('setNowNumber', response[response.length - 1].number)
                    }
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getOneHistory ({state},data) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/history/${data.id}`).then(response => {
                    resolve(response, state)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        postHistory ({state, dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.post(`/api/history`, {
                     title: data.title,
                     number: state.nowNumber + 1,
                     content: data.content,
                     image: data.image
                 }).then(response => {
                     dispatch('getHistory')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        putHistory ({ dispatch }, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/history/${data.id}`, {
                     title: data.title,
                     number: data.number,
                     content: data.content,
                 }).then(response => {
                     dispatch('getHistory')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        delHistory ({ dispatch }, id) {
             return new Promise((resolve, reject) => {
                 axios.delete(`/api/history/${id}`)
                 .then(response => {
                     dispatch('getHistory')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        putHistoryImage ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/history/${data.id}/image`, {
                     image: data.image
                 }).then(response => {
                     dispatch('getHistory')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        }
    },
    mutations: {
        setHistory(state, data){
            data.forEach((item) => {
                item.image = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.image}`
            })
            state.history = data
        },
        setNowNumber(state, data){
            state.nowNumber = data
        }
    },
    getters: {
        getHistory: state => {
          return state.history
        },
    }
}
