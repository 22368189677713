<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" class="text-right py-0">
                <v-btn color="primary" @click="dialog.batchCsv = true"> 批次更新會員團體資料 </v-btn>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
                <v-tabs v-model="tab">
                    <v-tab>會員團體</v-tab>
                    <v-tab>基層公會</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item v-if="memberGroups">
                        <div class="text-right">
                            <v-btn color="primary" @click="dialog.addMemeberGroup = true"> 新增公會 </v-btn>
                        </div>
                        <v-data-table dense fixed-header height="500px"
                            :headers="headers"
                            :items="memberGroups"
                            :items-per-page="-1"
                            class="elevation-1 headline"
                        >
                            <template v-slot:item.pay_status="{ item }">
                                {{ item.pay_status ? '有效': '無效' }}
                            </template>
                            <template v-slot:item.show="{ item }">
                                {{ item.show ? '有': '無' }}
                            </template>
                            <template v-slot:item.actions=" {item} ">
                                <v-btn icon :to="`/tcoc/memberGroup/${item.id}`" class="title">
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                                <v-btn icon class="title" @click="$refs.doubleCheckTool.setOpen(removeMemberGroup(item), delMemberGroup, item.id)">
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item v-if="basicGroups">
                        <div class="text-right">
                            <v-btn color="primary" @click="dialog.addBasicGroup = true"> 新增基層公會 </v-btn>
                        </div>
                        <v-data-table dense fixed-header height="500px"
                            :headers="basicHeaders"
                            :items="basicGroups"
                            :items-per-page="50"
                            class="elevation-1 headline"
                        >
                            <template v-slot:item.actions=" {item} ">
                                <v-btn icon class="title" @click="selectUpdateBasicGroup(item)">
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                                <v-btn icon class="title" @click="$refs.doubleCheckTool.setOpen(removeBasicGroup(item), delBasicGroup, item.id)">
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <double-check-tool ref="doubleCheckTool"></double-check-tool>
        <v-dialog v-model="dialog.global"
            persistent
            width="300"
        >
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
        </v-dialog>
        <v-dialog v-model="dialog.batchCsv"
            persistent
            max-width="300"
        >
            <v-card>
                <v-card-title class="text-h5">
                    批次更新會員團體資料
                </v-card-title>
                <v-card-text>
                    <v-file-input
                        label="上傳團體清單"
                        show-size color="primary"
                        v-model="excel.basic"
                    ></v-file-input>
                    <v-file-input
                        label="上傳各團體理監事清單"
                        show-size color="primary"
                        v-model="excel.supervisors"
                    ></v-file-input>
                    <v-file-input
                        label="上傳基層公會清單"
                        show-size color="primary"
                        v-model="excel.basicGroup"
                    ></v-file-input>
                    <!-- <div>
                        <upload-tool ref="basic" color="primary" text="上傳團體清單" :dark="true" :data.sync="csv.basic"
                            fileType=".xslx" :loadingItem.sync="dialog.global" :showFileName="true" :csvHeaders="csvHeaders.basic">
                        </upload-tool>
                    </div>
                    <div>
                        <upload-tool ref="supervisors" color="primary" text="上傳各團體理監事清單" :dark="true" :data.sync="csv.supervisors"
                            fileType=".xslx" :loadingItem.sync="dialog.global" :showFileName="true" :csvHeaders="csvHeaders.supervisors">
                        </upload-tool>
                    </div>
                    <div>
                        <upload-tool ref="basicGroup" color="primary" text="上傳基層公會清單" :dark="true" :data.sync="csv.basicGroup"
                            fileType=".xslx" :loadingItem.sync="dialog.global" :showFileName="true" :csvHeaders="csvHeaders.basicGroup">
                        </upload-tool>
                    </div> -->
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray"
                        @click="dialog.batchCsv = false"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        color="primary" :disabled="!excel.basic && !excel.supervisors && !excel.basicGroup"
                        @click="updataMemberGroupsAndBasicGroup"
                    >
                        更新
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog.addMemeberGroup"
            persistent
            max-width="800"
        >
            <validation-observer v-slot="{ invalid }">
                <v-card>
                    <v-card-title class="text-h5">
                        新增會員團體基本資料
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider name="編號" rules="required|numeric|max:4" v-slot="{ errors }">
                                    <v-text-field counter="4" type="number" label="編號" v-model="newMemeberGroup.id" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider name="會員團體名稱" rules="required|alpha|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" label="會員團體名稱" v-model="newMemeberGroup.name" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <validation-provider name="地址" rules="required|alpha_dash|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" label="地址" v-model="newMemeberGroup.address" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider name="電話" rules="phone|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" label="電話" v-model="newMemeberGroup.phone" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider name="傳真" rules="phone|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" label="傳真" v-model="newMemeberGroup.fax" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider name="電子信箱" rules="email|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" label="電子信箱" v-model="newMemeberGroup.email" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider name="網址" rules="url|max:100" v-slot="{ errors }">
                                    <v-text-field counter="100" label="網址" v-model="newMemeberGroup.website" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <p>繳費狀態</p>
                                <v-radio-group row v-model="newMemeberGroup.pay_status" :error-messages="errors">
                                    <v-radio
                                        v-for="status of [true, false]"
                                        :key="status"
                                        :label="`${status?'有效':'逾期'}`"
                                        :value="status"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" md="6">
                                <p>前台顯示</p>
                                <v-radio-group row v-model="newMemeberGroup.show">
                                    <v-radio
                                        v-for="status of [true, false]"
                                        :key="status"
                                        :label="`${status?'有':'無'}`"
                                        :value="status"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="gray"
                            @click="dialog.addMemeberGroup = false"
                        >
                            取消
                        </v-btn>
                        <v-btn
                            color="primary" :disabled="invalid"
                            @click="createMemberGroup"
                        >
                            新增
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </validation-observer>
        </v-dialog>
        <v-dialog v-model="dialog.addBasicGroup"
            persistent
            max-width="800"
        >
            <validation-observer v-slot="{ invalid }">
                <v-card>
                    <v-card-title class="text-h5">
                        新增基層公會
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider name="編號" rules="required|basisGroupId" v-slot="{ errors }">
                                    <v-text-field label="編號" v-model="newBasicGroup.id" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider name="公會名稱" rules="required|alpha|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" label="公會名稱" v-model="newBasicGroup.name" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider name="理事長" rules="required|alpha|max:15" v-slot="{ errors }">
                                    <v-text-field counter="15" label="理事長" v-model="newBasicGroup.master" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider name="電話" rules="phone|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" label="電話" v-model="newBasicGroup.phone" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider name="縣市別" rules="alpha|max:10" v-slot="{ errors }">
                                    <v-text-field counter="10" label="縣市別" v-model="newBasicGroup.city" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <validation-provider name="地址" rules="alpha_dash|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" label="地址" v-model="newBasicGroup.address" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="newBasicGroup.belong1"
                                    v-if="memberGroups" return-object clearable
                                    :items="memberGroups" :error-messages="errors"
                                    item-text="name"
                                    item-value="id"
                                    label="所屬公會1"
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="newBasicGroup.belong2"
                                    v-if="memberGroups" return-object clearable
                                    :items="memberGroups" :error-messages="errors"
                                    item-text="name"
                                    item-value="id"
                                    label="所屬公會2"
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="newBasicGroup.belong3"
                                    v-if="memberGroups" return-object clearable
                                    :items="memberGroups" :error-messages="errors"
                                    item-text="name"
                                    item-value="id"
                                    label="所屬公會3"
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="newBasicGroup.belong4"
                                    v-if="memberGroups" return-object clearable
                                    :items="memberGroups" :error-messages="errors"
                                    item-text="name"
                                    item-value="id"
                                    label="所屬公會4"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="gray"
                            @click="dialog.addBasicGroup = false"
                        >
                            取消
                        </v-btn>
                        <v-btn
                            color="primary" :disabled="invalid"
                            @click="createBasicGroup"
                        >
                            新增
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </validation-observer>
        </v-dialog>
        <v-dialog v-model="dialog.editBasicGroup"
            persistent
            max-width="800"
        >
            <validation-observer v-slot="{ invalid }">
                <v-card>
                    <v-card-title class="text-h5">
                        編輯基層公會 - {{ editBasicGroup.id }}
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider name="公會名稱" rules="required|alpha|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" label="公會名稱" v-model="editBasicGroup.name" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider name="理事長" rules="required|alpha|max:15" v-slot="{ errors }">
                                    <v-text-field counter="15" label="理事長" v-model="editBasicGroup.master" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider name="電話" rules="phone|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" label="電話" v-model="editBasicGroup.phone" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider name="縣市別" rules="alpha|max:10" v-slot="{ errors }">
                                    <v-text-field counter="10" label="縣市別" v-model="editBasicGroup.city" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <validation-provider name="地址" rules="alpha_dash|max:45" v-slot="{ errors }">
                                    <v-text-field counter="45" label="地址" v-model="editBasicGroup.address" :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="editBasicGroup.belong1"
                                    v-if="memberGroups" return-object clearable
                                    :items="memberGroups"
                                    item-text="name"
                                    item-value="id"
                                    label="所屬公會1"
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="editBasicGroup.belong2"
                                    v-if="memberGroups" return-object clearable
                                    :items="memberGroups"
                                    item-text="name"
                                    item-value="id"
                                    label="所屬公會2"
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="editBasicGroup.belong3"
                                    v-if="memberGroups" return-object clearable
                                    :items="memberGroups"
                                    item-text="name"
                                    item-value="id"
                                    label="所屬公會3"
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="editBasicGroup.belong4"
                                    v-if="memberGroups" return-object clearable
                                    :items="memberGroups"
                                    item-text="name"
                                    item-value="id"
                                    label="所屬公會4"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="gray"
                            @click="dialog.editBasicGroup = false"
                        >
                            取消
                        </v-btn>
                        <v-btn
                            color="primary" :disabled="invalid"
                            @click="updateBasicGroup"
                        >
                            更新
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </validation-observer>
        </v-dialog>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {
                tab: 0,
                headers: [{
                    text: 'No.',
                    value: 'id'
                }, {
                    text: '公會名稱',
                    value: 'name'
                }, {
                    text: '繳費狀態',
                    value: 'pay_status'
                }, {
                    text: '前台顯示',
                    value: 'show'
                }, {
                    text: '操作',
                    value: 'actions',
                    sortable: false
                }],
                basicHeaders: [{
                    text: 'No.',
                    value: 'id'
                }, {
                    text: '公會名稱',
                    value: 'name'
                }, {
                    text: '理事長',
                    value: 'master'
                }, {
                    text: '會址',
                    value: 'address'
                }, {
                    text: '電話',
                    value: 'phone'
                }, {
                    text: '操作',
                    value: 'actions',
                    sortable: false
                }],
                dialog: {
                    global: false,
                    batchCsv: false,
                    addMemeberGroup: false,
                    addBasicGroup: false,
                    editBasicGroup: false,
                },
                csv: {
                    basic: null,
                    supervisors: null,
                    basicGroup: null,
                },
                excel: {
                    basic: null,
                    supervisors: null,
                    basicGroup: null,
                },
                csvHeaders:{
                    basic: ['id', 'name', 'address', 'phone', 'fax', 'email', 'website', 'pay_status','show'],
                    supervisors: ['id', 'mg_name', 'title', 'name'],
                    basicGroup: ['id', 'name', 'city', 'belongGroup', 'master', 'address', 'phone'],
                },
                newMemeberGroup: {},
                newBasicGroup: {},
                editBasicGroup: {},
            }
        },
        computed: {
          ...mapGetters({
                memberGroups: 'MemberGroups/getMemberGroups',
                basicGroups: 'MemberGroups/getBasicGroups',
            })
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
              getMemberGroups: 'MemberGroups/getMemberGroups',
              getBasicGroups: 'MemberGroups/getBasicGroups',
              batchUpload: 'MemberGroups/batchUpload',
              batchUploadExcel: 'MemberGroups/batchUploadExcel',
              postMemberGroup: 'MemberGroups/postMemberGroup',
              postBasicGroup: 'MemberGroups/postBasicGroup',
              putBasicGroup: 'MemberGroups/putBasicGroup',
              delMemberGroup: 'MemberGroups/delMemberGroup',
              delBasicGroup: 'MemberGroups/delBasicGroup'
            }),
            closeLoading(){
                this.dialog.global = false
                this.dialog.batchCsv = false
                this.newMemeberGroup = {
                    id: null,
                    name: null,
                    address: null,
                    phone: null,
                    fax: null,
                    email: null,
                    website: null,
                    pay_status: true,
                    show: true,
                }
                this.newBasicGroup = {
                    id: null,
                    name: null,
                    master: null,
                    city: null,
                    address: null,
                    phone: null,
                    belong1: null,
                    belong2: null,
                    belong3: null,
                    belong4: null,
                }
                this.editBasicGroup = {
                    id: null,
                    name: null,
                    master: null,
                    city: null,
                    address: null,
                    phone: null,
                    belong1: null,
                    belong2: null,
                    belong3: null,
                    belong4: null,
                }
            },
            async init() {
                this.dialog.global = true
                if(this.memberGroups === ''){
                    await this.getMemberGroups()
                }
                if(this.basicGroups === ''){
                    await this.getBasicGroups()
                }
                this.closeLoading()
            },
            async updataMemberGroupsAndBasicGroup() {
                this.dialog.global = true
                await this.batchUploadExcel(this.excel)
                this.dialog.batchCsv = false
                this.closeLoading()
            },
            async createMemberGroup() {
                this.dialog.addMemeberGroup = false
                this.dialog.global = true
                await this.postMemberGroup(this.newMemeberGroup)
                this.closeLoading()
            },
            getBelongGroupId(data){
                for(let i = 1; i < 5; i++) {
                    if(data[`belong${i}`] && typeof data[`belong${i}`] === 'object') {
                        data[`belong${i}`] = data[`belong${i}`].id
                    }
                }
                return data
            },
            async createBasicGroup() {
                this.dialog.addBasicGroup = false
                this.dialog.global = true
                this.getBelongGroupId(this.newBasicGroup)
                await this.postBasicGroup(this.newBasicGroup)
                this.closeLoading()
            },
            selectUpdateBasicGroup(item) {
                this.editBasicGroup = item
                this.dialog.editBasicGroup = true
            },
            async updateBasicGroup() {
                this.dialog.editBasicGroup = false
                this.dialog.global = true
                this.getBelongGroupId(this.editBasicGroup)
                await this.putBasicGroup(this.editBasicGroup)
                this.closeLoading()
            },
            removeMemberGroup (item) {
              let result = {
                title: '刪除會員團體',
                message: `您確定要從系統刪除該會員團體 <u>${item.name}(${item.id})</u> 嗎? <br><b> 刪除後若有基層工會所屬於此，所屬關係將會失效，<br>建議您先解除所屬關係後再執行。 </b>`
              }
              return result
            },
            removeBasicGroup (item) {
              let result = {
                title: '刪除基層公會',
                message: `您確定要從系統刪除該基層公會 <u>${item.name}(${item.id})</u> 嗎?`
              }
              return result
            },
        }
    }
</script>
