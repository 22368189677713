<template>
    <div class="ma-3">
        <v-card class="px-3" v-if="organize">
            <validation-observer v-slot="{ invalid }">
                <v-row justify="center">
                    <v-col cols="12">
                        <h1>系統參數</h1>
                    </v-col>
                    <v-col cols="3">
                        <validation-provider name="理事長屆數" rules="required|numeric|max:2" v-slot="{ errors }">
                            <v-text-field v-model="organize.chairman_number" type="number" counter="2" label="理事長屆數" :error-messages="errors"></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="3">
                        <validation-provider name="理事長姓名" rules="required|alpha|max:15" v-slot="{ errors }">
                            <v-text-field v-model="organize.chairman_name" counter="15" label="理事長姓名" :error-messages="errors"></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="3">
                        <validation-provider name="理監事屆數" rules="required|numeric|max:2" v-slot="{ errors }">
                            <v-text-field v-model="organize.supervisors_number" type="number" counter="2" label="理監事屆數" :error-messages="errors"></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12">
                        <h1>關於省商會</h1>
                    </v-col>
                    <v-col cols="10">
                        <editor-tool ref="describeEditor" :data.sync="organize.describe" :height="300"></editor-tool>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <v-btn color="primary" :disabled="invalid" @click="updateOrganize">送出</v-btn>
                    </v-col>
                </v-row>
            </validation-observer>
        </v-card>
        <v-card class="px-3 mt-5">
            <v-row justify="center">
                <v-col cols="12">
                    <v-tabs grow show-arrows color="deep-orange" v-model="selectedTab">
                        <v-tab v-for="(item, index) in tabs" :key="index" v-text="item.name">  </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="selectedTab" class="pa-3">
                        <!-- 組織架構 -->
                        <v-tab-item>
                            <v-row v-if="organize">
                                <v-col cols="12" md="7">
                                    <upload-tool :data.sync="organize.image" :loadingItem.sync="disabled.uploadOrganizeImage"></upload-tool>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <editor-tool :data.sync="organize.content" ref="contentEditor" :height="500"></editor-tool>
                                </v-col>
                                <v-col cols="12" md="7">
                                    <div class="text-right">
                                        <v-btn color="primary" :disabled="disabled.uploadOrganizeImage"
                                            @click="updateOrganizeImage()"
                                        >
                                            上傳
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <div class="text-right">
                                        <v-btn color="primary" @click="updateOrganize">送出</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <!-- 行政系統  -->
                        <v-tab-item>
                            <v-row justify="center" v-if="administrative">
                                <v-col cols="12" md="6">
                                    <upload-tool :data.sync="administrative.image" :loadingItem.sync="disabled.uploadAdministrativeImage"></upload-tool>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <editor-tool :data.sync="administrative.content" ref="administrativeContentEditor" :height="600"></editor-tool>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <div class="text-right">
                                        <v-btn color="primary" :disabled="disabled.uploadAdministrativeImage"
                                            @click="updateAdministrativeImage()"
                                        >
                                            上傳
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <div class="text-right">
                                        <v-btn color="primary" @click="updateAdministrative">送出</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <!-- 歷史沿革   -->
                        <v-tab-item>
                            <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab" v-if="history.length > 0">
                                <div class="timeline">
                                    <div class="timeline-row" v-for="(item, key) in history" :key="key">
                                        <div class="timeline-time">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on"
                                                            small icon
                                                            color="success"
                                                            @click="editHistoryItem(key)"
                                                        >
                                                            <v-icon dark>
                                                                mdi-cog
                                                            </v-icon>
                                                        </v-btn>
                                                </template>
                                                <span> 更新 {{item.title}} </span>
                                            </v-tooltip>
                                            {{ item.title }}
                                            <br>
                                            <small> {{ item.content }} </small>
                                        </div>
                                        <div class="timeline-content">
                                            <v-img class="img-fluid" :src="item.image"></v-img>
                                        </div>
                                    </div>
                                    <!-- <div class="arrow" v-if="history.length > 0"></div> -->
                                </div>
                            </div>
                            <div class="text-center">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on"
                                            fab dark
                                            color="#f58a44"
                                            @click="dialog.addHistory = true"
                                        >
                                            <v-icon dark>
                                                mdi-plus
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span> 新增歷史沿革 </span>
                                </v-tooltip>
                            </div>
                        </v-tab-item>
                        <!-- 歷屆理事長 -->
                        <v-tab-item>
                            <v-row justify="center">
                                <v-col cols="10" md="6">
                                    <upload-tool :data.sync="organize.masters" :loadingItem.sync="disabled.uploadOrganizeMastersImage"></upload-tool>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn color="primary" @click="updateOrganizeMastersImage" :disabled="disabled.uploadOrganizeMastersImage">上傳</v-btn>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <!-- 中小商業服務活動 -->
                        <v-tab-item>
                            <v-row justify="center">
                                <v-col cols="10" md="6">
                                    <upload-tool :data.sync="organize.group_activities" :loadingItem.sync="disabled.uploadOrganizeGroupActivities"
                                        fileType="application/pdf"
                                    ></upload-tool>
                                    <embed v-if="organize.group_activities" type="video/webm" :src="`${organize.group_activities.preview?organize.group_activities.preview:organize.group_activities}#toolbar=0`" width="100%" style="max-height: 100rem; min-height: 50rem">
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn color="primary" @click="updateOrganizeGroupActivities" :disabled="disabled.uploadOrganizeGroupActivities">上傳</v-btn>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <!-- 稅務講習 -->
                        <v-tab-item>
                            <v-row justify="center">
                                <v-col cols="10" md="6">
                                    <upload-tool :data.sync="organize.tax_workshop" :loadingItem.sync="disabled.uploadOrganizeTaxWorkshop"
                                        fileType="application/pdf"
                                    ></upload-tool>
                                    <embed v-if="organize.tax_workshop" type="video/webm" :src="`${organize.tax_workshop.preview?organize.tax_workshop.preview:organize.tax_workshop}#toolbar=0`" width="100%" style="max-height: 100rem; min-height: 50rem">
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn color="primary" @click="updateOrganizeTaxWorkshop" :disabled="disabled.uploadOrganizeTaxWorkshop">上傳</v-btn>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <!-- 場地租借 -->
                        <v-tab-item>
                            <v-row justify="center">
                                <v-col cols="10" md="6">
                                    <upload-tool :data.sync="organize.rent" :loadingItem.sync="disabled.uploadOrganizeRent"
                                        fileType="application/pdf"
                                    ></upload-tool>
                                    <embed v-if="organize.rent" type="video/webm" :src="`${organize.rent.preview?organize.rent.preview:organize.rent}#toolbar=0`" width="100%" style="max-height: 100rem; min-height: 50rem">
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn color="primary" @click="updateOrganizeRent" :disabled="disabled.uploadOrganizeRent">上傳</v-btn>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-card>
        <!-- 新增歷史沿革 -->
        <v-dialog v-model="dialog.addHistory" width="50%">
            <validation-observer v-slot="{ invalid }">
                <v-card>
                    <v-toolbar dark color="#f58a44">
                        <v-btn icon dark @click="closeHistoryDialog()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>新增歷史沿革</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="pt-3">
                        <v-row justify="center">
                            <v-col cols="8">
                                <validation-provider name="時期名稱" rules="required|max:45" v-slot="{ errors }">
                                    <v-text-field outlined label="時期名稱" clsss="header" counter="45"
                                        v-model="newHistory.title" :error-messages="errors"
                                    ></v-text-field>
                                </validation-provider>
                                <validation-provider name="時期描述" rules="required|max:1000" v-slot="{ errors }">
                                    <v-textarea outlined counter="1000" label="時期描述" :error-messages="errors"
                                        v-model="newHistory.content"
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>
                            <v-col cols="4">
                                <p>時期照片</p>
                                <upload-tool :data.sync="newHistory.image" :loadingItem.sync="disabled.uploadNewHistory" :error-messages="errors"></upload-tool>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" :loading="loading.uploadNewHistory"
                            :disabled="invalid || !newHistory.image" @click="addHistoryItem"
                        > 儲存 </v-btn>
                    </v-card-actions>
                  </v-card>
             </validation-observer>
        </v-dialog>
        <!-- 變更歷史沿革 -->
        <v-dialog v-model="dialog.editHistory" width="50%">
            <validation-observer v-slot="{ invalid }">
                <v-card>
                    <v-toolbar dark color="#f58a44">
                        <v-btn icon dark @click="closeHistoryDialog()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>變更歷史沿革</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="pt-3">
                        <v-row justify="center">
                            <v-col cols="8">
                                <validation-provider name="時期名稱" rules="required|max:45" v-slot="{ errors }">
                                    <v-text-field outlined counter="45" label="時期名稱" v-model="updateHistory.title" :error-messages="errors"></v-text-field>
                                </validation-provider>
                                <validation-provider name="時期描述" rules="required|max:1000" v-slot="{ errors }">
                                    <v-textarea outlined counter="1000" label="時期描述" v-model="updateHistory.content" :error-messages="errors"></v-textarea>
                                </validation-provider>
                            </v-col>
                            <v-col cols="4">
                                <upload-tool :data.sync="updateHistory.image" :loadingItem.sync="disabled.uploadUpdateHistoryImage"></upload-tool>
                                <v-btn color="primary" @click="updateHistoryImage" :loading="loading.uploadUpdateHistoryImage" :disabled="disabled.uploadUpdateHistoryImage">上傳</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn dark color="error" v-if="!doubleDeleteHistory"
                            @click="doubleDeleteHistory = true"
                        >
                            刪除
                        </v-btn>
                        <v-btn dark color="error" v-else
                            :loading="loading.deleteHistory"
                            @click="deleteHistoryItem(updateHistory.id)"
                        >
                            確定刪除
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" :loading="loading.editHistory"
                            @click="updateHistoryItem" :disabled="invalid || !updateHistory.image"
                        >
                            儲存
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </validation-observer>
        </v-dialog>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {
                tabs: [{
                    name: '組織架構'
                }, {
                    name: '行政系統'
                }, {
                    name: '歷史沿革'
                }, {
                    name: '歷屆理事長'
                }, {
                    name: '中小商業服務活動'
                }, {
                    name: '稅務講習'
                }, {
                    name: '場地租借'
                }],
                loading: {
                    uploadOrganizeImage: false,
                    uploadOrganizeMastersImage: false,
                    uploadOrganizeGroupActivities: false,
                    uploadOrganizeTaxWorkshop: false,
                    uploadOrganizeRent: false,
                    uploadAdministrativeImage: false,
                    uploadHistoryImage: false,
                    uploadNewHistory: false,
                    uploadUpdateHistoryImage: false,
                    deleteHistory: false,
                    editHistory: false
                },
                dialog: {
                    addHistory: false,
                    editHistory: false
                },
                disabled: {
                    uploadOrganizeImage: true,
                    uploadOrganizeMastersImage: true,
                    uploadOrganizeGroupActivities: true,
                    uploadOrganizeTaxWorkshop: true,
                    uploadOrganizeRent: true,
                    uploadAdministrativeImage: true,
                    uploadHistoryImage: true,
                    uploadNewHistory: true,
                    uploadUpdateHistoryImage: true,
                },
                selectedTab: 0,
                doubleDeleteHistory: false,
                newHistory: {
                    number: '',
                    title: '',
                    content: '',
                    image: null
                },
                updateHistory: {
                    id: '',
                    number: '',
                    title: '',
                    content: '',
                    image: null
                }
            }
        },
        computed: {
          ...mapGetters({
            organize: 'Organize/getOrganize',
            administrative: 'Administrative/getAdministrative',
            history: 'History/getHistory'
          })
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
              getOrganize: 'Organize/getOrganize',
              putOrganize: 'Organize/putOrganize',
              putOrganizeImage: 'Organize/putOrganizeImage',
              putOrganizeMastersImage: 'Organize/putOrganizeMastersImage',
              putOrganizeGroupActivities: 'Organize/putOrganizeGroupActivities',
              putOrganizeTaxWorkshop: 'Organize/putOrganizeTaxWorkshop',
              putOrganizeRent: 'Organize/putOrganizeRent',
              getAdministrative: 'Administrative/getAdministrative',
              putAdministrative: 'Administrative/putAdministrative',
              putAdministrativeImage: 'Administrative/putAdministrativeImage',
              getHistory: 'History/getHistory',
              putHistory: 'History/putHistory',
              postHistory: 'History/postHistory',
              delHistory: 'History/delHistory',
              putHistoryImage: 'History/putHistoryImage',
            }),
            init() {
                if(this.organize === ''){
                    this.getOrganize()
                }
                if(this.administrative === ''){
                    this.getAdministrative()
                }
                if(this.history.length === 0){
                    this.getHistory()
                }
            },
            updateOrganizeImage() {
                this.loading.uploadOrganizeImage = true
                this.putOrganizeImage(this.organize)
                this.loading.uploadOrganizeImage = false
                this.disabled.uploadOrganizeImage = true
            },
            updateOrganizeMastersImage() {
                this.loading.uploadOrganizeMastersImage = true
                this.putOrganizeMastersImage(this.organize)
                this.loading.uploadOrganizeMastersImage = false
                this.disabled.uploadOrganizeMastersImage = true
            },
            updateAdministrativeImage() {
                this.loading.uploadAdministrativeImage = true
                this.putAdministrativeImage(this.administrative)
                this.loading.uploadAdministrativeImage = false
                this.disabled.uploadAdministrativeImage = true
            },
            updateHistoryImage() {
                this.loading.uploadUpdateHistoryImage = true
                this.putHistoryImage(this.updateHistory)
                this.loading.uploadUpdateHistoryImage = false
                this.disabled.uploadUpdateHistoryImage = true
            },
            updateOrganizeGroupActivities() {
                this.loading.uploadOrganizeGroupActivities = true
                this.putOrganizeGroupActivities(this.organize)
                this.loading.uploadOrganizeGroupActivities = false
                this.disabled.uploadOrganizeGroupActivities = true
            },
            updateOrganizeTaxWorkshop() {
                this.loading.uploadOrganizeTaxWorkshop = true
                this.putOrganizeTaxWorkshop(this.organize)
                this.loading.uploadOrganizeTaxWorkshop = false
                this.disabled.uploadOrganizeTaxWorkshop = true
            },
            updateOrganizeRent() {
                this.loading.uploadOrganizeRent = true
                this.putOrganizeRent(this.organize)
                this.loading.uploadOrganizeRent = false
                this.disabled.uploadOrganizeRent = true
            },
            async updateOrganize() {
                await this.putOrganize(this.organize)
            },
            async updateAdministrative() {
                this.putAdministrative(this.administrative)
            },
            async deleteHistoryItem(id) {
                this.loading.deleteHistory = true
                this.diabledUpdateHistory = true
                await this.delHistory(id)
                this.closeHistoryDialog()
            },
            async addHistoryItem() {
                this.loading.uploadNewHistory = true
                await this.postHistory(this.newHistory)
                this.closeHistoryDialog()
            },
            editHistoryItem(key) {
                this.updateHistory = { ...this.history[key] }
                this.dialog.editHistory = true
            },
            async updateHistoryItem() {
                this.loading.editHistory = true
                await this.putHistory(this.updateHistory)
                this.closeHistoryDialog()
            },
            closeHistoryDialog() {
                this.dialog.addHistory = false
                this.dialog.editHistory = false
                this.doubleDeleteHistory = false
                this.diabledUpdateHistory = false
                this.loading.uploadNewHistory = false
                this.loading.uploadUpdateHistoryImage = false
                this.loading.deleteHistory = false
                this.loading.editHistory = false
                this.disabled.uploadHistoryImage = true
                this.disabled.uploadNewHistory = true
                this.disabled.uploadUpdateHistoryImage = true

                this.newHistory = {
                    number: '',
                    title: '',
                    content: '',
                    image: null
                },
                this.updateHistory = {
                    id: '',
                    number: '',
                    title: '',
                    content: '',
                    image: null
                }
            }
        }
    }
</script>
<style media="screen">
.timeline {
    position: relative;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin: 0 auto 1rem auto;
    overflow: hidden;
}
.timeline:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -2px;
    border-right: 5px inset #0047b1;
    height: 99%;
    display: block;
}

.timeline-row {
    padding-left: 50%;
    position: relative;
    margin-bottom: 30px;
}

.timeline .arrow {
    border: solid #0047b1;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 11px;
    margin-left: 49.11%;
    transform: rotate(45deg);
}

.timeline-row .timeline-time {
    position: absolute;
    right: 50%;
    top: 15px;
    text-align: right;
    margin-right: 20px;
    color: #000000;
    font-size: 1.5rem;
    height:100%;
}
.timeline-row .timeline-time small {
    display: block;
    font-size: 1rem;
    height:80%;
    text-align: left;
    display: flex;          /* ★ */
    align-items: center;    /* ★ */
    font-size: 1rem;
    margin: 15px;
}
.timeline-row .timeline-content {
    position: relative;
    padding: 20px 30px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-clip: content-box;
}
.timeline-row .timeline-content:before {
    content: "";
    position: absolute;
    top: 20px;
    right: -50px;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    z-index: 10;
    background: #f58a44;
    border: 2px dashed #4b546f;
}
.timeline-row .timeline-content h4 {
    margin: 0 0 20px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 150%;
}
.timeline-row .timeline-content .thumbs {
    margin-bottom: 20px;
    display: flex;
}
.timeline-row .timeline-content .thumbs img {
    margin: 5px;
    max-width: 60px;
}
.timeline-row .timeline-content .badge {
    color: #ffffff;
    background: linear-gradient(120deg, #00b5fd 0%, #0047b1 100%);
}
.timeline-row:nth-child(even) .timeline-content {
    margin-left: 40px;
    text-align: left;
}
.timeline-row:nth-child(even) .timeline-content:after {
    left: -8px;
    right: initial;
    border-bottom: 0;
    border-left: 0;
    transform: rotate(-135deg);
}
.timeline-row:nth-child(even) .timeline-content:before {
    left: -50px;
    right: initial;
}
.timeline-row:nth-child(odd) {
    padding-left: 0;
    padding-right: 50%;
}
.timeline-row:nth-child(odd) .timeline-time {
    right: auto;
    left: 50%;
    text-align: left;
    margin-right: 0;
    margin-left: 20px;
    height:100%;
}
.timeline-row:nth-child(odd) .timeline-time small {
    height:80%;
    display: flex;          /* ★ */
    align-items: center;    /* ★ */
    font-size: 1rem;
    margin: 15px;
}
.timeline-row:nth-child(odd) .timeline-content {
    margin-right: 40px;
}
.timeline-row:nth-child(odd) .timeline-content:after {
    right: -8px;
    border-left: 0;
    border-bottom: 0;
    border: solid black;
}
</style>
