import axios from './axios.js'
import _chunk from 'lodash/chunk'
import groupBy from 'group-array'

export default {
    namespaced: true,
    state: {
        memberGroups: '',
        basicGroups: ''
    },
    actions: {
        getMemberGroups ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/memberGroups`).then(response => {
                    commit('setMemberGroups', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getMemberGroup ({commit}, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/memberGroups/${id}`).then(response => {
                    const levelGroup = groupBy(response.supervisors, 'title')
                    const levelValue = Object.values(levelGroup)
                    response['level']  = Object.keys(levelGroup)
                    for (let i = 0; i < response['level'].length ; i++) {
                        levelValue[i].forEach((item) => {
                            item.titleNo = i
                        })
                    }
                    response.supervisorsGroup = levelValue
                    resolve(response)
                    commit()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        postMemberGroup ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.post(`/api/memberGroups`, data).then(response => {
                     dispatch('getMemberGroups')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        putMemberGroup ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/memberGroups/${data.id}`, {
                     name: data.name,
                     address: data.address,
                     phone: data.phone,
                     fax: data.fax,
                     email: data.email,
                     website: data.website,
                     pay_status: data.pay_status,
                     show: data.show
                 }).then(response => {
                     dispatch('getMemberGroups')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        putMemberGroupSupervisors ({commit}, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/memberGroups/supervisors/${data.mg_id}`, {
                    data: data.list
                 }).then(response => {
                     commit('')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        delMemberGroup ({dispatch}, id) {
             return new Promise((resolve, reject) => {
                 axios.delete(`/api/memberGroups/${id}`).then(response => {
                     dispatch('getMemberGroups')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        postBasicGroups ({commit}, data) {
             return new Promise((resolve, reject) => {
                 axios.post(`/api/memberGroups/basicGroups`, data).then(response => {
                     commit('')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        getBasicGroups ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/memberGroups/basicGroups/list`).then(response => {
                    commit('setBasicGroups', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        putBasicGroup ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.put(`/api/memberGroups/basicGroups/${data.id}`,{
                     name: data.name,
                     master: data.master,
                     city: data.city,
                     address: data.address,
                     phone: data.phone,
                     belong1: data.belong1,
                     belong2: data.belong2,
                     belong3: data.belong3,
                     belong4: data.belong4,
                 }).then(response => {
                     dispatch('getBasicGroups')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        postBasicGroup ({dispatch}, data) {
             return new Promise((resolve, reject) => {
                 axios.post(`/api/memberGroups/basicGroups`,{
                     id: data.id,
                     name: data.name,
                     master: data.master,
                     city: data.city,
                     address: data.address,
                     phone: data.phone,
                     belong1: data.belong1,
                     belong2: data.belong2,
                     belong3: data.belong3,
                     belong4: data.belong4,
                 }).then(response => {
                     dispatch('getBasicGroups')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        delBasicGroup ({dispatch}, id) {
             return new Promise((resolve, reject) => {
                 axios.delete(`/api/memberGroups/basicGroups/${id}`).then(response => {
                     dispatch('getBasicGroups')
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
        async batchUpload ({dispatch}, data) {
            const action = ['batchUploadMemberGroups', 'batchUploadSupervisors', 'batchUploadBasicGroups']
            const params = [data.basic, data.supervisors, data.basicGroup]
            for (let i = 0 ; i < 3; i++) {
                let chunk = []
                if (params[i]) {
                    if (params[i].length > 100) {
                        chunk = _chunk(params[i],100)
                    } else {
                        chunk.push(params[i])
                    }
                    for(let j = 0 ; j < chunk.length ; j++) {
                        let input = { data:  chunk[j], isDelete: false}
                        if (j === 0) {
                            input.isDelete = true
                        }
                        await dispatch(action[i], input)
                    }
                }
            }
            await dispatch('getMemberGroups')
            await dispatch('getBasicGroups')
        },
        async batchUploadExcel ({dispatch}, data) {
            const action = ['batchUploadMemberGroups', 'batchUploadSupervisors', 'batchUploadBasicGroups']
            const params = [data.basic, data.supervisors, data.basicGroup]
            for (let i = 0 ; i < 3; i++) {
                if (params[i]) {
                    await dispatch(action[i], params[i])
                }
            }
            await dispatch('getMemberGroups')
            await dispatch('getBasicGroups')
        },
        batchUploadMemberGroups ({}, data) {
            let formData = new FormData()
            formData.append('file', data)
            return new Promise((resolve, reject) => {
                axios.post(`/api/memberGroups/batchUpload/memberGroups`,formData)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        batchUploadSupervisors ({}, data) {
            let formData = new FormData()
            formData.append('file', data)
            return new Promise((resolve, reject) => {
                axios.post(`/api/memberGroups/batchUpload/supervisors`, formData)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        batchUploadBasicGroups ({}, data) {
            let formData = new FormData()
            formData.append('file', data)
             return new Promise((resolve, reject) => {
                 axios.post(`/api/memberGroups/batchUpload/basicGroups`, formData)
                 .then(response => {
                     resolve(response)
                 }).catch(error => {
                     reject(error)
                 })
             })
        },
    },
    mutations: {
        setMemberGroups(state, data){
            state.memberGroups = data
        },
        setBasicGroups(state, data){
            state.basicGroups = data
        }
    },
    getters: {
        getMemberGroups: state => {
          return state.memberGroups
        },
        getBasicGroups: state => {
          return state.basicGroups
        }
    }
}
